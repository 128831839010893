import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { BehaviorSubject } from 'rxjs';

import { environment } from '../../../../../../environments/environment';

@Injectable()
export class PlaceScdCreateService {
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private JWT = new BehaviorSubject<any>('');
  private k: number;

  constructor(private http: HttpClient) { }

  setJWT(jwt) {
    this.JWT.next(jwt);
  }

  getJWT() {
    return this.JWT.asObservable();
  }

  getPlaceList(id: any) {
    return this.http.get(`${environment.committeeplaceApi}/api/scd/getPlaceList`, { headers: this.headers });
  }

  getSessionList(committeplaceName) {
    return this.http.get(`${environment.committeeplaceApi}/api/scd/getSessionsOnly`, { headers: this.headers });
  }

  getMainFolderBySessionID(sessionid: string) {
    return this.http.get(`${environment.committeeplaceApi}/api/scd/getMainFolderBySessionID/${sessionid}`, { headers: this.headers });
  }

  getFolderListById(sessionid: string) {
    return this.http.get(`${environment.committeeplaceApi}/api/scd/getFolderList/${sessionid}`, {
      headers: this.headers
    });
  }

  getSubFolderListByIds(sessionid: string, folderid: string) {
    return this.http.get(`${environment.committeeplaceApi}/api/scd/getSubFolderList/${sessionid}/${folderid}`, {
      headers: this.headers
    });
  }

  createPlace(placeList, user) {
    let users = user || {};

    let body = {
      RD_FolderSession: placeList.selectedSessionID,
      RD_Folder: placeList.selectedFolderID,
      RD_FolderSub: placeList.selectedSubfolder,
      RD_FileTitle: placeList.foldertitle,
      RD_File: placeList.folderpath,
      RD_DateAdded: placeList.dateAdded,
      RD_InformDaily: placeList.dailyNotification || false,
      RD_InformImmSent: placeList.delegateInformimm || false,
      RD_CreatedBy: users.USR_firstName + ' ' + users.USR_lastName,
      RD_CreatedOn: new Date(),
      RD_UpdatedBy: users.USR_firstName + ' ' + users.USR_lastName,
      RD_UpdatedOn: new Date(),
      path: placeList.path
    };
    return this.http.post(`${environment.committeeplaceApi}/api/scd/createplace`, JSON.stringify(body), {
      headers: this.headers
    });
  }

  updatePlace(placeList: any, user: any, placeObj: any) {
    let users = user || {};

    let body = {
      RD_FileTitleID: placeObj._id,
      RD_FolderSession: placeList.selectedSessionID,
      RD_Folder: placeList.selectedFolderID,
      RD_FolderSub: placeList.selectedSubfolder,
      RD_FileTitle: placeList.foldertitle,
      RD_File: placeList.folderpath,
      RD_DateAdded: placeList.dateAdded,
      RD_InformDaily: placeList.dailyNotification || false,
      RD_InformImm: placeList.delegateInformimm || false,
      RD_CreatedBy: users.USR_firstName + users.USR_lastName,
      RD_CreatedOn: new Date(),
      RD_UpdatedBy: users.USR_firstName + users.USR_lastName,
      RD_UpdatedOn: new Date(),
      path: placeList.path
    };
    return this.http.post(`${environment.committeeplaceApi}/api/scd/updateplace`, JSON.stringify(body), {
      headers: this.headers
    });
  }

  createMainFolder(folderdetails: any, user: any) {
    let users = user || {};

    let body = {
      RD_FolderSession: folderdetails.sessionid,
      RD_FolderSessionID: folderdetails.sessionid,
      RD_Folder: folderdetails.fname,
      RD_DateAdded: folderdetails.dateAdded,
      RD_InformImmSent: '',
      RD_InformImmD: '',
      RD_CreatedBy: users.USR_firstName + users.USR_lastName,
      RD_CreatedOn: new Date(),
      RD_UpdatedBy: users.USR_firstName + users.USR_lastName,
      RD_UpdatedOn: new Date()
    };
    return this.http.post(`${environment.committeeplaceApi}/api/scd/createmainfolder`, JSON.stringify(body), {
      headers: this.headers
    });
  }

  createSubFolder(folderdetails: any, user: any) {
    let users = user || {};

    let body = {
      RD_FolderSession: folderdetails.sessionid,
      RD_FolderSessionID: folderdetails.sessionid,
      RD_Folder: folderdetails.selectedmainfolderid,
      RD_FolderSub: folderdetails.sfoldername,
      RD_DateAdded: folderdetails.dateAdded,
      RD_CreatedBy: users.USR_firstName + users.USR_lastName,
      RD_CreatedOn: new Date(),
      RD_UpdatedBy: users.USR_firstName + users.USR_lastName,
      RD_UpdatedOn: new Date()
    };
    return this.http.post(`${environment.committeeplaceApi}/api/scd/createsubfolder`, JSON.stringify(body), {
      headers: this.headers
    });
  }

  getFileExtension = function (filename) {
    if (filename.length == 0) return '';
    var dot = filename.lastIndexOf('.');
    if (dot == -1) return '';
    var extension = filename.substr(dot, filename.length);
    return extension;
  };

  saveFile(response, filename) {
    var result = filename.match('.pdf');
    if (result) {
      let blob = new Blob([response], { type: 'application/pdf' });
      var blobURL = URL.createObjectURL(blob);
      var open = window.open(blobURL, '_blank');
      if (open == null || typeof (open) == 'undefined') {
        let a = document.createElement('a');
        a.target = '_self';
        a.href = blobURL;
        a.click();
      };
    } else {
      const blob = new Blob([response], { type: 'application/octet-stream' });
      saveAs(blob, filename);
    }
  }

  onBeforeSend = function (event) {
    event.xhr.setRequestHeader('Authorization', this.JWT.getValue());
  };

  onUpload(event, list) {
    let firstfilename = event.files[0].name;
    let filextension = this.getFileExtension(firstfilename);
    if (filextension == '.exe') {
      return false;
    }

    event.files.forEach(file => {
      list.push({
        originalname: file.name,
        uploadname: file.name
      });
    });
  }

  // delete file
  deleteFile = function (list, index) {
    const body = { filename: list[index].uploadname };

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      responseType: 'blob'
    };

    this.http.post(`${environment.committeeplaceApi}/api/scd/deletefile`, body, options).subscribe(data => {
      list.splice(index, 1);
    });
  };

  downloadFile = function (list: any, index: any, param3?: any, param4?: any) {
    const body = { filename: list[index].uploadname };

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Accept: 'application/pdf' }),
      responseType: 'blob'
    };

    this.http.post(`${environment.committeeplaceApi}/api/scd/downloadfile`, body, options).subscribe(data => {
      this.saveFile(data, list[index].originalname);
    });
  };

  isImage(file: File): boolean {
    return /^image\//.test(file.type);
  }
}
