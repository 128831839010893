import { Inject, Injectable } from "@angular/core";
import { NotificationsService } from 'angular2-notifications';

@Injectable()
export class NotifyService {

  constructor(private servicePNotify: NotificationsService) { }

  // service: show a notification
  addNotify(options, context) {
    context.options = {
      position: ['bottom', 'right'],
    };

    context['position1'] = 'bottom';
    context['position2'] = 'right';
    context['timeOut'] = 3000;
    context['showProgressBar'] = true;
    context['pauseOnHover'] = true;
    context['lastOnBottom'] = true;
    context['clickToClose'] = true;
    context['maxLength'] = 0;
    context['maxStack'] = 8;
    context['preventDuplicates'] = false;
    context['preventLastDuplicates'] = false;
    context['theClass'];
    context['rtl'] = false;
    context['animate'] = 'fromRight';
    context['icons'] = {
      success: '<em class="fa fa-4x fa-check-circle"></em>',
      alert: '<em class="fa fa-4x fa-exclamation-triangle"></em>',
      error: '<em class="fa fa-4x fa-exclamation-triangle"></em>',
      info: '<em class="fa fa-4x fa-info-circle"></em>',
      warn: '<em class="fa fa-4x fa-exclamation-triangle"></em>',
    };
    context['subType'] = 'success';

    context['title'];
    context['msg'];
    // this.servicePNotify.remove();
    context.options = {
      position: [
        ('position1' in options) ? options.position1 : context.position1,
        ('position2' in options) ? options.position2 : context.position2
      ],
      maxStack: ('maxStack' in options) ? options.maxStack : context.maxStack,
      timeOut: options.timeOut ? options.timeOut : context.timeOut,
      showProgressBar: ('showProgressBar' in options) ? options.showProgressBar : context.showProgressBar,
      pauseOnHover: ('pauseOnHover' in options) ? options.pauseOnHover : context.pauseOnHover,
      lastOnBottom: ('lastOnBottom' in options) ? options.lastOnBottom : context.lastOnBottom,
      clickToClose: ('clickToClose' in options) ? options.clickToClose : context.clickToClose,
      maxLength: options.maxLength ? options.maxLength : context.maxLength,
      preventDuplicates: ('preventDuplicates' in options) ? options.preventDuplicates : context.preventDuplicates,
      preventLastDuplicates: ('preventLastDuplicates' in options) ? options.preventLastDuplicates : context.preventLastDuplicates,
      theClass: options.theClass ? options.theClass : context.theClass,
      rtl: ('rtl' in options) ? options.rtl : context.rtl,
      animate: options.animate ? options.animate : context.animate,
      icons: context.icons
    };

    switch (options.type) {
      case 'success':
        this.servicePNotify.success(
          options.title ? options.title : context.title,
          options.msg ? options.msg : context.msg,
          {timeOut: options.timeOut || context.options.timeOut, icons: context.icons}
        );
        break;
      case 'error':
        this.servicePNotify.error(
          options.title ? options.title : context.title,
          options.msg ? options.msg : context.msg,
          {timeOut: options.timeOut || context.options.timeOut, icons: context.icons}
        );
        break;
      case 'alert':
        this.servicePNotify.error(
          options.title ? options.title : context.title,
          options.msg ? options.msg : context.msg,
          {timeOut: options.timeOut || context.options.timeOut, icons: context.icons}
        );
        break;
      case 'warn':
        this.servicePNotify.error(
          options.title ? options.title : context.title,
          options.msg ? options.msg : context.msg,
          {timeOut: options.timeOut || context.options.timeOut, icons: context.icons}
        );
        break;
      case 'info':
        this.servicePNotify.info(
          options.title ? options.title : context.title,
          options.msg ? options.msg : context.msg,
          {timeOut: options.timeOut || context.options.timeOut, icons: context.icons}
        );
        break;
      case 'create':
        this.servicePNotify.create(
          options.title ? options.title : context.title,
          options.msg ? options.msg : context.msg,
          options.type ? options.type : context.subType,
          {timeOut: options.timeOut || context.options.timeOut, icons: context.icons}
        );
        break;
      case 'html':
        this.servicePNotify.html(
          options.title ? options.title : context.title,
          options.msg ? options.msg : context.msg,
          {timeOut: options.timeOut || context.options.timeOut, icons: context.icons}
        );
        break;
      default:
        this.servicePNotify.alert(
          options.title ? options.title : context.title,
          options.msg ? options.msg : context.msg,
          {timeOut: options.timeOut || context.options.timeOut, icons: context.icons}
        );
        break;
    }
  }
}