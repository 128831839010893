import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'ag-grid-enterprise'; // <-- This will fix it.
import {LicenseManager} from 'ag-grid-enterprise';

// LicenseManager.setLicenseKey('United_Nations__MultiApp_4Devs4_March_2020__MTU4MzI4MDAwMDAwMA==0a4e7753f06af598872417a31084c1ca');
LicenseManager.setLicenseKey('CompanyName=United Nations,LicensedGroup=UN-DGACM-BAS,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=8,LicensedProductionInstancesCount=4,AssetReference=AG-024180,ExpiryDate=11_January_2023_[v2]_MTY3MzM5NTIwMDAwMA==ab0b4a6f21641dc3d3bdc6b89adf3daa');

switch (environment.branch) {
  case 'DEV':
    document.head.insertAdjacentHTML('afterbegin', '<title>e-Delegate+ DEV</title>');
    break;
  case 'QAT':
    document.head.insertAdjacentHTML('afterbegin', '<title>e-Delegate+ QAT</title>');
    break;
  case 'PROD':
    document.head.insertAdjacentHTML('afterbegin', '<title>e-Delegate+</title>');
    break;
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
