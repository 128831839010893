import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class ListOfParticipantsSetupService {
  private JWT = new BehaviorSubject<any>('');
  private k: number;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient) { }

  setJWT(jwt) {
    this.JWT.next(jwt);
  }

  getJWT() {


    return this.JWT.asObservable();
  }
  getLookupListByCode(code) {
    return this.http.get(`${environment.lookupsApi}/api/lookups/getlookups/${code}`, {
      headers: this.headers
    });
  }

  getMembers() {
    return this.http.get(`${environment.lookupsApi}/api/lookups/getmembership`, { headers: this.headers });
  }

  getDelegatesByMission(mission) {
    return this.http.get(`${environment.delegatesApi}/api/delegates/bymission/${mission}`, {
      headers: this.headers
    });
  }

  getMeetingsParticipants() {


    return this.http.get(`${environment.meetingsparticipantsApi}/api/meetingsparticipants/getall`, {
      headers: this.headers
    });


  };
  getMeetingParticipantById(id) {


    return this.http.get(`${environment.meetingsparticipantsApi}/api/meetingsparticipants/get/${id}`, {
      headers: this.headers
    });


  };
  getAllMeetings() {
    return this.http.get(`${environment.meetingsparticipantsApi}/api/meetingsparticipants/get/all/meetings`, {
      headers: this.headers
    });

  }
  getAllOpenMeetings() {
    return this.http.get(`${environment.meetingsparticipantsApi}/api/meetingsparticipants/get/all/meetings/open`, {
      headers: this.headers
    });

  }
  updateMeetingParticipantById(id, meetingsParticipants) {


    return this.http.put(`${environment.meetingsparticipantsApi}/api/meetingsparticipants/update/${id}`, meetingsParticipants, {
      headers: this.headers
    });


  };

  addParticipant(id, countryPath, country, participant) {
    return this.http.post(`${environment.meetingsparticipantsApi}/api/meetingsparticipants/update/add-participant/${id}`, { countryPath, country, participant }, { headers: this.headers });
  };

  updateParticipant(id, countryPath, country, participant) {
    return this.http.put(`${environment.meetingsparticipantsApi}/api/meetingsparticipants/update/update-participant/${id}`, { countryPath, country, participant }, { headers: this.headers });
  };

  deleteParticipant(id, countryPath, country, participant) {
    return this.http.post(`${environment.meetingsparticipantsApi}/api/meetingsparticipants/update/delete-participant/${id}`, { countryPath, country, participant }, { headers: this.headers });
  };

  createListOfParticipantsSetup(meetingsParticipants) {


    return this.http.post(`${environment.meetingsparticipantsApi}/api/meetingsparticipants/create`, meetingsParticipants, {
      headers: this.headers
    });


  };



  // check attached file's image
  isImage(file: File): boolean {



    return /^image\//.test(file.type);


  };


  saveFile(response, filename) {
    var result = filename.match('.pdf');
    if (result) {
      let blob = new Blob([response], { type: 'application/pdf' });
      var blobURL = URL.createObjectURL(blob);
      var open = window.open(blobURL, '_blank');
      if (open == null || typeof (open) == 'undefined') {
        let a = document.createElement('a');
        a.target = '_self';
        a.href = blobURL;
        a.click();
      };
    } else {
      const blob = new Blob([response], { type: 'application/octet-stream' });
      saveAs(blob, filename);
    }
  }


  uploadFile(file) {
    const formData: FormData = new FormData();

    formData.append('file', file, file.name);

    return this.http.post(`${environment.meetingsparticipantsApi}/api/meetingsparticipants/uploadfiles`, formData).toPromise();
  }

  async updateAttachment(originalList, currentList, uploader, type, msg) {
    var flag = await this.onBeforeSend(uploader, type, msg);

    if (msg[msg.length - 1] != "") return 'Upload file failed';
    else {
      if ((type == "validate" && flag) || type == "") {


        if (!originalList) {
          originalList = [];
        }

        // Delete
        if (originalList.length) {
          for (const originalAttachment of originalList) {
            let found = false;
            currentList.forEach((currentAttachment) => {
              if (currentAttachment.uploadname === originalAttachment.uploadname) {
                found = true;
              }
            });
            if (!found) {
              await this.deleteFile(originalAttachment.uploadname).then(
                result => {
                  // console.log(originalAttachment.uploaderName + ' delete success');
                },
                error => {
                  return Promise.reject('Delete file failed');
                }
              );
            }
          }
        }

        // Upload
        for (const file of uploader.files) {
          await this.uploadFile(file).then(
            result => {
              const date = new Date();
              currentList.push({
                originalname: result['originalname'],
                uploadname: result['uploadname'],
                uploadtime: date.getDate() + ' ' + date.toLocaleString('default', { month: 'short' }) + ' ' + date.getFullYear(),
                uploadentity: JSON.parse(localStorage.getItem('user'))['USR_Entity']
              });
            },
            error => {
              return Promise.reject('Upload file failed');
            }
          );
        }
        uploader.files = [];

        // return
        return Promise.resolve();
      }
      else {
        return 'Upload file failed';
      }
    }
  }
  // setup authorization header
  onBeforeSend = async function (uploader, type, msg) {
    // event.xhr.setRequestHeader('Authorization', this.JWT.getValue());

    var length = 0;
    if (type == "validate" && uploader.files.length > 0) {


      let result = await this.getImageDimension(uploader.files[0]);

      length++;
      var flag = true;

      if (result == false) {

        msg.push("Attachment should be of jpg or jpeg or pjpeg format, size less than 240KB, between 600x600 and 1200x1200 pixels");

        // event.xhr.abort();
        flag = false;
      }
      else {
        msg.push("");

      }


      return flag;



    }
    else {
      msg.push("");
      return true;
    }


  };
  getImageDimension(evt) {

    var flag = false;
    var type = evt.name.split(".");
    // console.log(type[1]);
    return new Promise((resolve, reject) => {
      if ((type[1] != 'jpg' && type[1] != 'jpeg' && type[1] != 'pjpeg')) {
        return false;
      }
      let image: any = evt;
      // console.log(image.size);
      let fr = new FileReader();
      fr.onload = () => { // when file has loaded
        var img = new Image();
        img.onload = () => {

          if (img.width > 1200 || img.width < 600 || img.height > 1200 || img.height < 600 || image.size > 240000 || (type[1] != 'jpg' && type[1] != 'jpeg' && type[1] != 'pjpeg')) {
            flag = false;
            resolve(flag);
          }
          else {
            flag = true;
            resolve(flag);
          }

        };

        img.src = fr.result.toString(); // The data URL 
      };
      fr.readAsDataURL(image);
    });

  };
  // on upload file
  onUpload(event, list, type, msgs) {
    // this.showMsg(msgs, 'upload', true, type);
    //console.log(list);



    event.files.forEach(file => {
      //console.log(file);
      if (type == 'validate') {
        if (list.length > 0) {
          list.splice(0, 1);
        }
      }

      list.push({
        originalname: file.name,
        uploadname: file.name


      });


    });
  };


  // delete file
  deleteFile = function (name) {
    const body = { filename: name };

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      responseType: 'blob'
    };

    return this.http.post(`${environment.meetingsparticipantsApi}/api/meetingsparticipants/deletefile`, body, options);
  };


  // download file
  downloadFile = function (uploadname) {
    const body = { filename: uploadname };

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Accept: 'application/pdf' }),
      responseType: 'blob'
    };
    this.http.post(`${environment.meetingsparticipantsApi}/api/meetingsparticipants/downloadfile`, body, options).subscribe(
      data => {
        this.saveFile(data, uploadname);
      }
    );


  };




}
