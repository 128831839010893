import {map,  switchMap } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";

@Injectable()
export class PlaceCreateService {
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private JWT = new BehaviorSubject<any>('');
  private k: number;

  constructor(private http: HttpClient, public router: Router) { }

  setJWT(jwt) {
    this.JWT.next(jwt);
  }

  getJWT() {
    return this.JWT.asObservable();
  }

  getPlaceList(id: any) {
    return this.http.get(`${environment.committeeplaceApi}/api/place/getPlaceList`, { headers: this.headers });
  }

  getSessionList(committeplaceName) {
    return this.http.get(`${environment.lookupsApi}/api/lookups/getlookups/PLC1`, { headers: this.headers }).pipe(map((response: Response) => {
      const data = response;
      return this.mapData(data);
    }));
  }

  mapData(data: any): any {
    let newObjectArray = [];
    data.result.forEach(element => {
      let newObject: any = {};
      newObject._id = element._id;
      newObject._sessionid = element._id;
      newObject.RD_FolderSession = element.LKP_Value;
      newObject.RD_FolderO = element.LKP_Order;
      newObjectArray.push(newObject);
    });

    return newObjectArray;
  }

  getMainFolderBySessionID(sessionid: string) {
    return this.http.get(`${environment.committeeplaceApi}/api/place/getMainFolderBySessionID/${sessionid}`, { headers: this.headers }).pipe(map((response: Response) => {
      const data: any = response;
      return data && data.length > 0 ?
        data[0].Mainfolders.sort((lhs, rhs) => {
          const order = lhs.RD_FolderO - rhs.RD_FolderO;
          if (order === 0) {
            return lhs.RD_Folder.localeCompare(rhs.RD_Folder);
          }
          return order;
        }) : [];
    }));
  }

  getFolderListById(sessionid: string) {
    return this.http.get(`${environment.committeeplaceApi}/api/place/getFolderList/${sessionid}`, {
      headers: this.headers
    });
  }

  getSubFolderListByIds(sessionid: string, folderid: string) {
    return this.http.get(`${environment.committeeplaceApi}/api/place/getSubFolderList/${sessionid}/${folderid}`, {
      headers: this.headers
    }).pipe(map((response: Response) => {
      const data: any = response;
      data.sort((lhs, rhs) => {
        const order = lhs.RD_FolderSubO - rhs.RD_FolderSubO;
        if (order === 0) {
          return lhs.RD_FolderSub.localeCompare(rhs.RD_FolderSub);
        }
        return order;
      });
    }));
  }

  createPlace(placeList, user) {
    let users = user || {};

    let body = {
      RD_FolderSession: placeList.selectedSessionID,
      RD_Folder: placeList.selectedFolderID,
      RD_FolderSub: placeList.selectedSubfolder,
      RD_FileTitle: placeList.foldertitle,
      RD_File: placeList.folderpath,
      RD_DateAdded: placeList.dateAdded,
      RD_InformDaily: placeList.dailyNotification || false,
      RD_InformImmSent: placeList.delegateInformimm || false,
      RD_CreatedBy: users.USR_FirstName + ' ' + users.USR_LastName,
      RD_CreatedOn: new Date(),
      RD_UpdatedBy: users.USR_FirstName + ' ' + users.USR_LastName,
      RD_UpdatedOn: new Date(),
      path: placeList.path,
      RD_LinkFile: placeList.addLinkFile,
      referenceID: placeList.referenceID,
    };
    return this.http.post(`${environment.committeeplaceApi}/api/place/createplace`, JSON.stringify(body), {
      headers: this.headers
    });
  }

  // updatePlace(placeList: any, user: any, placeObj: any) {
  //   let users = user || {};
  //   let body = {
  //     RD_FileTitleID: placeObj._id,
  //     RD_FolderSession: placeList.selectedSessionID,
  //     RD_Folder: placeList.selectedFolderID,
  //     RD_FolderSub: placeList.selectedSubfolder,
  //     RD_FileTitle: placeList.foldertitle,
  //     RD_File: placeList.folderpath,
  //     RD_DateAdded: placeList.dateAdded,
  //     RD_InformDaily: placeList.dailyNotification || false,
  //     RD_InformImm: placeList.delegateInformimm || false,
  //     RD_CreatedBy: users.USR_FirstName + users.USR_LastName,
  //     RD_CreatedOn: new Date(),
  //     RD_UpdatedBy: users.USR_FirstName + users.USR_LastName,
  //     RD_UpdatedOn: new Date(),
  //     path: placeList.path,
  //     RD_LinkFile: placeList.addLinkFile,
  //     referenceID: placeList.referenceID,
  //   };
  //   return this.http.post(`${environment.committeeplaceApi}/api/place/updateplace`, JSON.stringify(body), {
  //     headers: this.headers
  //   });
  // }

  createSessionForMainFolder(sessiondetails: any, user: any) {
    let users = user || {};
    let body = {
      RD_FolderSessionID: sessiondetails._sessionid,
      RD_FolderSessionName: sessiondetails.RD_FolderSession,
      RD_FolderO: +sessiondetails.RD_FolderO
    };

    return this.http.post(`${environment.committeeplaceApi}/api/place/createcommitteesession`, JSON.stringify(body), {
      headers: this.headers
    });
  }

  createSessionAndUpdateMainFolder(sessionList: any, folderdetails: any, user: any) {
    let sessiondetails = sessionList.filter(session => session._sessionid == folderdetails.sessionid)[0];
    return this.createSessionForMainFolder(sessiondetails, user).pipe(
      switchMap(data => {
        let updatedsessionList: any = data;
        let users = user || {};
        if (updatedsessionList.IsSuccess) {
          let body = {
            RD_FolderSession: folderdetails.sessionid,
            RD_FolderSessionID: folderdetails.sessionid,
            RD_Folder: folderdetails.fname,
            RD_DateAdded: folderdetails.dateAdded,
            RD_InformImmSent: '',
            RD_InformImmD: '',
            RD_CreatedBy: users.USR_FirstName + users.USR_LastName,
            RD_CreatedOn: new Date(),
            RD_UpdatedBy: users.USR_FirstName + users.USR_LastName,
            RD_UpdatedOn: new Date(),
            RD_FolderO: + folderdetails.order
          };
          return this.http.post(`${environment.committeeplaceApi}/api/place/createmainfolder`, JSON.stringify(body), {
            headers: this.headers
          }).pipe(map((response: Response) => {
            const data: any = response;
            return data && data.result &&
            data.result.Mainfolders.length > 0 ? data.result.Mainfolders.sort((lhs, rhs) => {
              const order = lhs.RD_FolderO - rhs.RD_FolderO;
              if (order === 0) {
                return lhs.RD_Folder.localeCompare(rhs.RD_Folder);
              }
              return order;
            }) : [];
          }));
        }
      })
    );
  }

  createSubFolder(folderdetails: any, user: any) {
    let users = user || {};

    let body = {
      RD_FolderSession: folderdetails.sessionid,
      RD_FolderSessionID: folderdetails.sessionid,
      RD_Folder: folderdetails.selectedmainfolderid,
      RD_FolderSub: folderdetails.sfoldername,
      RD_DateAdded: folderdetails.dateAdded,
      RD_CreatedBy: users.USR_FirstName + users.USR_LastName,
      RD_CreatedOn: new Date(),
      RD_UpdatedBy: users.USR_FirstName + users.USR_LastName,
      RD_UpdatedOn: new Date(),
      RD_FolderSubO: + folderdetails.order
    };
    return this.http.post(`${environment.committeeplaceApi}/api/place/createsubfolder`, JSON.stringify(body), {
      headers: this.headers
    }).pipe(map((response: Response) => {
      const data: any = response;
      return data && data.result &&
      data.result.Mainfolders.length > 0 ? data.result.Mainfolders.sort((lhs, rhs) => {
        return lhs['RD_FolderO'] - rhs['RD_FolderO'];
      }).sort((lhs, rhs) => {
        const order = lhs.RD_FolderO - rhs.RD_FolderO;
        if (order === 0) {
          return lhs.RD_Folder.localeCompare(rhs.RD_Folder);
        }
        return order;
      }) : [];
    }));
  }

  getFileExtension = function (filename) {
    if (filename.length == 0) return '';
    var dot = filename.lastIndexOf('.');
    if (dot == -1) return '';
    var extension = filename.substr(dot, filename.length);
    return extension;
  };

  saveFile(response, filename) {
    var result = filename.match('.pdf');
    if (result) {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //To IE or Edge browser, using msSaveorOpenBlob method to download file.
        let blob = new Blob([response], { type: "application/pdf" });
        var blobURL = URL.createObjectURL(blob);
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        let blob = new Blob([response], { type: 'application/pdf' });
        var blobURL = URL.createObjectURL(blob);
        //let btn = document.createElement('button');
        // btn.onclick = function () {
        //   window.open(blobURL, '_blank');
        // }
        // btn.click();
        var open = window.open(blobURL, '_blank');
        if (open == null || typeof (open) == 'undefined') {
          let a = document.createElement('a');
          a.target = '_self';
          a.href = blobURL;
          a.type = 'application/pdf';
          a.download = filename;
          a.click();
        };
      }
    } else {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //To IE or Edge browser, using msSaveorOpenBlob method to download file.
        let blob = new Blob([response], { type: "application/octet-stream" });
        var blobURL = URL.createObjectURL(blob);
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        const blob = new Blob([response], { type: 'application/octet-stream' });
        saveAs(blob, filename);
      }
    }
  }
  deleteFile = function (name) {
    const body = { filename: name };

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      responseType: 'blob'
    };
    return this.http.post(`${environment.committeeplaceApi}/api/place/deletefile`, body, options).toPromise();
  };

  downloadFile = function (list: any, index: any, param3?: any, param4?: any) {
    const body = { filename: list[index].uploadname };

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Accept: 'application/pdf' }),
      responseType: 'blob'
    };

    this.http.post(`${environment.committeeplaceApi}/api/place/downloadfile`, body, options).subscribe(data => {
      this.saveFile(data, list[index].originalname);
    });
  };

  isImage(file: File): boolean {
    return /^image\//.test(file.type);
  }

  // uploadFile(file, referenceID: string, path: string) {
  //   const formData: FormData = new FormData();
  //   formData.append('file1', file, "file1");
  //   formData.append('file2', file, "file2");
  //   formData.append('referenceID', referenceID);
  //   formData.append('path', path);
  //   return this.http.post(`${environment.committeeplaceApi}/api/place/uploadfiles`, formData).toPromise();
  // }

  submitPlace(docInfo, docs) {
    const formData: FormData = new FormData();
    formData.append('docInfo', JSON.stringify(docInfo));
    for (const doc of docs) {
      formData.append(doc.name, doc);
    }
    if (docInfo.documentId) {
      return this.http.put(`${environment.committeeplaceApi}/api/place/documents/${docInfo.documentId}`, formData).toPromise();
    } else {
      return this.http.post(`${environment.committeeplaceApi}/api/place/documents`, formData).toPromise();
    }

  }

  deleteMainFolder(sessionId: string, mainFolderId: string) {
    return this.http
        .delete(`${environment.committeeplaceApi}/api/place/folders/main`, {
            params: {
                sessionId,
                mainFolderId,
            },
        })
        .pipe(
            map((response: any) => {
                return response && response.result && response.result.Mainfolders.length
                    ? response.result.Mainfolders.sort((lhs, rhs) => {
                          const order = lhs.RD_FolderO - rhs.RD_FolderO;
                          if (order === 0) {
                              return lhs.RD_Folder.localeCompare(rhs.RD_Folder);
                          }
                          return order;
                      })
                    : [];
            })
        );
}

  deleteSubFolder(sessionId: string, subFolderId: string) {
    return this.http.delete(`${environment.committeeplaceApi}/api/place/folders/sub`, {
      params: {
        sessionId,
        subFolderId
      }
    })
    .pipe(
        map((response: any) => {
            return response && response.result && response.result.Mainfolders.length
                ? response.result.Mainfolders.sort((lhs, rhs) => {
                      const order = lhs.RD_FolderO - rhs.RD_FolderO;
                      if (order === 0) {
                          return lhs.RD_Folder.localeCompare(rhs.RD_Folder);
                      }
                      return order;
                  })
                : [];
        })
    );
  }

  updateMainFolder = (updateFields, user): Observable<any> => {
    updateFields = {
      ...updateFields,
      RD_UpdatedBy: user.USR_FirstName + user.USR_LastName,
      RD_UpdatedOn: new Date(),
    }
    return this.http
    .put<any>(
      `${environment.committeeplaceApi}/api/place/folders/main`,
      JSON.stringify(updateFields),
      {
        headers: this.headers,
      }
    ).pipe(map(({success, result}) => {
      return success && result.Mainfolders.length > 0
        ? result.Mainfolders.sort((lhs, rhs) => {
          const order = lhs.RD_FolderO - rhs.RD_FolderO;
          if (order === 0) {
            return lhs.RD_Folder.localeCompare(rhs.RD_Folder);
          }
          return order;
        })
        : [];
    })
    )
  }

  updateSubFolder = (updateFields, user): Observable<any> => {
    updateFields = {
      ...updateFields,
      RD_UpdatedBy: user.USR_FirstName + user.USR_LastName,
      RD_UpdatedOn: new Date(),
    }
    return this.http
    .put<any>(
      `${environment.committeeplaceApi}/api/place/folders/sub`,
      JSON.stringify(updateFields),
      {
        headers: this.headers,
      }
    ).pipe(map(({success, result}) => {
      return success && result.Mainfolders.length > 0
        ? result.Mainfolders.sort((lhs, rhs) => {
          const order = lhs.RD_FolderO - rhs.RD_FolderO;
          if (order === 0) {
            return lhs.RD_Folder.localeCompare(rhs.RD_Folder);
          }
          return order;
        })
        : [];
    })
    )
  }

  // async updateAttachment(originalList: Array<any>, currentList: Array<any>, uploader: any, referenceID: string, path: string) {
  //   if (originalList.length) {
  //     await this.deleteFile(originalList[0].uploadname).then(
  //       result => {
  //         console.log(originalList[0].uploaderName + ' delete success');
  //       },
  //       error => {
  //         return Promise.reject('Delete file failed');
  //       }
  //     );
  //   }

  //   for (const file of uploader.files) {
  //     await this.uploadFile(file, referenceID, path).then(
  //       result => {
  //         const date = new Date();
  //         currentList.push({
  //           originalname: result['originalname'],
  //           uploadname: result['uploadname'],
  //           uploadtime: date.getDate() + ' ' + date.toLocaleString('default', { month: 'short' }) + ' ' + date.getFullYear(),
  //           uploadentity: JSON.parse(localStorage.getItem('user'))['USR_Entity'],
  //           uplodedID: result['_id'],
  //         });
  //       },
  //       error => {
  //         return Promise.reject('Upload file failed');
  //       }
  //     );
  //   }
  //   uploader.files = [];
  //   return Promise.resolve(currentList);
  // }
}
