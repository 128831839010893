import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class ElectionsService {
  private JWT = new BehaviorSubject<any>('');
  private k: number;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient) { }

  setJWT(jwt) {
    this.JWT.next(jwt);
  }

  getJWT() {
    return this.JWT.asObservable();
  }

  getEntities() {
    return this.http.get(`${environment.lookupsApi}/api/lookups/getentities`, { headers: this.headers });
  }

  getGroups() {
    return this.http.get(`${environment.lookupsApi}/api/lookups/getgroups`, { headers: this.headers });
  }

  getChairsByDate() {
    return this.http.get(`${environment.candidaturesApi}/api/regionalgroupchair/getchairsbydate`, { headers: this.headers });
  }

  createCandidature(tmpData) {
    return this.http.post(`${environment.candidaturesApi}/api/candidatures/createcandidature`, tmpData, { headers: this.headers });
  };

  getElectionsByBodyByStatus(param) {
    return this.http.post(`${environment.candidaturesApi}/api/candidatures/getelectionsbybodybystatus`, param, { headers: this.headers });
  }

  getElectionByID(docID) {
    return this.http.get(`${environment.candidaturesApi}/api/candidatures/getelectionbyid/${docID}`, { headers: this.headers });
  }

  getElections(param) {
    return this.http.post(`${environment.candidaturesApi}/api/candidatures/getelections`, param, { headers: this.headers });
  }

  getCandidaturesByElectionID(param) {
    return this.http.post(`${environment.candidaturesApi}/api/candidatures/getcandidaturesbyelectionid`, param, { headers: this.headers });
  }

  getCandidatureByID(docID) {
    return this.http.get(`${environment.candidaturesApi}/api/candidatures/getcandidaturebyid/${docID}`, { headers: this.headers });
  }

  updateCandidatureByID(param) {
    return this.http.put(`${environment.candidaturesApi}/api/candidatures/updatecandidaturebyid`, param, { headers: this.headers });
  }

  endorseCandidatureByID(param) {
    return this.http.put(`${environment.candidaturesApi}/api/candidatures/endorsecandidaturebyid`, param, { headers: this.headers });
  }

  withdrewCandidatureByID(param) {
    return this.http.put(`${environment.candidaturesApi}/api/candidatures/withdrewcandidaturebyid`, param, { headers: this.headers });
  }


  getUserEligibility(tmpelection, tmpms) {
    return new Promise((resolve, reject) => {
      let accesstype = {
        chair: "",
        group: "",
        ms: "",
      };
      //check election and check member state eligibility for election
      //check if ms can submit
      // if (tmpelection.CE_Submitter.indexOf('Chair of the Regional Group') > -1) {
      //check if ms is chair
      this.getChairsByDate().subscribe(data => {
        let tmpchairs = data['result'];
        tmpchairs[0]["RG_Chairs"].forEach(i => {
          if (i["chair"] && i["chair"] != "" && i["chair"] === tmpms) {
            accesstype.chair = 'Yes';
            accesstype.group = i["group"];
          };
        });
        //if not chair, check if it is MS
        if (tmpelection.CE_EligibleMS.indexOf(tmpms) > -1) {
          accesstype.ms = 'Yes';
        };
        resolve(accesstype);
      });
      // } else if (tmpelection.CE_Submitter.indexOf("Member State") > -1) {
      //check if your ms can submit
      // if (tmpelection.CE_EligibleMS.indexOf(tmpms) > -1) {
      //   accesstype = "MS";
      // };
      // resolve(accesstype);
      // } else {
      //   resolve(accesstype);
      // }
    });
  }

  // setup authorization header
  onBeforeSend = function (event, uploader, type, msg) {
    event.xhr.setRequestHeader('Authorization', this.JWT.getValue());
    if (type == "validate") {
      uploader.files.forEach(file => {
        this.getImageDimension(file, (result) => {
          if (result == false) {

            msg.push("Attachment should be of jpg or jpeg or pjpeg format, size less than 240KB, between 600x600 and 1200x1200 pixels");

            event.xhr.abort();
          }
          else {
            msg.push("");
          }
        });
      });
    }
    else {
      msg.push("");
    }

  };



  onUpload(event, list, type, msgs) {
    // this.showMsg(msgs, 'upload', true, type);

    event.files.forEach(file => {
      if (type == 'validate') {
        if (list.length > 0) {
          list.splice(0, 1);
        }
      }

      list.push({
        originalname: file.name,
        uploadname: file.name
      });
    });
  };


  downloadFile = function (list: any, index: any, param3?: any, param4?: any) {
    const body = { filename: list[index].uploadname };

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Accept: 'application/pdf' }),
      responseType: 'blob'
    };

    this.http.post(`${environment.candidaturesApi}/api/candidatures/downloadfile`, body, options).subscribe(
      data => {
        this.saveFile(data, list[index].originalname);
      }
    );
  };

  removeFile = function (list, index) {
    list.splice(index, 1);
  };

  async updateAttachment(originalList, currentList, uploader, ID) {
    if (!originalList) {
      originalList = [];
    }

    // Delete
    if (originalList.length) {
      for (const originalAttachment of originalList) {
        let found = false;
        currentList.forEach((currentAttachment) => {
          if (currentAttachment.uploadname === originalAttachment.uploadname) {
            found = true;
          }
        });
        if (!found) {
          await this.deleteFile(originalAttachment.uploadname).then(
            result => {
              // console.log(originalAttachment.uploaderName + ' delete success');
            },
            error => {
              return Promise.reject('Delete file failed');
            }
          );
        }
      }
    }

    // Upload
    for (const file of uploader.files) {
      await this.uploadFile(file, ID).then(
        result => {
          const date = new Date();
          currentList.push({
            originalname: result['originalname'],
            uploadname: result['uploadname'],
            uploadtime: date.getDate() + ' ' + date.toLocaleString('default', { month: 'short' }) + ' ' + date.getFullYear(),
          });
        },
        error => {
          return Promise.reject('Upload file failed');
        }
      );
    }
    uploader.files = [];

    return Promise.resolve();
  }

  deleteFile = function (name) {
    const body = { filename: name };

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      responseType: 'blob'
    };
    return this.http.post(`${environment.candidaturesApi}/api/candidatures/deletefile`, body, options).toPromise();
  };

  uploadFile(file, ID) {
    const formData: FormData = new FormData();

    formData.append('file', file, file.name);
    formData.append('mongoID', ID);

    return this.http.post(`${environment.candidaturesApi}/api/candidatures/uploadfiles`, formData).toPromise();
  }

  saveFile(response, filename) {
    var result = filename.match('.pdf');
    if (result) {
      let blob = new Blob([response], { type: 'application/pdf' });
      var blobURL = URL.createObjectURL(blob);
      var open = window.open(blobURL, '_blank');
      if (open == null || typeof (open) == 'undefined') {
        let a = document.createElement('a');
        a.target = '_self';
        a.href = blobURL;
        a.click();
      };
    } else {
      const blob = new Blob([response], { type: 'application/octet-stream' });
      saveAs(blob, filename);
    }
  }

}
