import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class AgendaGAStatusService {
  private JWT = new BehaviorSubject<any>('');
  private k: number;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient) { }

  setJWT(jwt) {
    this.JWT.next(jwt);
  }

  getJWT() {

    return this.JWT.asObservable();
  }

  getLookupListByCode(code) {
    return this.http.get(`${environment.lookupsApi}/api/lookups/getlookups/${code}`, {
      headers: this.headers
    });
  }

  getAgendaByBodyBySession(param) {
    return this.http.post(`${environment.lookupsApi}/api/lookups/getagendabybodybysession`, param, { headers: this.headers });
  }

  getAgendaBySessionByStatus(param) {
    return this.http.post(`${environment.eDecisionsApi}/api/agendastatus/getagendabysessionbystatus`, param, { headers: this.headers });
  }

  getAgendaDetailsBySessionByItem(param) {
    return this.http.post(`${environment.eDecisionsApi}/api/agendastatus/getagendadetailsbysessionbyitem`, param, { headers: this.headers });
  }

  updateAgendaDetailsByID(docID, tempData) {
    return this.http.put(`${environment.eDecisionsApi}/api/agendastatus/updateagendadetailsbyid/${docID}`, { tempData }, {
      headers: this.headers
    });
  }

  createAgendaDetails(param) {
    return this.http.post(`${environment.eDecisionsApi}/api/agendastatus/createagendadetails`, param, {
      headers: this.headers
    });
  };

  // setup authorization header
  onBeforeSend = function (event, uploader, type, msg) {
    event.xhr.setRequestHeader('Authorization', this.JWT.getValue());
    if (type == "validate") {
      uploader.files.forEach(file => {
        this.getImageDimension(file, (result) => {
          console.log(result);
          if (result == false) {

            msg.push("Attachment should be of jpg or jpeg or pjpeg format, size less than 240KB, between 600x600 and 1200x1200 pixels");

            event.xhr.abort();
          }
          else {
            msg.push("");
          }
        });
      });
    }
    else {
      msg.push("");
    }

  };



  onUpload(event, list, type, msgs) {
    // this.showMsg(msgs, 'upload', true, type);
    console.log(list);



    event.files.forEach(file => {
      console.log(file);
      if (type == 'validate') {
        if (list.length > 0) {
          list.splice(0, 1);
        }
      }

      list.push({
        originalname: file.name,
        uploadname: file.name


      });


    });
  };


  downloadFile = function (list: any, index: any, param3?: any, param4?: any) {
    const body = { filename: list[index].uploadname };

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Accept: 'application/pdf' }),
      responseType: 'blob'
    };

    this.http.post(`${environment.tempdecaldriverrequestsApi}/api/votes/downloadfile`, body, options).subscribe(
      data => {
        this.saveFile(data, list[index].uploadname);
      }
    );
  };

  removeFile = function (list, index) {
    list.splice(index, 1);
  };

  async updateAttachment(originalList, currentList, uploader) {
    if (!originalList) {
      originalList = [];
    }

    // Delete
    if (originalList.length) {
      for (const originalAttachment of originalList) {
        let found = false;
        currentList.forEach((currentAttachment) => {
          if (currentAttachment.uploadname === originalAttachment.uploadname) {
            found = true;
          }
        });
        if (!found) {
          await this.deleteFile(originalAttachment.uploadname).then(
            result => {
              console.log(originalAttachment.uploaderName + ' delete success');
            },
            error => {
              return Promise.reject('Delete file failed');
            }
          );
        }
      }
    }

    // Upload
    for (const file of uploader.files) {
      await this.uploadFile(file).then(
        result => {
          const date = new Date();
          currentList.push({
            originalname: result['originalname'],
            uploadname: result['uploadname'],
            uploadtime: date.getDate() + ' ' + date.toLocaleString('default', { month: 'short' }) + ' ' + date.getFullYear(),
            uploadentity: JSON.parse(localStorage.getItem('user'))['USR_Entity']
          });
        },
        error => {
          return Promise.reject('Upload file failed');
        }
      );
    }
    uploader.files = [];

    console.log(currentList);
    // return
    return Promise.resolve();
  }

  deleteFile = function (name) {
    const body = { filename: name };

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      responseType: 'blob'
    };
    return this.http.post(`${environment.tempdecaldriverrequestsApi}/api/votes/deletefile`, body, options).toPromise();
  };

  uploadFile(file) {
    const formData: FormData = new FormData();

    formData.append('file', file, file.name);

    return this.http.post(`${environment.tempdecaldriverrequestsApi}/api/votes/uploadfiles`, formData).toPromise();
  }

  saveFile(response, filename) {
    var result = filename.match('.pdf');
    if (result) {
      let blob = new Blob([response], { type: 'application/pdf' });
      var blobURL = URL.createObjectURL(blob);
      var open = window.open(blobURL, '_blank');
      if (open == null || typeof (open) == 'undefined') {
        let a = document.createElement('a');
        a.target = '_self';
        a.href = blobURL;
        a.click();
      };
    } else {
      const blob = new Blob([response], { type: 'application/octet-stream' });
      saveAs(blob, filename);
    }
  }

}
