import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { LoaderService } from './loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  // List of APIs to skip
  private apisToSkip: string[] = [
    'version.html',
    '/listofspeakers/get' // Add other APIs you want to bypass here
  ];

  constructor(private loaderService: LoaderService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Check if the URL matches any of the APIs to skip
    if (this.apisToSkip.some((api) => req.url.includes(api))) {
      return next.handle(req); // Skip the loader logic
    }
    // start our loader here
    this.loaderService.startLoader();

    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      // if the event is for http response
          if (event instanceof HttpResponse) {
        // stop our loader here
            this.loaderService.stopLoader();
          }

    }, (err: any) => {
      // if any error (not for just HttpResponse) we stop our loader bar
          this.loaderService.stopLoader();
    }));
  }

}
