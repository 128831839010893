import { Injectable } from '@angular/core';

@Injectable()
export class UtilityService {

  constructor() {

  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  ParseDateForSave(d) {
    let isDST = (d) => {
      let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
      let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
      return Math.max(jan, jul) != d.getTimezoneOffset();
    }
    let inputDate = new Date(d)

    if (isDST(inputDate)) {
      inputDate.setHours(inputDate.getHours() + 4)
    } else {
      inputDate.setHours(inputDate.getHours() + 5)
    }

    return inputDate
  }

  ParseDateForDisplay(d) {
    let isDST = (d) => {
      let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
      let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
      return Math.max(jan, jul) != d.getTimezoneOffset();
    }
    let inputDate = new Date(d)

    if (isDST(inputDate)) {
      inputDate.setHours(inputDate.getHours() - 4)
    } else {
      inputDate.setHours(inputDate.getHours() - 5)
    }
    return inputDate
  }

}
