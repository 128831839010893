import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { BehaviorSubject } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class CredentialsFileUploadService {
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private JWT = new BehaviorSubject<any>('');
  private k: number;

  constructor(private http: HttpClient) { }

  setJWT(jwt) {
    this.JWT.next(jwt);
  }

  getJWT() {
    return this.JWT.asObservable();
  }

  getFileExtension = function (filename) {
    if (filename.length == 0) return '';
    var dot = filename.lastIndexOf('.');
    if (dot == -1) return '';
    var extension = filename.substr(dot, filename.length);
    return extension;
  };

  saveFile(response, filename) {
    var result = filename.match('.pdf');
    if (result) {
      let blob = new Blob([response], { type: 'application/pdf' });
      var blobURL = URL.createObjectURL(blob);
      var open = window.open(blobURL, '_blank');
      if (open == null || typeof (open) == 'undefined') {
        let a = document.createElement('a');
        a.target = '_self';
        a.href = blobURL;
        a.click();
      };
    } else {
      const blob = new Blob([response], { type: 'application/octet-stream' });
      saveAs(blob, filename);
    }
  }

  deleteFile = function (name) {
    const body = { filename: name };

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      responseType: 'blob'
    };
    return this.http.post(`${environment.ecredentialsApi}/api/credentials/deletefile`, body, options).toPromise();
  };

  downloadFile = function (list: any, index: any, param3?: any, param4?: any) {
    const body = { filename: list[index].uploadname };

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Accept: 'application/pdf' }),
      responseType: 'blob'
    };

    this.http.post(`${environment.ecredentialsApi}/api/credentials/downloadfile`, body, options).subscribe(data => {
      this.saveFile(data, list[index].uploadname);
    });
  };

  isImage(file: File): boolean {
    return /^image\//.test(file.type);
  }

  uploadFile(file) {
    const formData: FormData = new FormData();

    formData.append('file', file, file.name);

    return this.http.post(`${environment.ecredentialsApi}/api/credentials/uploadplainfiles`, formData).toPromise();
  }

  async updateAttachment(originalList: Array<any>, currentList: Array<any>, uploader: any) {
    if (originalList.length) {
      await this.deleteFile(originalList[0].uploadname).then(
        result => {

        },
        error => {
          return Promise.reject('Delete file failed');
        }
      );
    }

    for (const file of uploader.files) {
      await this.uploadFile(file).then(
        result => {
          const date = new Date();
          currentList.push({
            originalname: result['originalname'],
            uploadname: result['uploadname'],
            uploadtime: date.getDate() + ' ' + date.toLocaleString('default', { month: 'short' }) + ' ' + date.getFullYear(),
            uploadentity: JSON.parse(localStorage.getItem('user'))['USR_Entity'],
          });
        },
        error => {
          return Promise.reject('Upload file failed');
        }
      );
    }
    uploader.files = [];
    return Promise.resolve(currentList);
  }

}
