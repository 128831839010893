import { Injectable } from '@angular/core';

@Injectable()
export class GlobalVariableService {

  public maxInputBoxLength = 500;
  public maxTextareaLength = 2000;
  public maxAttachmentSize = 209715200;

  constructor() { }

}
