import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SpeakerModalService {
  private JWT = new BehaviorSubject<any>('');
  private k: number;

  constructor(
    private http: HttpClient
  ) { }

  setJWT(jwt) {
    this.JWT.next(jwt);
  }

  getJWT() {
    return this.JWT.asObservable();
  }

  assignCurTime(model, field) {
    model[field] = new Date();
    this.calculateTime(model);
  }

  calculateTime(model) {
    if (model['SP_starttime'] && model['SP_endtime']) {
      const start = new Date(model['SP_starttime']).setSeconds(0, 0);
      const end = new Date(model['SP_endtime']).setSeconds(0, 0);
      const diff = end - start;
      if (diff < 0) {
        model['SP_time'] = 'INVALID END TIME';
      } else {
        model['SP_time'] = Math.floor(diff / 1000 / 60);
      }
    } else { }
  }

  // convert lookup data
  convertLookupData(data, prefix) {
    if (data) {
      const tempList: any = [];

      data.forEach(i => {
        const temp: any = {};

        switch (i.LKP_Code) {
          case 'LOR':
            temp[prefix + 'lvlReprOrder'] = i.LKP_Order;
            temp[prefix + 'lvlRepr'] = i.LKP_Value;
            temp[prefix + 'lvlReprShort'] = i.LKP_ValueShort;
            break;
          case 'TITLE':
            temp[prefix + 'titleOrder'] = i.LKP_Order;
            temp[prefix + 'title'] = i.LKP_Value;
            temp[prefix + 'titleShort'] = i.LKP_ValueShort;
            break;
        }

        tempList.push(temp);
      });

      return tempList;
    } else {
      return data;
    }
  }

  // convert entity data
  convertEntityData(data, prefix) {
    if (data) {
      const tempList: any = [];

      data.forEach((i) => {
        const temp: any = {};
        temp[prefix + 'entity'] = i.MC_Entity;
        temp[prefix + 'entityShort'] = i.MC_EntityShort;
        temp[prefix + 'entityLong'] = i.MC_EntityLong;
        temp[prefix + 'entityCategory'] = i.MC_Category;
        tempList.push(temp);
      });

      return tempList;
    } else {
      return data;
    }
  }

  fillOutEntity(speakerModel, missionData) {
    // tslint:disable-next-line:no-shadowed-variable
    const mission = missionData.filter(mission => {
      return mission.SP_entity === speakerModel.SP_entity.SP_entity;
    });

    if (mission && mission.length) {
      speakerModel.SP_entity = mission[0];
    } else {
      speakerModel.SP_entity.SP_entityShort = '';
      speakerModel.SP_entity.SP_entityLong = '';
      speakerModel.SP_entity.SP_entityCategory = '';
    }
  }

  // check attached file's image
  isImage(file: File): boolean {
    return /^image\//.test(file.type);
  }

  // save as pdf
  saveFile(response, filename) {
    var result = filename.match('.pdf');
    if (result) {
      let blob = new Blob([response], { type: 'application/pdf' });
      var blobURL = URL.createObjectURL(blob);
      var open = window.open(blobURL, '_blank');
      if (open == null || typeof (open) == 'undefined') {
        let a = document.createElement('a');
        a.target = '_self';
        a.href = blobURL;
        a.click();
      };
    } else {
      const blob = new Blob([response], { type: 'application/octet-stream' });
      saveAs(blob, filename);
    }
  }

  // upload file
  uploadFile(file) {
    const formData: FormData = new FormData();

    formData.append('file', file, file.name);

    return this.http.post(`${environment.eListOfSpeakersApi}/api/listofspeakers/uploadfiles`, formData).toPromise();
  }

  // delete file
  deleteFile = function (name) {
    const body = { filename: name };

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      responseType: 'blob'
    };

    return this.http.post(`${environment.eListOfSpeakersApi}/api/listofspeakers/deletefile`, body, options).toPromise();
  };

  // download file
  downloadFile = function (list: any, index: any, param3?: any, param4?: any) {
    const body = { filename: list[index].uploadname };

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Accept: 'application/pdf' }),
      responseType: 'blob'
    };

    this.http.post(`${environment.eListOfSpeakersApi}/api/listofspeakers/downloadfile`, body, options).subscribe(
      data => {
        this.saveFile(data, list[index].originalname);
      }
    );
  };

  async updateAttachment(originalList, currentList, uploader) {
    if (!originalList) {
      originalList = [];
    }

    // Delete
    if (originalList.length) {
      for (const originalAttachment of originalList) {
        let found = false;
        currentList.forEach((currentAttachment) => {
          if (currentAttachment.uploadname === originalAttachment.uploadname) {
            found = true;
          }
        });
        if (!found) {
          await this.deleteFile(originalAttachment.uploadname).then(
            result => {

            },
            error => {
              return Promise.reject('Delete file failed');
            }
          );
        }
      }
    }

    // Upload
    for (const file of uploader.files) {
      await this.uploadFile(file).then(
        result => {
          const date = new Date();
          currentList.push({
            originalname: result['originalname'],
            uploadname: result['uploadname'],
            uploadtime: date.getDate() + ' ' + date.toLocaleString('default', { month: 'short' }) + ' ' + date.getFullYear(),
            uploadentity: JSON.parse(localStorage.getItem('user'))['USR_Entity']
          });
        },
        error => {
          if (error.status === 500) {
            return Promise.reject('Please contact technical support');
          } else {
            return Promise.reject('Upload file failed');
          }
        }
      );
    }
    uploader.files = [];

    // return
    return Promise.resolve();
  }

  downloadAllFiles = function (list) {
    var index = 0;
    list.forEach(file => {
      const body = { filename: file.uploadname };

      const options = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Accept: "application/pdf",
        }),
        responseType: "blob",
      };
      var filename = file.originalname;
      index++;
      this.http
        .post(`${environment.eListOfSpeakersApi}/api/listofspeakers/downloadfile`, body, options)
        .subscribe((data) => {
          this.saveFileDownload(data, filename);
        });
    });
  };

  getSpeakerOrder(speaker) {
    this.k = speaker['SP_slotNo'] - 1;
  }

  checkSpeakerOrderChange(speaker, speakersList) {

    if (speaker['SP_slotNo'] - 0 === this.k + 1) {
      speakersList.splice(this.k, 1, speaker);
    } else {
      if (speaker['SP_slotNo'] <= speakersList.length && speaker['SP_slotNo'] >= 1) {
        speakersList.splice(this.k, 1);
        speakersList.splice(speaker['SP_slotNo'] - 1, 0, speaker);
      } else {
        speaker['SP_slotNo'] = this.k + 1;
        speakersList.splice(this.k, 1, speaker);
      }
    }

    speakersList.forEach(i => {
      i['SP_slotNo'] = speakersList.indexOf(i) + 1;
    });
  }

  removeSpeakerFromList(speaker, speakersList) {
    speakersList.splice(speaker['SP_slotNo'] - 1, 1);

    speakersList.forEach(i => {
      i['SP_slotNo'] = speakersList.indexOf(i) + 1;
    });
  }
}
