<!-- {{ item.name.length > 53 ? (item.name) : (item.name | truncate:53:'...') }} -->
<a mat-list-item
    [ngStyle]="getStyles()"
    [ngClass]="isMobile && checkStringLength(item.name) ? 'mod-height': ''"
    class="mat-list-item legibility"
    [href]="item.external ? item.external : 'javascript:void(0);'"
    (click)="onItemSelected(item, $event)">
    {{ item.name | translate }}
    <span class="chevron" *ngIf="item.children && item.children.length">
        <mat-icon [ngClass]="expanded ? 'open':'closed'">
            chevron_right
        </mat-icon>
    </span>
</a>
<mat-divider></mat-divider>
<div *ngIf="expanded">
    <app-menu-list-item (closeMenu)="closeMenu.emit(1)" *ngFor="let child of item.children" [language]="language" [item]="child" [depth]="depth+1">
    </app-menu-list-item>
</div>
