
import { filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css']
})
export class BreadcrumbsComponent implements OnInit {
  tempState = [];
  breadcrumbs: Array<Object>;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService) { }

  ngOnInit() {
    this.breadcrumbs = [];
    this.tempState = [];
    let currentRoute = this.route.root,
      url = '';
    do {
      const childrenRoutes = currentRoute.children;
      currentRoute = null;
      childrenRoutes.forEach(routes => {
        if (routes.outlet === 'primary') {
          const routeSnapshot = routes.snapshot;
          url += '/' + routeSnapshot.url.map(segment => segment.path).join('/');
          if (routes.snapshot.data.title !== undefined) {
            let status = true;
            if (routes.snapshot.data.status !== undefined) {
              status = routes.snapshot.data.status;
            }

            let icon = false;
            if (routes.snapshot.data.icon !== undefined) {
              icon = routes.snapshot.data.icon;
            }

            let caption = false;
            if (routes.snapshot.data.caption !== undefined) {
              caption = routes.snapshot.data.caption;
            }

            if (!this.tempState.includes(routes.snapshot.data.title)) {
              this.tempState.push(routes.snapshot.data.title);
              if (url.includes("meetingsAtUNHQC3")) {
                this.breadcrumbs.push({
                  label: "Third " + routes.snapshot.data.title,
                  icon: icon,
                  caption: caption,
                  status: status,
                  url: url
                });
              }
              else if (url.includes("CSOCDMeetings")) {
                console.log("here");
                this.breadcrumbs.push({
                  label: "Commission Meeting",
                  icon: icon,
                  caption: caption,
                  status: status,
                  url: url
                });
              }
              else if (url.includes("CSW62MeetingsInUNHQ")) {
                this.breadcrumbs.push({
                  label: "CSW Meetings",
                  icon: icon,
                  caption: caption,
                  status: status,
                  url: url
                });
              }
              else if (url.includes("CPDMeetings")) {
                console.log("here");
                this.breadcrumbs.push({
                  label: "Commission Meeting",
                  icon: icon,
                  caption: caption,
                  status: status,
                  url: url
                });
              }
              else if (url.includes("meetingsAtUNHQC2")) {
                this.breadcrumbs.push({
                  label: "Second " + routes.snapshot.data.title,
                  icon: icon,
                  caption: caption,
                  status: status,
                  url: url
                });
              }
              else if (url.includes("ecosocmeetings")) {
                this.breadcrumbs.push({
                  label: "ECOSOC Meetings",
                  icon: icon,
                  caption: caption,
                  status: status,
                  url: url
                });
              }
              else if (url.includes("ldcmeetings")) {
                this.breadcrumbs.push({
                  label: "Conference Meetings",
                  icon: icon,
                  caption: caption,
                  status: status,
                  url: url
                });
              }
              else if (url.includes("gstc")) {
                this.breadcrumbs.push({
                  label: "Second Global Sustainable Transport Conference",
                  icon: icon,
                  caption: caption,
                  status: status,
                  url: url
                });
              }
              else if (url.includes("meetingsAtUNFF")) {
                this.breadcrumbs.push({
                  label: "UNFF Meetings",
                  icon: icon,
                  caption: caption,
                  status: status,
                  url: url
                });
              }
              else if (url.includes("secondCommittee/contactList")) {
                this.breadcrumbs.push({
                  label: "Second Committee" + routes.snapshot.data.title,
                  icon: icon,
                  caption: caption,
                  status: status,
                  url: url
                });
              }
              else if (url.includes("thirdCommittee/contactList")) {
                this.breadcrumbs.push({
                  label: "Third Committee" + routes.snapshot.data.title,
                  icon: icon,
                  caption: caption,
                  status: status,
                  url: url
                });
              }
              else if (url.includes("sixthCommittee/contactList")) {
                this.breadcrumbs.push({
                  label: "Sixth Committee" + routes.snapshot.data.title,
                  icon: icon,
                  caption: caption,
                  status: status,
                  url: url
                });
              }
              else if (url.includes("committeeforprogrammeandcoordination/contactList")) {
                this.breadcrumbs.push({
                  label: "Committee for Programme and Coordination" + routes.snapshot.data.title,
                  icon: icon,
                  caption: caption,
                  status: status,
                  url: url
                });
              }
              else {
                this.breadcrumbs.push({
                  label: routes.snapshot.data.title,
                  icon: icon,
                  caption: caption,
                  status: status,
                  url: url
                });
              }

            }
          }
          currentRoute = routes;
        }
      });
    } while (currentRoute);

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.breadcrumbs = [];
        this.tempState = [];
        currentRoute = this.route.root,
          url = '';
        do {
          const childrenRoutes = currentRoute.children;
          currentRoute = null;
          childrenRoutes.forEach(routes => {
            if (routes.outlet === 'primary') {
              const routeSnapshot = routes.snapshot;
              url += '/' + routeSnapshot.url.map(segment => segment.path).join('/');
              if (routes.snapshot.data.title !== undefined) {
                let status = true;
                if (routes.snapshot.data.status !== undefined) {
                  status = routes.snapshot.data.status;
                }

                let icon = false;
                if (routes.snapshot.data.icon !== undefined) {
                  icon = routes.snapshot.data.icon;
                }

                let caption = false;
                if (routes.snapshot.data.caption !== undefined) {
                  caption = routes.snapshot.data.caption;
                }

                if (!this.tempState.includes(routes.snapshot.data.title)) {
                  this.tempState.push(routes.snapshot.data.title);
                  if (url.includes("meetingsAtUNHQC3")) {
                    this.breadcrumbs.push({
                      label: "Third " + routes.snapshot.data.title,
                      icon: icon,
                      caption: caption,
                      status: status,
                      url: url
                    });
                  }
                  else if (url.includes("CSOCDMeetings")) {
                    this.breadcrumbs.push({
                      label: "Commission Meeting",
                      icon: icon,
                      caption: caption,
                      status: status,
                      url: url
                    });
                  }
                  else if (url.includes("CSW62MeetingsInUNHQ")) {
                    this.breadcrumbs.push({
                      label: "CSW Meetings",
                      icon: icon,
                      caption: caption,
                      status: status,
                      url: url
                    });
                  }
                  else if (url.includes("ecosocmeetings")) {
                    this.breadcrumbs.push({
                      label: "ECOSOC Meetings",
                      icon: icon,
                      caption: caption,
                      status: status,
                      url: url
                    });
                  }
                  else if (url.includes("ldcmeetings")) {
                    this.breadcrumbs.push({
                      label: "Conference Meetings",
                      icon: icon,
                      caption: caption,
                      status: status,
                      url: url
                    });
                  }
                  else if (url.includes("gstc")) {
                    this.breadcrumbs.push({
                      label: "Second Global Sustainable Transport Conference",
                      icon: icon,
                      caption: caption,
                      status: status,
                      url: url
                    });
                  }
                  else if (url.includes("meetingsAtUNFF")) {
                    this.breadcrumbs.push({
                      label: "UNFF Meetings",
                      icon: icon,
                      caption: caption,
                      status: status,
                      url: url
                    });
                  }
                  else if (url.includes("CPDMeetings")) {
                    this.breadcrumbs.push({
                      label: "Commission Meeting",
                      icon: icon,
                      caption: caption,
                      status: status,
                      url: url
                    });
                  }
                  else if (url.includes("meetingsAtUNHQC2")) {
                    this.breadcrumbs.push({
                      label: "Second " + routes.snapshot.data.title,
                      icon: icon,
                      caption: caption,
                      status: status,
                      url: url
                    });
                  }
                  else if (url.includes("secondCommittee/contactList")) {
                    this.breadcrumbs.push({
                      label: "Second Committee" + routes.snapshot.data.title,
                      icon: icon,
                      caption: caption,
                      status: status,
                      url: url
                    });
                  }
                  else if (url.includes("thirdCommittee/contactList")) {
                    this.breadcrumbs.push({
                      label: "Third Committee" + routes.snapshot.data.title,
                      icon: icon,
                      caption: caption,
                      status: status,
                      url: url
                    });
                  }
                  else if (url.includes("sixthCommittee/contactList")) {
                    this.breadcrumbs.push({
                      label: "Sixth Committee" + routes.snapshot.data.title,
                      icon: icon,
                      caption: caption,
                      status: status,
                      url: url
                    });
                  }
                  else {
                    this.breadcrumbs.push({
                      label: routes.snapshot.data.title,
                      icon: icon,
                      caption: caption,
                      status: status,
                      url: url
                    });
                  }

                }
              }
              currentRoute = routes;
            }
          });
        } while (currentRoute);
      });
  }
}
