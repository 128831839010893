import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class TicketsPLSService {
  private JWT = new BehaviorSubject<any>('');
  private k: number;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient) { }

  setJWT(jwt) {
    this.JWT.next(jwt);
  }

  getJWT() {


    return this.JWT.asObservable();
  }
  getLookupListByCode(code) {
    return this.http.get(`${environment.lookupsApi}/api/lookups/getlookups/${code}`, {
      headers: this.headers
    });
  }

  getMembers() {
    return this.http.get(`${environment.lookupsApi}/api/lookups/getmembership`, { headers: this.headers });
  }

  getDelegatesByMission(mission) {
    return this.http.get(`${environment.delegatesApi}/api/delegates/bymission/${mission}`, {
      headers: this.headers
    });
  }



  getAllMeetings() {
    return this.http.get(`${environment.meetingsparticipantsApi}/api/meetingsparticipants/get/all/meetings`, {
      headers: this.headers
    });

  }
  getAllOpenMeetings() {
    return this.http.get(`${environment.meetingsparticipantsApi}/api/meetingsparticipants/get/all/meetings/open`, {
      headers: this.headers
    });

  }


  addParticipant(id, countryPath, country, participant) {
    return this.http.post(`${environment.meetingsparticipantsApi}/api/meetingsparticipants/update/add-participant/${id}`, { countryPath, country, participant }, { headers: this.headers });
  };

  updateParticipant(id, countryPath, country, participant) {
    return this.http.put(`${environment.meetingsparticipantsApi}/api/meetingsparticipants/update/update-participant/${id}`, { countryPath, country, participant }, { headers: this.headers });
  };

  deleteParticipant(id, countryPath, country, participant) {
    return this.http.post(`${environment.meetingsparticipantsApi}/api/meetingsparticipants/update/delete-participant/${id}`, { countryPath, country, participant }, { headers: this.headers });
  };


  createTicketsSetup(param) {
    return this.http.post(`${environment.eregistrationApi}/api/tickets-pls/createsetup`, param, { headers: this.headers });
  };

  createTickets(param) {
    return this.http.post(`${environment.eregistrationApi}/api/tickets-pls/createtickets`, param, { headers: this.headers });
  };

  getTicketsSetups() {
    return this.http.get(`${environment.eregistrationApi}/api/tickets-pls/getsetups`, { headers: this.headers });
  };

  getTicketsSetupByID(id) {
    return this.http.get(`${environment.eregistrationApi}/api/tickets-pls/getsetupbyid/${id}`, { headers: this.headers });
  };

  getTicketsSetupByStatusOpen() {
    return this.http.get(`${environment.eregistrationApi}/api/tickets-pls/getopensetup`, { headers: this.headers });
  };

  getTicketsBySetupIDByEntity(param) {
    return this.http.post(`${environment.eregistrationApi}/api/tickets-pls/getticketsbysetupidbyentity`, param, { headers: this.headers });
  };

  getTicketsBySetupID(id) {
    return this.http.get(`${environment.eregistrationApi}/api/tickets-pls/getticketsbysetupid/${id}`, { headers: this.headers });
  };

  updateTicketsSetupByID(param) {
    return this.http.put(`${environment.eregistrationApi}/api/tickets-pls/updatesetupbyid`, param, { headers: this.headers });
  };

  updateTicketsDateTimeByID(param) {
    return this.http.put(`${environment.eregistrationApi}/api/tickets-pls/updateticketsdatetimebyid`, param, { headers: this.headers });
  };

  updateTicketsVIPByID(param) {
    return this.http.put(`${environment.eregistrationApi}/api/tickets-pls/updateticketsvipbyid`, param, { headers: this.headers });
  };

  updateTicketsSectionAByID(param) {
    return this.http.put(`${environment.eregistrationApi}/api/tickets-pls/updateticketssectionabyid`, param, { headers: this.headers });
  };

  updateTicketsBalconyByID(param) {
    return this.http.put(`${environment.eregistrationApi}/api/tickets-pls/updateticketsbalconybyid`, param, { headers: this.headers });
  };

  updateTicketsContactByID(param) {
    return this.http.put(`${environment.eregistrationApi}/api/tickets-pls/updateticketscontactbyid`, param, { headers: this.headers });
  };


  deleteTicketsVIPByID(param) {
    return this.http.put(`${environment.eregistrationApi}/api/tickets-pls/deleteticketsvipbyid`, param, { headers: this.headers });
  };

  deleteTicketsSectionAByID(param) {
    return this.http.put(`${environment.eregistrationApi}/api/tickets-pls/deleteticketssectionabyid`, param, { headers: this.headers });
  };

  deleteTicketsBalconyByID(param) {
    return this.http.put(`${environment.eregistrationApi}/api/tickets-pls/deleteticketsbalconybyid`, param, { headers: this.headers });
  };

}
