import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class MissionStaffService {
  private JWT = new BehaviorSubject<any>('');
  private k: number;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private requestType: string = "";

  constructor(private http: HttpClient) { }

  setJWT(jwt) {
    this.JWT.next(jwt);
  }

  getJWT() {
    return this.JWT.asObservable();
  }

  getEntity(entity) {
    return this.http.get(`${environment.lookupsApi}/api/lookups/getentity/${entity}`, {
      headers: this.headers
    });
  }

  getLookupListByCode(code) {
    return this.http.get(`${environment.lookupsApi}/api/lookups/getlookups/${code}`, {
      headers: this.headers
    });
  }

  getDiplRanks() {
    return this.http.get(`${environment.delegatesApi}/api/delegates/getdiplranks`, {
      headers: this.headers
    });
  };

  getFunctions() {
    return this.http.get(`${environment.delegatesApi}/api/delegates/getfunctions`, {
      headers: this.headers
    });
  };

  getMartialStatus() {
    return this.http.get(`${environment.delegatesApi}/api/delegates/getmaritalstatus`, {
      headers: this.headers
    });
  };

  getNationalities() {
    return this.http.get(`${environment.delegatesApi}/api/delegates/getnationalities`, {
      headers: this.headers
    });
  };

  getPassportTypes() {
    return this.http.get(`${environment.delegatesApi}/api/delegates/getpassporttypes`, {
      headers: this.headers
    });
  };

  getVisaTypes() {
    return this.http.get(`${environment.delegatesApi}/api/delegates/getvisatypes`, {
      headers: this.headers
    });
  };

  getTitles() {
    return this.http.get(`${environment.delegatesApi}/api/delegates/gettitles`, {
      headers: this.headers
    });
  };

  getRelationships() {
    return this.http.get(`${environment.delegatesApi}/api/delegates/getrelationships`, {
      headers: this.headers
    });
  };

  getRequestsByEntityByStatus(param) {
    return this.http.post(`${environment.delegatesApi}/api/delegates/getrequestsbyentitybystatus`, param, { headers: this.headers });
  };

  getDelegatesByEntity(entity) {
    return this.http.get(`${environment.delegatesApi}/api/delegates/getbyentity/${entity}`, { headers: this.headers });
  };

  getDelegateByID(id) {
    return this.http.get(`${environment.delegatesApi}/api/delegates/getdelegatebyid/${id}`, { headers: this.headers });
  };

  getDependentByID(id) {
    return this.http.get(`${environment.delegatesApi}/api/delegates/getdependentbyid/${id}`, { headers: this.headers });
  };

  getDelegateTmpByID(id) {
    return this.http.get(`${environment.delegatesApi}/api/delegates/getmissionlogbyid/${id}`, { headers: this.headers });
  };

  getMissionRequestByTmpID(param) {
    return this.http.post(`${environment.delegatesApi}/api/delegates/getmissionrequestbytmpid`, param, { headers: this.headers });
  }

  createDelegate(delegate) {
    return this.http.post(`${environment.delegatesApi}/api/delegates/createdelegate`, delegate, { headers: this.headers });
  };

  createDependent(dependent) {
    return this.http.post(`${environment.delegatesApi}/api/delegates/createdependent`, dependent, { headers: this.headers });
  };

  createMissionUpdateLog(tmpData) {
    return this.http.post(`${environment.delegatesApi}/api/delegates/createmissionupdatelog`, tmpData, { headers: this.headers });
  };

  requestNewPass(tmpData) {
    return this.http.post(`${environment.delegatesApi}/api/delegates/requestnewpass`, tmpData, { headers: this.headers });
  };

  terminateDelegateByID(tmpData) {
    return this.http.post(`${environment.delegatesApi}/api/delegates/terminatedelegatebyid`, tmpData, { headers: this.headers });
  };

  terminateDependentByID(tmpData) {
    return this.http.post(`${environment.delegatesApi}/api/delegates/terminatedependentbyid`, tmpData, { headers: this.headers });
  };

  updateNewDelegateByID(id, param) {
    return this.http.put(`${environment.delegatesApi}/api/delegates/updatenewdelegatebyid/${id}`, param, { headers: this.headers });
  }

  cancelRequestByID(id, param) {
    return this.http.put(`${environment.delegatesApi}/api/delegates/cancelrequestbyid/${id}`, param, { headers: this.headers });
  }

  formatDateField(tmpDate, type) {
    if (tmpDate != null && tmpDate != '') {
      if (type == "-") {
        var yr = tmpDate.slice(6);
        var mnth = tmpDate.slice(3, 5);
        var dy = tmpDate.slice(0, 2);
        tmpDate = yr + "-" + mnth + "-" + dy;
      }
      else {
        var yr = tmpDate.slice(0, 4);
        var mnth = tmpDate.slice(5, 7);
        var dy = tmpDate.slice(8);
        tmpDate = dy + "/" + mnth + "/" + yr;
      }
    } else {
      tmpDate = '';
    };
    return tmpDate;
  }


  // save as pdf
  saveFile(response, filename) {
    var result = filename.match('.pdf');
    if (result) {
      let blob = new Blob([response], { type: 'application/pdf' });
      var blobURL = URL.createObjectURL(blob);
      var open = window.open(blobURL, '_blank');
      if (open == null || typeof (open) == 'undefined') {
        let a = document.createElement('a');
        a.target = '_self';
        a.href = blobURL;
        a.click();
      };
    } else {
      const blob = new Blob([response], { type: 'application/octet-stream' });
      saveAs(blob, filename);
    }
  }


  uploadFile(file, ID, folder) {
    if (!file || !file.name) {
      return;
    };
    const formData: FormData = new FormData();

    formData.append('file', file, file.name);
    formData.append('mongoID', ID);
    if (folder === 'image') {
      formData.append('folder', "image");
    };

    return this.http.post(`${environment.delegatesApi}/api/delegates/uploadfiles`, formData).toPromise();
  }

  removeFile = function (list, index) {
    list.splice(index, 1);
  };


  async updateAttachment(originalList, currentList, uploader, ID, folder) {

    if (!originalList) {
      originalList = [];
    }

    // Delete
    if (originalList.length) {
      for (const originalAttachment of originalList) {
        let found = false;
        currentList.forEach((currentAttachment) => {
          if (currentAttachment.uploadname === originalAttachment.uploadname) {
            found = true;
          }
        });
        if (!found) {
          await this.deleteFile(originalAttachment.uploadname).then(
            result => {

            },
            error => {
              return Promise.reject('Delete file failed');
            }
          );
        }
      }
    }

    // Upload
    for (const file of (uploader.files || [])) {
      await this.uploadFile(file, ID, folder).then(
        result => {
          const date = new Date();
          currentList.push({
            originalname: result['originalname'],
            uploadname: result['uploadname'],
            uploadtime: date.getDate() + ' ' + date.toLocaleString('default', { month: 'short' }) + ' ' + date.getFullYear(),
            uploadentity: result['entity']
          });
        },
        error => {
          return Promise.reject('Upload file failed');
        }
      );
    }
    uploader.files = [];

    // return
    return Promise.resolve();
  }


  // setup authorization header
  onBeforeSend = async function (uploader, type, msg) {
    // event.xhr.setRequestHeader('Authorization', this.JWT.getValue());
    var length = 0;
    if (type == "validate" && uploader.files.length > 0) {

      let result = await this.getImageDimension(uploader.files[0]);

      length++;
      var flag = true;

      if (result == false) {
        msg.push("Attachment should be of jpg or jpeg or pjpeg format, size less than 240KB");
        // event.xhr.abort();
        flag = false;
      }
      else {
        msg.push("");
      }
      return flag;
    }
    else {
      msg.push("");
      return true;
    }


  };

  getImageDimension(evt) {

    var flag = false;
    var type = evt.name.split(".");
    // console.log(type[1]);
    return new Promise((resolve, reject) => {
      if ((type[1] != 'jpg' && type[1] != 'jpeg' && type[1] != 'pjpeg')) {
        return false;
      }
      let image: any = evt;
      // console.log(image.size);
      let fr = new FileReader();
      fr.onload = () => { // when file has loaded
        var img = new Image();
        img.onload = () => {
          // console.log(img.height);
          //img.width > 1200 || img.width < 600 || img.height > 1200 || img.height < 600 ||
          if (image.size > 240000 || (type[1] != 'jpg' && type[1] != 'jpeg' && type[1] != 'pjpeg')) {
            flag = false;
            resolve(flag);
          }
          else {
            flag = true;
            resolve(flag);
          }
        };
        img.src = fr.result.toString(); // The data URL 
      };
      fr.readAsDataURL(image);
    });

  };

  // on upload file
  onUpload(event, list, type, msgs) {
    // this.showMsg(msgs, 'upload', true, type);
    //console.log(list);

    event.files.forEach(file => {
      //console.log(file);
      if (type == 'validate') {
        if (list.length > 0) {
          list.splice(0, 1);
        }
      }
      list.push({
        originalname: file.name,
        uploadname: file.name
      });
    });
  };


  // delete file
  deleteFile = function (name) {
    const body = { filename: name };

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      responseType: 'blob'
    };

    return this.http.post(`${environment.delegatesApi}/api/delegates/deletefile`, body, options).toPromise();
  };


  // download file
  downloadFile = function (list: any, index: any, param3?: any, param4?: any) {
    const body = { filename: list[index].uploadname };

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Accept: 'application/pdf' }),
      responseType: 'blob'
    };

    this.http.post(`${environment.delegatesApi}/api/delegates/downloadfile`, body, options).subscribe(
      data => {
        // this.showMsg(msgs, 'delete', true, type);
        this.saveFile(data, list[index].originalname);
      }
    );
  };

  // download file
  getFile = function (list, index, type, msgs) {
    let tmpfolder = "";
    let body = {};
    if (msgs === 'image') {
      tmpfolder = "Images";
    };
    body = { filename: list[index].uploadname, folder: tmpfolder };
    if (msgs === 'plsimage') {
      tmpfolder = "Images";
      body = { filename: list, folder: tmpfolder };
    };

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Accept: 'application/pdf' }),
      responseType: 'blob'
    };

    return this.http.post(`${environment.delegatesApi}/api/delegates/downloadfile`, body, options)
  };


}
