import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AdminComponent } from "./layout/admin/admin.component";
import { AuthComponent } from "./layout/auth/auth.component";
import { AuthorizationGuard } from "./services_shared/";
import { environment } from "../environments/environment";
import { EBlueBookForPrecisWritersModule } from './views/bluebook-dd/e-blue-book-precis-writers.module';

const routes: Routes = [
  {
    path: "docs",
    component: AdminComponent,
    children: [
      {
        path: "",
        redirectTo: "doc",
        pathMatch: "full",
      },
      {
        path: "doc",
        loadChildren: () => import('./views/docs/docs-view.module').then(m => m.DocViewModule),
      },
    ],
  },
  {
    path: "portal",
    component: AdminComponent,
    children: [
      {
        path: "",
        redirectTo: "profile",
        pathMatch: "full",
      },
      {
        path: "home",
        loadChildren: () => import('./views/home-page/home-page.module').then(m => m.HomePageModule),
      },
      {
        path: "doc",
        loadChildren: () => import('./views/docs/docs-view.module').then(m => m.DocViewModule),
      },
      {
        path: "support",
        loadChildren: () => import('./views/support/support.module').then(m => m.SupportModule),
      },
      {
        path: "docs",
        loadChildren: () => import('./views/home-page/home-page.module').then(m => m.HomePageModule),
      },
      {
        path: "profile",
        loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfileModule),
      },
      {
        path: "changepassword",
        loadChildren:
          () => import('./views/change-password/change-password.module').then(m => m.ChangePasswordModule),
      },
      {
        path: "resources/access/accessNY",
        loadChildren: () => import('./views/resources/access/access.module').then(m => m.AccessModule),
        data: {
          moduleName: "AccessAdminNy",
        },
        canActivate: [AuthorizationGuard],
      },
      {
        path: "resources/access/accessGeneva",
        loadChildren: () => import('./views/resources/accessGeneva/access.module').then(m => m.AccessModule),
        data: {
          moduleName: "AccessAdminGeneva",
        },
        canActivate: [AuthorizationGuard],
      },
      {
        path: "resources/telephoneDirectory",
        loadChildren: () => import('./views/resources/telephone-directory/telephone-directory.module').then(m => m.TelephoneModule),
        data: {
          moduleName: "TelephoneDirectoryModule",
        },
      },
      {
        path: "administration/auth/accessAdmin",
        loadChildren:
          () => import('./views/resources/accessAdmin/adminAccess.module').then(m => m.AdminAccessModule),
        data: {
          moduleName: "SystemAccessManagement",
        },
        canActivate: [AuthorizationGuard],
      },
      {
        path: "administration/ListOfParticipantsSetup",
        loadChildren:
          () => import('./views/list-of-participants-setup/list-of-participants-setup.module').then(m => m.ListOfParticipantsSetupModule),
        data: {
          moduleName: "listofparticipants-secretariat",
        },
        canActivate: [AuthorizationGuard],
      },
      {
        path: "administration/eRecordedVotesSetup",
        loadChildren:
          () => import('./views/voting-recording/voting-recording-gap-secretariat/voting-recording.module').then(m => m.VotingRecordingModule),
      },
      {
        path: "administration/eRecordedVotesSetupHRC",
        loadChildren:
          () => import('./views/voting-recording/voting-recording-hrc-secretariat/voting-recording.module').then(m => m.VotingRecordingModule),
      },
      {
        path: "administration/votingIntentions",
        loadChildren:
          () => import('./views/voting-intentions/voting-intentions-secretariat/voting-intentions.module').then(m => m.VotingIntentionsModule),
      },
      {
        path: "administration/e-blue-book-precis-writers",
        data: { moduleName: "bluebook-secretariat" },
        loadChildren: () => import('./views/bluebook-dd/e-blue-book-precis-writers.module').then(m => m.EBlueBookForPrecisWritersModule),
      },
      {
        path: "administration/lookup",
        loadChildren: () => import('./views/lookups/lookups.module').then(m => m.LookupsModule),
      },
      {
        path: "administration/eregistrations/media",
        loadChildren: () => import('./views/e-registration-media/media.module').then(m => m.MediaModule),
      },
      {
        path: "administration/eregistrations/security",
        loadChildren: () => import('./views/e-registration-security/security.module').then(m => m.SecurityModule),
      },
      {
        path: "administration/eregistrations/passandid",
        loadChildren: () => import('./views/e-registration-pass-and-id/security.module').then(m => m.SecurityModule),
      },
      {
        path: "administration/technicalSupport/cache",
        loadChildren:
          () => import('./views/technical-support/redis-cache/redis-cache.module').then(m => m.RedisCacheModule),
      },
      {
        path: "administration/technicalSupport/datasync-mpm",
        loadChildren:
          () => import('./views/technical-support/datasync-mpm/datasync-mpm.module').then(m => m.DatasyncMpmModule),
      },
      {
        path: "administration/technicalSupport/import-e-registration-data",
        loadChildren:
          () => import('./views/technical-support/import-e-registration-data/import-registration-data.module').then(m => m.ImportRegistrationDataModule),
      },
      {
        path: "administration/technicalSupport/searchindex",
        loadChildren:
          () => import('./views/place/shared/search-index/search-index.module').then(m => m.SearchIndexModule),
      },
      {
        path: "administration/technicalSupport/search-index-elastic",
        loadChildren:
          () => import('./views/place/shared/search-index-elastic/search-index-elastic.module').then(m => m.SearchIndexElasticModule),
      },
      {
        path: "administration/technicalSupport/version",
        loadChildren:
          () => import('./views/technical-support/version/version.module').then(m => m.VersionModule),
      },
      {
        path: "administration/technicalSupport/bulk",
        loadChildren: () => import('./views/bulk-upload/bulk-upload.module').then(m => m.BulkUploadModule),
      },
      {
        path: "administration/technicalSupport/releaseNotes",
        loadChildren: () => import('./views/release-notes/release-notes.module').then(m => m.ReleaseNotesModule),
      },
      {
        path: "administration/listofspeakers",
        loadChildren:
          () => import('./views/published-list-of-speakers/published-list-of-speakers.module').then(m => m.PublishedListOfSpeakersModule),
      },
      {
        path: "administration/eRecordedVotesReport",
        loadChildren:
          () => import('./views/voting-recording-report/voting-recording-report.module').then(m => m.VotingRecordingReportModule),
      },
      {
        path: "administration/meetingproceedings",
        loadChildren: () => import('./views/meetings/meetings.module').then(m => m.MeetingsModule),
      },
      {
        path: "administration/gd",
        loadChildren:
          () => import('./views/list-of-speakers-gd/meetings.module').then(m => m.MeetingsModule),
      },
      {
        path: "administration/emailtemplates",
        loadChildren: () => import('./views/email-templates/email-templates.module').then(m => m.EmailTemplatesModule),
      },
      {
        path: "unauthorize",
        loadChildren:
          () => import('./views/info/unauthorize/unauthorize.module').then(m => m.UnauthorizeModule),
      },
      {
        path: "error",
        loadChildren: () => import('./views/info/error/error.module').then(m => m.ErrorModule),
      },
      {
        path: "protocol/countries",
        loadChildren:
          () => import('./views/member-countries/member-countries-list/member-countries-list.module').then(m => m.MemberCountriesListModule),
      },
      {
        path: "protocol/countries/:id",
        loadChildren:
          () => import('./views/member-countries/member-countries-details/member-countries.module').then(m => m.MemberCountriesModule),
      },
      {
        path: "protocol/eregistration",
        data: {
          moduleName: "ERegistration",
        },
        loadChildren:
          () => import('./views/e-registration/e-registration.module').then(m => m.ERegistrationModule),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "protocol/staffregistration",
        data: { moduleName: "ERegistration" },
        loadChildren: () => import('./views/e-registration-staff/staff.module').then(m => m.StaffModule),
      },
      {
        path: "protocol/emissionstaff",
        data: {
          moduleName: "mission-staff",
        },
        loadChildren:
          () => import('./views/mission-staff/mission-staff.module').then(m => m.MissionStaffModule),

        canActivate: [AuthorizationGuard],
      },
      {
        path: "generalAssembly/plenary/stvm/PowerPoint",
        loadChildren:
          () => import('./views/docs/docs-view.module').then(m => m.DocViewModule),
      },
      {
        path: "generalAssembly/plenary/stvm/PDF",
        loadChildren:
          () => import('./views/docs/docs-view.module').then(m => m.DocViewModule),
      },
      {
        path: "generalAssembly/firstCommittee/draftProposals/GuidelinesForMainSponsors",
        loadChildren:
          () => import('./views/docs/docs-view.module').then(m => m.DocViewModule),
      },
      {
        path: "generalAssembly/firstCommittee/draftProposals/GuidelinesForCoSponsors",
        loadChildren:
          () => import('./views/docs/docs-view.module').then(m => m.DocViewModule),
      },
      {
        path: "geneva/HumanRightsCouncil/GuidelinesForHRCMainSponsors",
        loadChildren:
          () => import('./views/docs/docs-view.module').then(m => m.DocViewModule),
      },
      {
        path: "geneva/HumanRightsCouncil/GuidelinesForHRCCoSponsors",
        loadChildren:
          () => import('./views/docs/docs-view.module').then(m => m.DocViewModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/HumanRightsCouncil/GuidelinesForHRCMainSponsors",
        loadChildren:
          () => import('./views/docs/docs-view.module').then(m => m.DocViewModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/HumanRightsCouncil/GuidelinesForHRCCoSponsors",
        loadChildren:
          () => import('./views/docs/docs-view.module').then(m => m.DocViewModule),
      },
      {
        path: "generalAssembly/firstCommittee/draftProposals/ProcedureForDraftSubmissions",
        loadChildren:
          () => import('./views/docs/docs-view.module').then(m => m.DocViewModule),
      },
      {
        path: "generalAssembly/firstCommittee/draftProposals/GuidelinesForMainSponsors",
        loadChildren:
          () => import('./views/docs/docs-view.module').then(m => m.DocViewModule),
      },
      {
        path: "generalAssembly/plenary/documents/SGCommunications",
        loadChildren:
          () => import('./views/docs/docs-view.module').then(m => m.DocViewModule),
      },
      {
        path: "generalAssembly/plenary/documents/DGACMServices",
        loadChildren:
          () => import('./views/docs/docs-view.module').then(m => m.DocViewModule),
      },
      {
        path: "generalAssembly/plenary/faqs",
        loadChildren:
          () => import('./views/faqs/faqs.module').then(m => m.FaqsModule),
      },
      {
        path: "generalAssembly/plenary/gaannouncements",
        data: {
          moduleName: "announcements-gaplenary",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "generalAssembly/firstCommittee/announcements",
        data: {
          moduleName: "announcements-c1",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "generalAssembly/secondCommittee/announcements",
        data: {
          moduleName: "announcements-c2",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "generalAssembly/thirdCommittee/announcements",
        data: {
          moduleName: "announcements-c3",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "generalAssembly/fourthCommittee/announcements",
        data: {
          moduleName: "announcements-c4",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "generalAssembly/fifthCommittee/announcements",
        data: {
          moduleName: "announcements-c5",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "generalAssembly/sixthCommittee/announcements",
        data: {
          moduleName: "announcements-c6",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "securitycouncil/announcements",
        data: {
          moduleName: "announcements-sc",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "ecosoc/commissionForSocialDevelopment/announcements",
        data: {
          moduleName: "announcements-csocd",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "ecosoc/commissionOnPopulationAndDevelopment/announcements",
        data: {
          moduleName: "announcements-cpd",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "ecosoc/committeeforprogrammeandcoordination/announcements",
        data: {
          moduleName: "announcements-cpc",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "ecosoc/commissionOnTheStatusOfWomen/announcements",
        data: {
          moduleName: "announcements-csw",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "ecosoc/unitedNationsForumOnForests/announcements",
        data: {
          moduleName: "announcements-ff",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "ecosoc/permanentForumOnIndigenousIssues/announcements",
        data: {
          moduleName: "announcements-pfii",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "ecosoc/permanentForumOnIndigenousIssues/place",
        data: {
          moduleName: "place-pfii",
        },
        loadChildren:
          () => import('./views/place/place-pfii/place-details/place-pfii-details.module').then(m => m.PlacePfiiDetailsModule),
      },
      {
        path: "ecosoc/UnitedNationsGroupofExpertsonGeographicalNames/Announcements",
        data: {
          moduleName: "announcements-ungegn",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "ecosoc/UnitedNationsCommitteeofExpertsonGGIM/Announcements",
        data: {
          moduleName: "announcements-ggim",
        },
        loadChildren:
          "./views/announcements/announcements.module#AnnouncementsModule",
      },
      {
        path: "ecosoc/en/announcements",
        data: {
          moduleName: "announcements-ecosoc",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "Conference/ldc/announcements",
        data: {
          moduleName: "announcements-confldc",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "Conference/ocean/announcements",
        data: {
          moduleName: "announcements-confocean",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "Conference/stockholm50/announcements",
        data: {
          moduleName: "announcements-confst50",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "Conference/confmezfnwmd/announcements",
        data: {
          moduleName: "announcements-confmezfnwmd",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "Conference/confsids/prepcom/announcements",
        data: {
          moduleName: "announcements-confsids",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "Conference/confsids/sids/announcements",
        data: {
          moduleName: "announcements-confsids",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "Conference/confcd/announcements",
        data: {
          moduleName: "announcements-confcd",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "geneva/gencd/announcements",
        data: {
          moduleName: "announcements-confcd",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "nairobi/unep/cpr/announcements",
        data: {
          moduleName: "announcements-cpr",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "nairobi/unep/unea/announcements",
        data: {
          moduleName: "announcements-unea",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "Conference/conflldc/prepcomlldc/announcements",
        data: {
          moduleName: "announcements-conflldc",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "Conference/confsalw/prepcomsalw/announcements",
        data: {
          moduleName: "announcements-confsalw",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "Conference/confsalw/salw/announcements",
        data: {
          moduleName: "announcements-confsalw",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "Conference/conficffd/prepcomicffd/announcements",
        data: {
          moduleName: "announcements-conficffd",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "Conference/conficffd/prepcomicffd/place",
        loadChildren:
          () => import('./views/place/place-conficffd/place-details/place-conficffd-details.module').then(m => m.PlaceConfIcffdDetailsModule),
      },
      {
        path: "Conference/conficffd/prepcomicffd/place/create/new",
        loadChildren:
          () => import('./views/place/place-conficffd/place-create/place-conficffd-create.module').then(m => m.PlaceConfIcffdCreateModule),
      },
      {
        path: "Conference/conficffd/prepcomicffd/place/search",
        loadChildren:
          () => import('./views/place/place-conficffd/place-search/place-conficffd-search.module').then(m => m.PlaceConfIcffdSearchModule),
      },
      {
        path: "Conference/tpnw/place",
        loadChildren:
          () => import('./views/place/place-oda-tpnw/place-details/place-details.module').then(m => m.PlaceDetailsModule),
      },
      {
        path: "Conference/tpnw/place/create/new",
        loadChildren:
          () => import('./views/place/place-oda-tpnw/place-create/place-create.module').then(m => m.PlaceCreateModule),
      },
      {
        path: "Conference/tpnw/place/search",
        loadChildren:
          () => import('./views/place/place-oda-tpnw/place-search/place-search.module').then(m => m.PlaceSearchModule),
      },
      {
        path: "nairobi/unep/unea/place",
        loadChildren:
          () => import('./views/place/place-oda-unea/place-details/place-details.module').then(m => m.PlaceDetailsModule),
      },
      {
        path: "nairobi/unep/unea/place/create/new",
        loadChildren:
          () => import('./views/place/place-oda-unea/place-create/place-create.module').then(m => m.PlaceCreateModule),
      },
      {
        path: "nairobi/unep/unea/place/search",
        loadChildren:
          () => import('./views/place/place-oda-unea/place-search/place-search.module').then(m => m.PlaceSearchModule),
      },
      {
        path: "nairobi/unep/cpr/place",
        loadChildren:
          () => import('./views/place/place-oda-cpr/place-details/place-details.module').then(m => m.PlaceDetailsModule),
      },
      {
        path: "nairobi/unep/cpr/place/create/new",
        loadChildren:
          () => import('./views/place/place-oda-cpr/place-create/place-create.module').then(m => m.PlaceCreateModule),
      },
      {
        path: "nairobi/unep/cpr/place/search",
        loadChildren:
          () => import('./views/place/place-oda-cpr/place-search/place-search.module').then(m => m.PlaceSearchModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/OEWGonConvAmm/place",
        loadChildren:
          () => import('./views/place/place-oda-oewgca/place-details/place-details.module').then(m => m.PlaceDetailsModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/OEWGonConvAmm/place/create/new",
        loadChildren:
          () => import('./views/place/place-oda-oewgca/place-create/place-create.module').then(m => m.PlaceCreateModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/OEWGonConvAmm/place/search",
        loadChildren:
          () => import('./views/place/place-oda-oewgca/place-search/place-search.module').then(m => m.PlaceSearchModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/AHWGArevitalization/Announcements",
        data: {
          moduleName: "announcements-ahwggar",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/ahict/Announcements",
        data: {
          moduleName: "announcements-ahict",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/ahict/place",
        loadChildren:
          () => import('./views/place/place-ahict/place-details/place-ahict-details.module').then(m => m.PlaceAhictDetailsModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/ahict/place/create/new",
        loadChildren:
          () => import('./views/place/place-ahict/place-create/place-ahict-create.module').then(m => m.PlaceAhictCreateModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/ahict/place/search",
        loadChildren:
          () => import('./views/place/place-ahict/place-search/place-ahict-search.module').then(m => m.PlaceAhictSearchModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/OEWGonSecurity/Announcements",
        data: {
          moduleName: "announcements-oewgcs",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/BiennialMeetingonSmallArms/Announcements",
        data: {
          moduleName: "announcements-bmsa",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/coc/Announcements",
        data: {
          moduleName: "announcements-coc",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/disarmamentCommission/UNDCAnnouncements",
        data: {
          moduleName: "announcements-dc",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/specialCommitteeOnPeacekeepingOperations/announcements",
        data: {
          moduleName: "announcements-c34",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/SpecialCommitteeonDecolonization/Announcements",
        data: {
          moduleName: "announcements-c24",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/OEWGonAgeing/Announcements",
        data: {
          moduleName: "announcements-oewga",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/OEWGonConvAmm/Announcements",
        data: {
          moduleName: "announcements-oewgca",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/OEWGonInformationandTelecommunications/Announcements",
        data: {
          moduleName: "announcements-oewgcs",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "ecosoc/StatisticalCommission/announcements",
        data: {
          moduleName: "announcements-statcomm",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "generalAssembly/searchelectedofficers",
        data: {
          moduleName: "searchelectedofficers",
        },
        loadChildren:
          () => import('./views/elected-officer/elected-officer/elected-officer.module').then(m => m.ElectedOfficerModule),
      },
      {
        path: "ecosoc/en/searchmembership",
        data: {
          moduleName: "searchmembership",
        },
        loadChildren:
          () => import('./views/elected-officer/ecosoc-membership/ecosoc-membership.module').then(m => m.EcosocMembershipModule),
      },
      {
        path: "resources/meetings/meetingRoom",
        data: {
          moduleName: "meetingRoom",
        },
        loadChildren:
          () => import('./views/meeting-rooms/meeting-rooms.module').then(m => m.MeetingRoomsModule),
      },
      {
        path: "whatsNew/secretarygeneralletters",
        data: {
          moduleName: "secretarygeneralletters",
        },
        loadChildren:
          () => import('./views/sg-letters/sg-letters.module').then(m => m.SGLettersModule),
      },
      {
        path: "placesc",
        loadChildren:
          () => import('./views/place/place-scd/place-scd-details/place-scd-details.module').then(m => m.PlaceScdDetailsModule),
        data: {
          moduleName: "place-sc",
        },
        canActivate: [AuthorizationGuard],
      },
      {
        path: "placesc751",
        loadChildren:
          () => import('./views/place/place-sc751/place-scd-details/place-sc751-details.module').then(m => m.PlaceSc751DetailsModule),
        data: { moduleName: "place-sc751" },
        canActivate: [AuthorizationGuard],
      },
      {
        path: "placesciwg",
        loadChildren:
          () => import('./views/place/place-sciwg/place-scd-details/place-sciwg-details.module').then(m => m.PlaceScIwgDetailsModule),
        data: { moduleName: "place-sciwg" },
        canActivate: [AuthorizationGuard],
      },
      {
        path: "placesc1267",
        loadChildren:
          () => import('./views/place/place-sc1267/place-scd-details/place-sc1267-details.module').then(m => m.PlaceSc1267DetailsModule),
        data: { moduleName: "place-sc1267" },
        canActivate: [AuthorizationGuard],
      },
      {
        path: "placesc1373",
        loadChildren:
          () => import('./views/place/place-sc1373/place-scd-details/place-sc1373-details.module').then(m => m.PlaceSc1373DetailsModule),
        data: { moduleName: "place-sc1373" },
        canActivate: [AuthorizationGuard],
      },
      {
        path: "placesc1518",
        loadChildren:
          () => import('./views/place/place-sc1518/place-scd-details/place-sc1518-details.module').then(m => m.PlaceSc1518DetailsModule),
        data: { moduleName: "place-sc1518" },
        canActivate: [AuthorizationGuard],
      },
      {
        path: "placesc1533",
        loadChildren:
          () => import('./views/place/place-sc1533/place-scd-details/place-sc1533-details.module').then(m => m.PlaceSc1533DetailsModule),
        data: { moduleName: "place-sc1533" },
        canActivate: [AuthorizationGuard],
      },
      {
        path: "placesc1540",
        loadChildren:
          () => import('./views/place/place-sc1540/place-scd-details/place-sc1540-details.module').then(m => m.PlaceSc1540DetailsModule),
        data: { moduleName: "place-sc1540" },
        canActivate: [AuthorizationGuard],
      },
      {
        path: "placesc1591",
        loadChildren:
          () => import('./views/place/place-sc1591/place-scd-details/place-sc1591-details.module').then(m => m.PlaceSc1591DetailsModule),
        data: { moduleName: "place-sc1591" },
        canActivate: [AuthorizationGuard],
      },
      {
        path: "placesc1718",
        loadChildren:
          () => import('./views/place/place-sc1718/place-scd-details/place-sc1718-details.module').then(m => m.PlaceSc1718DetailsModule),
        data: { moduleName: "place-sc1718" },
        canActivate: [AuthorizationGuard],
      },
      {
        path: "placesc1970",
        loadChildren:
          () => import('./views/place/place-sc1970/place-scd-details/place-sc1970-details.module').then(m => m.PlaceSc1970DetailsModule),
        data: { moduleName: "place-sc1970" },
        canActivate: [AuthorizationGuard],
      },
      {
        path: "placesc1988",
        loadChildren:
          () => import('./views/place/place-sc1988/place-scd-details/place-sc1988-details.module').then(m => m.PlaceSc1988DetailsModule),
        data: { moduleName: "place-sc1988" },
        canActivate: [AuthorizationGuard],
      },
      {
        path: "placesc2048",
        loadChildren:
          () => import('./views/place/place-sc2048/place-scd-details/place-sc2048-details.module').then(m => m.PlaceSc2048DetailsModule),
        data: { moduleName: "place-sc2048" },
        canActivate: [AuthorizationGuard],
      },
      {
        path: "placesc2140",
        loadChildren:
          () => import('./views/place/place-sc2140/place-scd-details/place-sc2140-details.module').then(m => m.PlaceSc2140DetailsModule),
        data: { moduleName: "place-sc2140" },
        canActivate: [AuthorizationGuard],
      },
      {
        path: "placesc2745",
        loadChildren:
          () => import('./views/place/place-sc2127/place-scd-details/place-sc2127-details.module').then(m => m.PlaceSc2127DetailsModule),
        data: { moduleName: "place-sc2127" },
        canActivate: [AuthorizationGuard],
      },
      {
        path: "placesc2206",
        loadChildren:
          () => import('./views/place/place-sc2206/place-scd-details/place-sc2206-details.module').then(m => m.PlaceSc2206DetailsModule),
        data: { moduleName: "place-sc2206" },
        canActivate: [AuthorizationGuard],
      },
      {
        path: "placesc2231",
        loadChildren:
          () => import('./views/place/place-sc2231/place-scd-details/place-sc2231-details.module').then(m => m.PlaceSc2231DetailsModule),
        data: { moduleName: "place-sc2231" },
        canActivate: [AuthorizationGuard],
      },
      {
        path: "placesc2374",
        loadChildren:
          () => import('./views/place/place-sc2374/place-scd-details/place-sc2374-details.module').then(m => m.PlaceSc2374DetailsModule),
        data: { moduleName: "place-sc2374" },
        canActivate: [AuthorizationGuard],
      },
      {
        path: "placesc2653",
        loadChildren:
          () => import('./views/place/place-sc2653/place-scd-details/place-sc2653-details.module').then(m => m.PlaceSc2653DetailsModule),
        data: { moduleName: "place-sc2653" },
        canActivate: [AuthorizationGuard],
      },
      {
        path: "placewgcaac",
        loadChildren:
          () => import('./views/place/place-wgcaac/place-scd-details/place-wgcaac-details.module').then(m => m.PlaceWgcaacDetailsModule),
        data: { moduleName: "place-wgcaac" },
        canActivate: [AuthorizationGuard],
      },
      {
        path: "securitycouncil/placesc",
        redirectTo: "placesc",
        pathMatch: "full",
      },
      {
        path: "placesc/create/new",
        loadChildren:
          () => import('./views/place/place-scd/place-scd-create/place-scd-create.module').then(m => m.PlaceScdCreateModule),
      },
      {
        path: "placesc/search",
        loadChildren:
          () => import('./views/place/place-scd/place-scd-search/place-scd-search.module').then(m => m.PlaceScdSearchModule),
      },
      {
        path: "securitycouncil/SubsidiaryOrgans/placesc751",
        redirectTo: "placesc751",
        pathMatch: "full",
      },
      {
        path: "placesc751/create/new",
        loadChildren:
          () => import('./views/place/place-sc751/place-scd-create/place-sc751-create.module').then(m => m.PlaceSc751CreateModule),
      },
      {
        path: "placesc751/search",
        loadChildren:
          () => import('./views/place/place-sc751/place-scd-search/place-sc751-search.module').then(m => m.PlaceSc751SearchModule),
      },
      {
        path: "securitycouncil/SubsidiaryOrgans/placesciwg",
        redirectTo: "placesciwg",
        pathMatch: "full",
      },
      {
        path: "placesciwg/create/new",
        loadChildren:
          () => import('./views/place/place-sciwg/place-scd-create/place-sciwg-create.module').then(m => m.PlaceScIwgCreateModule),
      },
      {
        path: "placesciwg/search",
        loadChildren:
          () => import('./views/place/place-sciwg/place-scd-search/place-sciwg-search.module').then(m => m.PlaceScIwgSearchModule),
      },
      {
        path: "securitycouncil/SubsidiaryOrgans/placesc1267",
        redirectTo: "placesc1267",
        pathMatch: "full",
      },
      {
        path: "placesc1267/create/new",
        loadChildren:
          () => import('./views/place/place-sc1267/place-scd-create/place-sc1267-create.module').then(m => m.PlaceSc1267CreateModule),
      },
      {
        path: "placesc1267/search",
        loadChildren:
          () => import('./views/place/place-sc1267/place-scd-search/place-sc1267-search.module').then(m => m.PlaceSc1267SearchModule),
      },
      {
        path: "securitycouncil/SubsidiaryOrgans/placesc1373",
        redirectTo: "placesc1373",
        pathMatch: "full",
      },
      {
        path: "placesc1373/create/new",
        loadChildren:
          () => import('./views/place/place-sc1373/place-scd-create/place-sc1373-create.module').then(m => m.PlaceSc1373CreateModule),
      },
      {
        path: "placesc1373/search",
        loadChildren:
          () => import('./views/place/place-sc1373/place-scd-search/place-sc1373-search.module').then(m => m.PlaceSc1373SearchModule),
      },
      {
        path: "securitycouncil/SubsidiaryOrgans/placesc1518",
        redirectTo: "placesc1518",
        pathMatch: "full",
      },
      {
        path: "placesc1518/create/new",
        loadChildren:
          () => import('./views/place/place-sc1518/place-scd-create/place-sc1518-create.module').then(m => m.PlaceSc1518CreateModule),
      },
      {
        path: "placesc1518/search",
        loadChildren:
          () => import('./views/place/place-sc1518/place-scd-search/place-sc1518-search.module').then(m => m.PlaceSc1518SearchModule),
      },
      {
        path: "securitycouncil/SubsidiaryOrgans/placesc1533",
        redirectTo: "placesc1533",
        pathMatch: "full",
      },
      {
        path: "placesc1533/create/new",
        loadChildren:
          () => import('./views/place/place-sc1533/place-scd-create/place-sc1533-create.module').then(m => m.PlaceSc1533CreateModule),
      },
      {
        path: "placesc1533/search",
        loadChildren:
          () => import('./views/place/place-sc1533/place-scd-search/place-sc1533-search.module').then(m => m.PlaceSc1533SearchModule),
      },
      {
        path: "securitycouncil/SubsidiaryOrgans/placesc1540",
        redirectTo: "placesc1540",
        pathMatch: "full",
      },
      {
        path: "placesc1540/create/new",
        loadChildren:
          () => import('./views/place/place-sc1540/place-scd-create/place-sc1540-create.module').then(m => m.PlaceSc1540CreateModule),
      },
      {
        path: "placesc1540/search",
        loadChildren:
          () => import('./views/place/place-sc1540/place-scd-search/place-sc1540-search.module').then(m => m.PlaceSc1540SearchModule),
      },
      {
        path: "securitycouncil/SubsidiaryOrgans/placesc1591",
        redirectTo: "placesc1591",
        pathMatch: "full",
      },
      {
        path: "placesc1591/create/new",
        loadChildren:
          () => import('./views/place/place-sc1591/place-scd-create/place-sc1591-create.module').then(m => m.PlaceSc1591CreateModule),
      },
      {
        path: "placesc1591/search",
        loadChildren:
          () => import('./views/place/place-sc1591/place-scd-search/place-sc1591-search.module').then(m => m.PlaceSc1591SearchModule),
      },
      {
        path: "securitycouncil/SubsidiaryOrgans/placesc1718",
        redirectTo: "placesc1718",
        pathMatch: "full",
      },
      {
        path: "placesc1718/create/new",
        loadChildren:
          () => import('./views/place/place-sc1718/place-scd-create/place-sc1718-create.module').then(m => m.PlaceSc1718CreateModule),
      },
      {
        path: "placesc1718/search",
        loadChildren:
          () => import('./views/place/place-sc1718/place-scd-search/place-sc1718-search.module').then(m => m.PlaceSc1718SearchModule),
      },
      {
        path: "securitycouncil/SubsidiaryOrgans/placesc1970",
        redirectTo: "placesc1970",
        pathMatch: "full",
      },
      {
        path: "placesc1970/create/new",
        loadChildren:
          () => import('./views/place/place-sc1970/place-scd-create/place-sc1970-create.module').then(m => m.PlaceSc1970CreateModule),
      },
      {
        path: "placesc1970/search",
        loadChildren:
          () => import('./views/place/place-sc1970/place-scd-search/place-sc1970-search.module').then(m => m.PlaceSc1970SearchModule),
      },
      {
        path: "securitycouncil/SubsidiaryOrgans/placesc2140",
        redirectTo: "placesc2140",
        pathMatch: "full",
      },
      {
        path: "placesc2140/create/new",
        loadChildren:
          () => import('./views/place/place-sc2140/place-scd-create/place-sc2140-create.module').then(m => m.PlaceSc2140CreateModule),
      },
      {
        path: "placesc2140/search",
        loadChildren:
          () => import('./views/place/place-sc2140/place-scd-search/place-sc2140-search.module').then(m => m.PlaceSc2140SearchModule),
      },
      {
        path: "securitycouncil/SubsidiaryOrgans/placesc1988",
        redirectTo: "placesc1988",
        pathMatch: "full",
      },
      {
        path: "placesc1988/create/new",
        loadChildren:
          () => import('./views/place/place-sc1988/place-scd-create/place-sc1988-create.module').then(m => m.PlaceSc1988CreateModule),
      },
      {
        path: "placesc1988/search",
        loadChildren:
          () => import('./views/place/place-sc1988/place-scd-search/place-sc1988-search.module').then(m => m.PlaceSc1988SearchModule),
      },
      {
        path: "securitycouncil/SubsidiaryOrgans/placesc2048",
        redirectTo: "placesc2048",
        pathMatch: "full",
      },
      {
        path: "placesc2048/create/new",
        loadChildren:
          () => import('./views/place/place-sc2048/place-scd-create/place-sc2048-create.module').then(m => m.PlaceSc2048CreateModule),
      },
      {
        path: "placesc2048/search",
        loadChildren:
          () => import('./views/place/place-sc2048/place-scd-search/place-sc2048-search.module').then(m => m.PlaceSc2048SearchModule),
      },
      {
        path: "securitycouncil/SubsidiaryOrgans/placesc2745",
        redirectTo: "placesc2745",
        pathMatch: "full",
      },
      {
        path: "placesc2745/create/new",
        loadChildren:
          () => import('./views/place/place-sc2127/place-scd-create/place-sc2127-create.module').then(m => m.PlaceSc2127CreateModule),
      },
      {
        path: "placesc2745/search",
        loadChildren:
          () => import('./views/place/place-sc2127/place-scd-search/place-sc2127-search.module').then(m => m.PlaceSc2127SearchModule),
      },
      {
        path: "securitycouncil/SubsidiaryOrgans/placesc2206",
        redirectTo: "placesc2206",
        pathMatch: "full",
      },
      {
        path: "placesc2206/create/new",
        loadChildren:
          () => import('./views/place/place-sc2206/place-scd-create/place-sc2206-create.module').then(m => m.PlaceSc2206CreateModule),
      },
      {
        path: "placesc2206/search",
        loadChildren:
          () => import('./views/place/place-sc2206/place-scd-search/place-sc2206-search.module').then(m => m.PlaceSc2206SearchModule),
      },
      {
        path: "securitycouncil/Resolution2231(2015)",
        redirectTo: "placesc2231",
        pathMatch: "full",
      },
      {
        path: "placesc2231/create/new",
        loadChildren:
          () => import('./views/place/place-sc2231/place-scd-create/place-sc2231-create.module').then(m => m.PlaceSc2231CreateModule),
      },
      {
        path: "placesc2231/search",
        loadChildren:
          () => import('./views/place/place-sc2231/place-scd-search/place-sc2231-search.module').then(m => m.PlaceSc2231SearchModule),
      },
      {
        path: "securitycouncil/SubsidiaryOrgans/placesc2374",
        redirectTo: "placesc2374",
        pathMatch: "full",
      },
      {
        path: "placesc2374/create/new",
        loadChildren:
          () => import('./views/place/place-sc2374/place-scd-create/place-sc2374-create.module').then(m => m.PlaceSc2374CreateModule),
      },
      {
        path: "placesc2374/search",
        loadChildren:
          () => import('./views/place/place-sc2374/place-scd-search/place-sc2374-search.module').then(m => m.PlaceSc2374SearchModule),
      },
      {
        path: "securitycouncil/SubsidiaryOrgans/placesc2653",
        redirectTo: "placesc2653",
        pathMatch: "full",
      },
      {
        path: "placesc2653/create/new",
        loadChildren:
          () => import('./views/place/place-sc2653/place-scd-create/place-sc2653-create.module').then(m => m.PlaceSc2653CreateModule),
      },
      {
        path: "placesc2653/search",
        loadChildren:
          () => import('./views/place/place-sc2653/place-scd-search/place-sc2653-search.module').then(m => m.PlaceSc2653SearchModule),
      },
      {
        path: "securitycouncil/SubsidiaryOrgans/placewgcaac",
        redirectTo: "placewgcaac",
        pathMatch: "full",
      },
      {
        path: "placewgcaac/create/new",
        loadChildren:
          () => import('./views/place/place-wgcaac/place-scd-create/place-wgcaac-create.module').then(m => m.PlaceWgcaacCreateModule),
      },
      {
        path: "placewgcaac/search",
        loadChildren:
          () => import('./views/place/place-wgcaac/place-scd-search/place-wgcaac-search.module').then(m => m.PlaceWgcaacSearchModule),
      },
      {
        path: "generalAssembly/firstCommittee/place",
        loadChildren:
          () => import('./views/place/placeC1/place-details/place-details.module').then(m => m.PlaceDetailsModule),
      },
      {
        path: "generalAssembly/firstCommittee/place/create/new",
        loadChildren:
          () => import('./views/place/placeC1/place-create/place-create.module').then(m => m.PlaceCreateModule),
      },
      {
        path: "generalAssembly/secondCommittee/place/create/new",
        loadChildren:
          () => import('./views/place/place-c2/place-create/place-c2-create.module').then(m => m.PlaceC2CreateModule),
      },
      {
        path: "generalAssembly/thirdCommittee/place/create/new",
        loadChildren:
          () => import('./views/place/place-c3/place-create/place-c3-create.module').then(m => m.PlaceC3CreateModule),
      },
      {
        path: "generalAssembly/plenary/placegaplenary/create/new",
        loadChildren:
          () => import('./views/place/place-gaplenary/place-create/place-gaplenary-create.module').then(m => m.PlaceGAplenaryCreateModule),
      },
      {
        path: "Conference/pdc/placegaplenary/create/new",
        loadChildren:
          () => import('./views/place/place-gaplenary/place-create/place-gaplenary-create.module').then(m => m.PlaceGAplenaryCreateModule),
      },
      {
        path: "Conference/2ndHLUNConferenceonSouthSouthCooperation/ConferencePlace/create/new",
        loadChildren:
          () => import('./views/place/place-confbapa/place-create/place-confbapa-create.module').then(m => m.PlaceConfBapaCreateModule),
      },
      {
        path: "Conference/ldc/placeconfldc/create/new",
        loadChildren:
          () => import('./views/place/place-confldc/place-create/place-confldc-create.module').then(m => m.PlaceConfLdcCreateModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/OEWGonInformationandTelecommunications/OEWGonInformationandTelecommunicationsPlace/create/new",
        loadChildren:
          () => import('./views/place/place-oewgcs/place-create/place-oewgcs-create.module').then(m => m.PlaceOewgcsCreateModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/OEWGonSecurity/OEWGonSecurityPlace/create/new",
        loadChildren:
          () => import('./views/place/place-oewgs/place-create/place-oewgs-create.module').then(m => m.PlaceOewgsCreateModule),
      },
      {
        path:
          "generalAssembly/subsidiaryBodies/coc/COCPlace/create/new",
        loadChildren:
          () => import('./views/place/place-coc/place-create/place-coc-create.module').then(m => m.PlaceCocCreateModule),
      },
      {
        path:
          "generalAssembly/subsidiaryBodies/specialCommitteeOnPeacekeepingOperations/place/create/new",
        loadChildren:
          () => import('./views/place/place-c34/place-create/place-c34-create.module').then(m => m.PlaceC34CreateModule),
      },
      {
        path:
          "generalAssembly/subsidiaryBodies/SpecialCommitteeonDecolonization/C24Place/create/new",
        loadChildren:
          () => import('./views/place/place-c24/place-create/place-c24-create.module').then(m => m.PlaceC24CreateModule),
      },
      {
        path:
          "generalAssembly/subsidiaryBodies/disarmamentCommission/UNDCPlace/create/new",
        loadChildren:
          () => import('./views/place/place-dc/place-create/place-dc-create.module').then(m => m.PlaceDcCreateModule),
      },
      {
        path: "ecosoc/committeeforprogrammeandcoordination/place/create/new",
        loadChildren:
          () => import('./views/place/place-cpc/place-create/place-cpc-create.module').then(m => m.PlaceCpcCreateModule),
      },
      {
        path: "generalAssembly/fourthCommittee/place/create/new",
        loadChildren:
          () => import('./views/place/place-c4/place-create/place-c4-create.module').then(m => m.PlaceC4CreateModule),
      },
      {
        path: "generalAssembly/fifthCommittee/place/create/new",
        loadChildren:
          () => import('./views/place/place-c5/place-create/place-c5-create.module').then(m => m.PlaceC5CreateModule),
      },
      {
        path: "generalAssembly/fifthCommittee/c5documents/place/create/new",
        loadChildren:
          () => import('./views/place/place-c5/place-create/place-c5-create.module').then(m => m.PlaceC5CreateModule),
      },
      {
        path: "generalAssembly/fifthCommittee/place/subscribe",
        loadChildren:
          () => import('./views/place/place-c5/place-subscriptions/place-c5-subscriptions.module').then(m => m.PlaceC5SubscriptionsModule),
      },
      {
        path: "generalAssembly/fifthCommittee/place/subscribers",
        loadChildren:
          () => import('./views/place/place-c5/place-subscribers/place-c5-subscribers.module').then(m => m.PlaceC5SubscribersModule),
      },
      {
        path: "generalAssembly/fifthCommittee/c5documents/place/subscribe",
        loadChildren:
          () => import('./views/place/place-c5/place-subscriptions/place-c5-subscriptions.module').then(m => m.PlaceC5SubscriptionsModule),
      },
      {
        path: "generalAssembly/fifthCommittee/c5documents/place/subscribers",
        loadChildren:
          () => import('./views/place/place-c5/place-subscribers/place-c5-subscribers.module').then(m => m.PlaceC5SubscribersModule),
      },
      {
        path: "generalAssembly/fifthCommittee/elections/candiweb/create/new",
        loadChildren:
          () => import('./views/place/place-candi/place-create/place-candi-create.module').then(m => m.PlaceCandiCreateModule),
      },
      {
        path: "generalAssembly/fifthCommittee/candiweb/create/new",
        loadChildren:
          () => import('./views/place/place-candi/place-create/place-candi-create.module').then(m => m.PlaceCandiCreateModule),
      },
      {
        path: "ecosoc/en/place/create/new",
        loadChildren:
          () => import('./views/place/place-ECOSOC/place-create/place-ECOSOC-create.module').then(m => m.PlaceEcosocCreateModule),
      },
      {
        path: "ecosoc/permanentForumOnIndigenousIssues/place/create/new",
        loadChildren:
          () => import('./views/place/place-pfii/place-create/place-pfii-create.module').then(m => m.PlacePfiiCreateModule),
      },

      {
        path: "generalAssembly/firstCommittee/place/search",
        loadChildren:
          () => import('./views/place/placeC1/place-search/place-search.module').then(m => m.PlaceSearchModule),
      },
      {
        path: "generalAssembly/secondCommittee/place/search",
        loadChildren:
          () => import('./views/place/place-c2/place-search/place-c2-search.module').then(m => m.PlaceC2SearchModule),
      },
      {
        path: "generalAssembly/thirdCommittee/place/search",
        loadChildren:
          () => import('./views/place/place-c3/place-search/place-c3-search.module').then(m => m.PlaceC3SearchModule),
      },
      {
        path: "generalAssembly/plenary/placegaplenary/search",
        loadChildren:
          () => import('./views/place/place-gaplenary/place-search/place-gaplenary-search.module').then(m => m.PlaceGAplenarySearchModule),
      },
      {
        path: "Conference/pdc/placegaplenary/search",
        loadChildren:
          () => import('./views/place/place-gaplenary/place-search/place-gaplenary-search.module').then(m => m.PlaceGAplenarySearchModule),
      },
      {
        path: "Conference/2ndHLUNConferenceonSouthSouthCooperation/ConferencePlace/search",
        loadChildren:
          () => import('./views/place/place-confbapa/place-search/place-confbapa-search.module').then(m => m.PlaceConfBapaSearchModule),
      },
      {
        path: "Conference/ldc/placeconfldc/search",
        loadChildren:
          () => import('./views/place/place-confldc/place-search/place-confldc-search.module').then(m => m.PlaceConfLdcSearchModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/OEWGonInformationandTelecommunications/OEWGonInformationandTelecommunicationsPlace/search",
        loadChildren:
          () => import('./views/place/place-oewgcs/place-search/place-oewgcs-search.module').then(m => m.PlaceOewgcsSearchModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/OEWGonSecurity/OEWGonSecurityPlace/search",
        loadChildren:
          () => import('./views/place/place-oewgs/place-search/place-oewgs-search.module').then(m => m.PlaceOewgsSearchModule),
      },
      {
        path:
          "generalAssembly/subsidiaryBodies/coc/COCPlace/search",
        loadChildren:
          () => import('./views/place/place-coc/place-search/place-coc-search.module').then(m => m.PlaceCocSearchModule),
      },
      {
        path:
          "generalAssembly/subsidiaryBodies/specialCommitteeOnPeacekeepingOperations/place/search",
        loadChildren:
          () => import('./views/place/place-c34/place-search/place-c34-search.module').then(m => m.PlaceC34SearchModule),
      },
      {
        path:
          "generalAssembly/subsidiaryBodies/SpecialCommitteeonDecolonization/C24Place/search",
        loadChildren:
          () => import('./views/place/place-c24/place-search/place-c24-search.module').then(m => m.PlaceC24SearchModule),
      },
      {
        path:
          "generalAssembly/subsidiaryBodies/disarmamentCommission/UNDCPlace/search",
        loadChildren:
          () => import('./views/place/place-dc/place-search/place-dc-search.module').then(m => m.PlaceDcSearchModule),
      },
      {
        path: "ecosoc/committeeforprogrammeandcoordination/place/search",
        loadChildren:
          () => import('./views/place/place-cpc/place-search/place-cpc-search.module').then(m => m.PlaceCpcSearchModule),
      },
      {
        path: "generalAssembly/fourthCommittee/place/search",
        loadChildren:
          () => import('./views/place/place-c4/place-search/place-c4-search.module').then(m => m.PlaceC4SearchModule),
      },
      {
        path: "generalAssembly/fifthCommittee/place/search",
        loadChildren:
          () => import('./views/place/place-c5/place-search/place-c5-search.module').then(m => m.PlaceC5SearchModule),
      },
      {
        path: "generalAssembly/fifthCommittee/c5documents/place/search",
        loadChildren:
          () => import('./views/place/place-c5/place-search/place-c5-search.module').then(m => m.PlaceC5SearchModule),
      },
      {
        path: "generalAssembly/fifthCommittee/elections/candiweb/search",
        loadChildren:
          () => import('./views/place/place-candi/place-search/place-candi-search.module').then(m => m.PlaceCandiSearchModule),
      },
      {
        path: "generalAssembly/fifthCommittee/candiweb/search",
        loadChildren:
          () => import('./views/place/place-candi/place-search/place-candi-search.module').then(m => m.PlaceCandiSearchModule),
      },
      {
        path: "ecosoc/en/place/search",
        loadChildren:
          () => import('./views/place/place-ECOSOC/place-search/place-ECOSOC-search.module').then(m => m.PlaceEcosocSearchModule),
      },
      {
        path: "ecosoc/permanentForumOnIndigenousIssues/place/search",
        loadChildren:
          () => import('./views/place/place-pfii/place-search/place-pfii-search.module').then(m => m.PlacePfiiSearchModule),
      },

      {
        path: "generalAssembly/secondCommittee/place",
        loadChildren:
          () => import('./views/place/place-c2/place-details/place-c2-details.module').then(m => m.PlaceC2DetailsModule),
      },
      {
        path: "generalAssembly/fifthCommittee/place",
        loadChildren:
          () => import('./views/place/place-c5/place-details/place-c5-details.module').then(m => m.PlaceC5DetailsModule),
      },
      {
        path: "generalAssembly/fifthCommittee/c5documents/place",
        loadChildren:
          () => import('./views/place/place-c5/place-details/place-c5-details.module').then(m => m.PlaceC5DetailsModule),
      },
      {
        path: "generalAssembly/thirdCommittee/place",
        loadChildren:
          () => import('./views/place/place-c3/place-details/place-c3-details.module').then(m => m.PlaceC3DetailsModule),
      },
      {
        path: "generalAssembly/plenary/placegaplenary",
        loadChildren:
          () => import('./views/place/place-gaplenary/place-details/place-gaplenary-details.module').then(m => m.PlaceGAplenaryDetailsModule),
      },
      {
        path: "Conference/pdc/placegaplenary",
        loadChildren:
          () => import('./views/place/place-gaplenary/place-details/place-gaplenary-details.module').then(m => m.PlaceGAplenaryDetailsModule),
      },
      {
        path: "Conference/2ndHLUNConferenceonSouthSouthCooperation/ConferencePlace",
        loadChildren:
          () => import('./views/place/place-confbapa/place-details/place-confbapa-details.module').then(m => m.PlaceConfBapaDetailsModule),
      },
      {
        path: "Conference/ldc/placeconfldc",
        loadChildren:
          () => import('./views/place/place-confldc/place-details/place-confldc-details.module').then(m => m.PlaceConfLdcDetailsModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/OEWGonInformationandTelecommunications/OEWGonInformationandTelecommunicationsPlace",
        loadChildren:
          () => import('./views/place/place-oewgcs/place-details/place-oewgcs-details.module').then(m => m.PlaceOewgcsDetailsModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/OEWGonSecurity/OEWGonSecurityPlace",
        loadChildren:
          () => import('./views/place/place-oewgs/place-details/place-oewgs-details.module').then(m => m.PlaceOewgsDetailsModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/coc/COCPlace",
        loadChildren:
          () => import('./views/place/place-coc/place-details/place-coc-details.module').then(m => m.PlaceCocDetailsModule),
      },
      {
        path:
          "generalAssembly/subsidiaryBodies/specialCommitteeOnPeacekeepingOperations/place",
        loadChildren:
          () => import('./views/place/place-c34/place-details/place-c34-details.module').then(m => m.PlaceC34DetailsModule),
      },
      {
        path:
          "generalAssembly/subsidiaryBodies/SpecialCommitteeonDecolonization/C24Place",
        loadChildren:
          () => import('./views/place/place-c24/place-details/place-c24-details.module').then(m => m.PlaceC24DetailsModule),
      },
      {
        path:
          "generalAssembly/subsidiaryBodies/disarmamentCommission/UNDCPlace",
        loadChildren:
          () => import('./views/place/place-dc/place-details/place-dc-details.module').then(m => m.PlaceDcDetailsModule),
      },
      {
        path: "ecosoc/committeeforprogrammeandcoordination/place",
        loadChildren:
          () => import('./views/place/place-cpc/place-details/place-cpc-details.module').then(m => m.PlaceCpcDetailsModule),
      },
      {
        path: "generalAssembly/fourthCommittee/place",
        loadChildren:
          () => import('./views/place/place-c4/place-details/place-c4-details.module').then(m => m.PlaceC4DetailsModule),
      },
      {
        path: "generalAssembly/fifthCommittee/elections/candiweb",
        loadChildren:
          () => import('./views/place/place-candi/place-details/place-candi-details.module').then(m => m.PlaceCandiDetailsModule),
      },
      {
        path: "generalAssembly/fifthCommittee/candiweb",
        loadChildren:
          () => import('./views/place/place-candi/place-details/place-candi-details.module').then(m => m.PlaceCandiDetailsModule),
      },
      {
        path: "generalAssembly/plenary/candiweb",
        loadChildren:
          () => import('./views/place/place-candi/place-details/place-candi-details.module').then(m => m.PlaceCandiDetailsModule),
      },
      {
        path: "generalAssembly/plenary/candiweb/search",
        loadChildren:
          () => import('./views/place/place-candi/place-search/place-candi-search.module').then(m => m.PlaceCandiSearchModule),
      },
      {
        path: "generalAssembly/plenary/candiweb/create/new",
        loadChildren:
          () => import('./views/place/place-candi/place-create/place-candi-create.module').then(m => m.PlaceCandiCreateModule),
      },
      {
        path: "ecosoc/en/place",
        loadChildren:
          () => import('./views/place/place-ECOSOC/place-details/place-ECOSOC-details.module').then(m => m.PlaceEcosocDetailsModule),
      },
      {
        path: "ecosoc/permanentForumOnIndigenousIssues/place",
        loadChildren:
          () => import('./views/place/place-pfii/place-details/place-pfii-details.module').then(m => m.PlacePfiiDetailsModule),
      },
      {
        path: "ecosoc/en/elections/candiweb",
        loadChildren:
          () => import('./views/place/place-candi/place-details/place-candi-details.module').then(m => m.PlaceCandiDetailsModule),
      },
      {
        path: "ecosoc/en/elections/candiweb/search",
        loadChildren:
          () => import('./views/place/place-candi/place-search/place-candi-search.module').then(m => m.PlaceCandiSearchModule),
      },
      {
        path: "ecosoc/en/elections/candiweb/create/new",
        loadChildren:
          () => import('./views/place/place-candi/place-create/place-candi-create.module').then(m => m.PlaceCandiCreateModule),
      },
      {
        path: "whatsNew/spokespersonMorningHeadlines",
        loadChildren:
          () => import('./views/spokesman-morning-headlines/spokesman-morning-headlines.module').then(m => m.SpokesmanMorningHeadlinesModule),
      },
      {
        path: "resources/meetings/meetingsAtUNHQ",
        loadChildren:
          () => import('./views/meetings-at-unhq/meetings-at-unhq.module').then(m => m.MeetingsAtUnhqModule),
      },
      {
        path: "resources/documents/documentsIssuedAtUNHQToday",
        loadChildren:
          () => import('./views/documents-issued-today/documents-issued-today.module').then(m => m.DocumentsIssuedTodayModule),
      },
      {
        path: "resources/eCorrespondenceEmail",
        loadChildren:
          () => import('./views/e-correspondence/e-correspondence.module').then(m => m.ECorrespondenceModule),
      },
      {
        path: "generalAssembly/plenary/resolutionsDecisions/edecisions",
        loadChildren:
          () => import('./views/e-decisions/e-decisions-list/e-decisions-list.module').then(m => m.EDecisionsListModule),
      },
      {
        path: "generalAssembly/plenary/resolutionsDecisions/edecisions/:id",
        loadChildren:
          () => import('./views/e-decisions/e-decisions-details/e-decisions-details.module').then(m => m.EDecisionsDetailsModule),
      },
      {
        path:
          "generalAssembly/plenary/resolutionsDecisions/edecisions/create/new",
        loadChildren:
          () => import('./views/e-decisions/e-decisions-create/e-decisions-create.module').then(m => m.EDecisionsCreateModule),
      },
      {
        path: "generalAssembly/plenary/resolutionsDecisions/eRecordedVotes",
        loadChildren:
          () => import('./views/voting-recording/voting-recording-gap/voting-recording.module').then(m => m.VotingRecordingModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/HumanRightsCouncil/eRecordedVotesHRC",
        loadChildren:
          () => import('./views/voting-recording/voting-recording-hrc/voting-recording.module').then(m => m.VotingRecordingModule),
      },
      {
        path: "geneva/HumanRightsCouncil/eRecordedVotesHRC",
        loadChildren:
          () => import('./views/voting-recording/voting-recording-hrc/voting-recording.module').then(m => m.VotingRecordingModule),
      },
      {
        path: "generalAssembly/plenary/resolutionsDecisions/eRecordedVotesTest",
        loadChildren:
          () => import('./views/voting-recording/voting-recording-test/voting-recording.module').then(m => m.VotingRecordingModule),
      },
      {
        path: "generalAssembly/plenary/resolutionsDecisions/votingIntentions",
        loadChildren:
          () => import('./views/voting-intentions/voting-intentions-gap/voting-intentions.module').then(m => m.VotingIntentionsModule),
      },
      {
        path: "generalAssembly/firstCommittee/draftProposals/votingIntentions",
        loadChildren:
          () => import('./views/voting-intentions/voting-intentions-c1/voting-intentions.module').then(m => m.VotingIntentionsModule),
      },
      {
        path: "generalAssembly/secondCommittee/draftProposals/votingIntentions",
        loadChildren:
          () => import('./views/voting-intentions/voting-intentions-c2/voting-intentions.module').then(m => m.VotingIntentionsModule),
      },
      {
        path: "generalAssembly/thirdCommittee/draftProposals/votingIntentions",
        loadChildren:
          () => import('./views/voting-intentions/voting-intentions-c3/voting-intentions.module').then(m => m.VotingIntentionsModule),
      },
      {
        path: "generalAssembly/fourthCommittee/votingIntentions",
        loadChildren:
          () => import('./views/voting-intentions/voting-intentions-c4/voting-intentions.module').then(m => m.VotingIntentionsModule),
      },
      {
        path: "ecosoc/en/resolutionsDecisions/votingIntentions",
        loadChildren:
          () => import('./views/voting-intentions/voting-intentions-ecosoc/voting-intentions.module').then(m => m.VotingIntentionsModule),
      },
      {
        path: "ecosoc/commissionForSocialDevelopment/draftProposals/votingIntentions",
        loadChildren:
          () => import('./views/voting-intentions/voting-intentions-csocd/voting-intentions.module').then(m => m.VotingIntentionsModule),
      },
      {
        path: "ecosoc/commissionOnPopulationAndDevelopment/votingIntentions",
        loadChildren:
          () => import('./views/voting-intentions/voting-intentions-cpd/voting-intentions.module').then(m => m.VotingIntentionsModule),
      },
      {
        path: "ecosoc/commissionOnTheStatusOfWomen/votingIntentions",
        loadChildren:
          () => import('./views/voting-intentions/voting-intentions-csw/voting-intentions.module').then(m => m.VotingIntentionsModule),
      },
      {
        path: "ecosoc/unitedNationsForumOnForests/votingIntentions",
        loadChildren:
          () => import('./views/voting-intentions/voting-intentions-ff/voting-intentions.module').then(m => m.VotingIntentionsModule),
      },
      {
        path: "ecosoc/en/resolutionsDecisions/edecisions",
        loadChildren:
          () => import('./views/e-decisions/e-decisions-list/e-decisions-list.module').then(m => m.EDecisionsListModule),
      },
      {
        path: "ecosoc/en/resolutionsDecisions/edecisions/:id",
        loadChildren:
          () => import('./views/e-decisions/e-decisions-details/e-decisions-details.module').then(m => m.EDecisionsDetailsModule),
      },
      {
        path: "ecosoc/en/resolutionsDecisions/edecisions/create/new",
        loadChildren:
          () => import('./views/e-decisions/e-decisions-create/e-decisions-create.module').then(m => m.EDecisionsCreateModule),
      },
      {
        path: "ecosoc/en/resolutionsDecisions/sponsorshipECOSOC",
        loadChildren:
          () => import('./views/sponsorship/sponsorshipECOSOC/sponsorshipECOSOC.module').then(m => m.SponsorshipECOSOCModule),
      },
      {
        path: "ecosoc/commissionForSocialDevelopment/draftProposals/sponsorshipCSOCD",
        loadChildren:
          () => import('./views/sponsorship/sponsorshipCSOCD/sponsorshipCSOCD.module').then(m => m.SponsorshipCSOCDModule),
      },
      {
        path: "ecosoc/commissionOnTheStatusOfWomen/sponsorshipCSW",
        loadChildren:
          () => import('./views/sponsorship/sponsorshipCSW/sponsorshipCSW.module').then(m => m.SponsorshipCSWModule),
      },
      {
        path: "ecosoc/commissionOnPopulationAndDevelopment/sponsorshipCPD",
        loadChildren:
          () => import('./views/sponsorship/sponsorshipCPD/sponsorshipCPD.module').then(m => m.SponsorshipCPDModule),
      },
      {
        path: "ecosoc/unitedNationsForumOnForests/sponsorshipFF",
        loadChildren:
          () => import('./views/sponsorship/sponsorshipFF/sponsorshipFF.module').then(m => m.SponsorshipFFModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/HumanRightsCouncil/sponsorshipHRC",
        loadChildren:
          () => import('./views/sponsorship/sponsorshipHRC/sponsorshipHRC.module').then(m => m.SponsorshipHRCModule),
      },
      {
        path: "geneva/HumanRightsCouncil/sponsorshipHRC",
        loadChildren:
          () => import('./views/sponsorship/sponsorshipHRC/sponsorshipHRC.module').then(m => m.SponsorshipHRCModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/HumanRightsCouncil/sponsorshipHRC2",
        loadChildren:
          () => import('./views/sponsorship/sponsorshipHRC2/sponsorshipHRC2.module').then(m => m.SponsorshipHRC2Module),
      },
      {
        path: "generalAssembly/subsidiaryBodies/SpecialCommitteeonDecolonization/sponsorshipC24",
        loadChildren:
          () => import('./views/sponsorship/sponsorshipC24/sponsorshipC24.module').then(m => m.SponsorshipC24Module),
      },
      {
        path: "generalAssembly/subsidiaryBodies/OEWGonAgeing/sponsorshipOEWGA",
        loadChildren:
          () => import('./views/sponsorship/sponsorshipOEWGA/sponsorshipOEWGA.module').then(m => m.SponsorshipOEWGAModule),
      },
      {
        path: "securitycouncil/sponsorshipSC",
        loadChildren:
          () => import('./views/sponsorship/sponsorshipSC/sponsorshipSC.module').then(m => m.SponsorshipSCModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/ahcc/sponsorshipAHCC",
        loadChildren:
          () => import('./views/sponsorship/sponsorshipAHCC/sponsorshipAHCC.module').then(m => m.SponsorshipAHCCModule),
      },
      {
        path: "vienna/ahcc/sponsorshipAHCC",
        loadChildren:
          () => import('./views/sponsorship/sponsorshipAHCC/sponsorshipAHCC.module').then(m => m.SponsorshipAHCCModule),
      },
      {
        path: "ecosoc/cnd/sponsorshipCND",
        loadChildren:
          () => import('./views/sponsorship/sponsorshipCND/sponsorshipCND.module').then(m => m.SponsorshipCNDModule),
      },
      {
        path: "vienna/cnd/sponsorshipCND",
        loadChildren:
          () => import('./views/sponsorship/sponsorshipCND/sponsorshipCND.module').then(m => m.SponsorshipCNDModule),
      },
      {
        path: "ecosoc/ccpcj/sponsorshipCCPCJ",
        loadChildren:
          () => import('./views/sponsorship/sponsorshipCCPCJ/sponsorshipCCPCJ.module').then(m => m.SponsorshipCCPCJModule),
      },
      {
        path: "vienna/ccpcj/sponsorshipCCPCJ",
        loadChildren:
          () => import('./views/sponsorship/sponsorshipCCPCJ/sponsorshipCCPCJ.module').then(m => m.SponsorshipCCPCJModule),
      },
      {
        path: "vienna/untoc/sponsorshipUNTOC",
        loadChildren:
          () => import('./views/sponsorship/sponsorshipUNTOC/sponsorshipUNTOC.module').then(m => m.SponsorshipUNTOCModule),
      },
      {
        path: "ecosoc/unitedNationsForumOnForests/meetingsAtUNFF",
        loadChildren: () => import('./views/meetings-at-unhq-committee/meetings-at-unhq-committee.module').then(m => m.MeetingsAtUnhqCommitteeModule),
      },
      {
        path: "Conference/ldc/ldcmeetings",
        loadChildren: () => import('./views/meetings-at-unhq-committee/meetings-at-unhq-committee.module').then(m => m.MeetingsAtUnhqCommitteeModule),
      },
      {
        path: "Conference/gstc/gstcmeetings",
        loadChildren: () => import('./views/meetings-at-unhq-committee/meetings-at-unhq-committee.module').then(m => m.MeetingsAtUnhqCommitteeModule),
      },
      {
        path: "ecosoc/en/ecosocmeetings",
        loadChildren: () => import('./views/meetings-at-unhq-committee/meetings-at-unhq-committee.module').then(m => m.MeetingsAtUnhqCommitteeModule),
      },
      {
        path: "ecosoc/commissionForSocialDevelopment/CSOCDMeetings",
        loadChildren: () => import('./views/meetings-at-unhq-committee/meetings-at-unhq-committee.module').then(m => m.MeetingsAtUnhqCommitteeModule),
      },
      {
        path: "ecosoc/commissionOnPopulationAndDevelopment/CPDMeetings",
        loadChildren: () => import('./views/meetings-at-unhq-committee/meetings-at-unhq-committee.module').then(m => m.MeetingsAtUnhqCommitteeModule),
      },
      {
        path: "ecosoc/commissionOnTheStatusOfWomen/CSW62MeetingsInUNHQ",
        loadChildren: () => import('./views/meetings-at-unhq-committee/meetings-at-unhq-committee.module').then(m => m.MeetingsAtUnhqCommitteeModule),
      },
      {
        path: "generalAssembly/plenary/speakerlist",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "generalAssembly/HighlevelPoliticalForumonSustainableDevelopment",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "generalAssembly/sotf",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "generalAssembly/hlmar",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "generalAssembly/hlmaetpslr",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "generalAssembly/thirdCommittee/speakerlist",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "securitycouncil",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "generalAssembly/fifthCommittee",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/disarmamentCommission",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "Conference/ConferenceofStatesPartiestoCRPD",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "Conference/gstc",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "Conference/NPT",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "Conference/confmezfnwmd",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "Conference/water",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "Conference/ctbt",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "Conference/tpnw",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "Conference/confcd",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "Conference/confsids/prepcom",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "Conference/confsids/sids",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "Conference/conflldc/prepcomlldc",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "Conference/conflldc/lldc",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "Conference/confsalw/salw",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "Conference/conficffd/prepcomicffd",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },

      {
        path: "ecosoc/commissionForSocialDevelopment",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "ecosoc/commissionOnTheStatusOfWomen",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "nairobi/unep/unea",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "vienna/ahcc",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "geneva/gencd",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "unep/unea",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "ecosoc/commissionOnTheStatusOfWomen/speakersmrt",
        loadChildren: () => import('./views/e-speakers-mrt/e-speakers-mrt.module').then(m => m.ESpeakersMRTModule),
      },
      {
        path: "ecosoc/cnd",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "ecosoc/ccpcj",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "vienna/cnd",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "vienna/ccpcj",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "vienna/untoc",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "generalAssembly/HighlevelPoliticalForumonSustainableDevelopment/speakersmrt",
        loadChildren: () => import('./views/e-speakers-mrt/e-speakers-mrt.module').then(m => m.ESpeakersMRTModule),
      },
      {
        path: "Conference/ldc/speakersrt",
        loadChildren: () => import('./views/e-speakers-roundtables/e-speakers-rt.module').then(m => m.ESpeakersRTModule),
      },
      {
        path: "Conference/stockholm50/speakersld",
        loadChildren: () => import('./views/e-speakers-ld/e-speakers-ld.module').then(m => m.ESpeakersLDModule),
      },
      {
        path: "Conference/ocean/speakersld",
        loadChildren: () => import('./views/e-speakers-ld/e-speakers-ld.module').then(m => m.ESpeakersLDModule),
      },
      {
        path: "generalAssembly/imrf/speakers/speakersld",
        loadChildren: () => import('./views/e-speakers-ld/e-speakers-ld.module').then(m => m.ESpeakersLDModule),
      },
      {
        path: "generalAssembly/ffd/speakersld",
        loadChildren: () => import('./views/e-speakers-ld/e-speakers-ld.module').then(m => m.ESpeakersLDModule),
      },
      {
        path: "generalAssembly/pppr/speakersld",
        loadChildren: () => import('./views/e-speakers-ld/e-speakers-ld.module').then(m => m.ESpeakersLDModule),
      },
      {
        path: "generalAssembly/uhc/speakersld",
        loadChildren: () => import('./views/e-speakers-ld/e-speakers-ld.module').then(m => m.ESpeakersLDModule),
      },
      {
        path: "generalAssembly/hlmtb/speakersld",
        loadChildren: () => import('./views/e-speakers-ld/e-speakers-ld.module').then(m => m.ESpeakersLDModule),
      },
      {
        path: "generalAssembly/hlmar/speakersld",
        loadChildren: () => import('./views/e-speakers-ld/e-speakers-ld.module').then(m => m.ESpeakersLDModule),
      },
      {
        path: "generalAssembly/hlmaetpslr/speakersld",
        loadChildren: () => import('./views/e-speakers-ld/e-speakers-ld.module').then(m => m.ESpeakersLDModule),
      },
      {
        path: "generalAssembly/sotf/speakersld",
        loadChildren: () => import('./views/e-speakers-ld/e-speakers-ld.module').then(m => m.ESpeakersLDModule),
      },
      {
        path: "generalAssembly/plenary/speakerlist/speakersld",
        loadChildren: () => import('./views/e-speakers-ld/e-speakers-ld.module').then(m => m.ESpeakersLDModule),
      },
      {
        path: "Conference/water/speakersld",
        loadChildren: () => import('./views/e-speakers-ld/e-speakers-ld.module').then(m => m.ESpeakersLDModule),
      },
      {
        path: "Conference/confsids/sids/speakersld",
        loadChildren: () => import('./views/e-speakers-ld/e-speakers-ld.module').then(m => m.ESpeakersLDModule),
      },
      {
        path: "Conference/conflldc/lldc/speakersld",
        loadChildren: () => import('./views/e-speakers-ld/e-speakers-ld.module').then(m => m.ESpeakersLDModule),
      },
      {
        path: "ecosoc/en/speakersld",
        loadChildren: () => import('./views/e-speakers-ld/e-speakers-ld.module').then(m => m.ESpeakersLDModule),
      },
      {
        path: "ecosoc/en",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "ecosoc/commissionOnPopulationAndDevelopment",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "ecosoc/permanentForumOnIndigenousIssues",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/SpecialCommitteeonDecolonization",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/UNCharterCommittee",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/specialCommitteeOnPeacekeepingOperations",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/OEWGonSecurity",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/BiennialMeetingonSmallArms",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/ahict",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "Conference/ldc",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "Conference/ocean",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "Conference/stockholm50",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "generalAssembly/thirdCommittee/schedule/meetingsAtUNHQC3",
        loadChildren: () => import('./views/meetings-at-unhq-committee/meetings-at-unhq-committee.module').then(m => m.MeetingsAtUnhqCommitteeModule),
      },
      {
        path: "generalAssembly/thirdCommittee/contactList",
        loadChildren: () => import('./views/list-of-delegates/list-of-delegates.module').then(m => m.ListOfDelegatesModule),
      },
      {
        path: "generalAssembly/secondCommittee/contactList",
        loadChildren: () => import('./views/list-of-delegates/list-of-delegates.module').then(m => m.ListOfDelegatesModule),
      },
      {
        path: "generalAssembly/sixthCommittee/contactList",
        loadChildren: () => import('./views/list-of-delegates/list-of-delegates.module').then(m => m.ListOfDelegatesModule),
      },
      {
        path: "ecosoc/committeeforprogrammeandcoordination/contactList",
        loadChildren: () => import('./views/list-of-delegates/list-of-delegates.module').then(m => m.ListOfDelegatesModule),
      },
      {
        path: "generalAssembly/sixthCommittee/c6meetings",
        loadChildren: () => import('./views/meetings-at-unhq-committee/meetings-at-unhq-committee.module').then(m => m.MeetingsAtUnhqCommitteeModule),
      },
      {
        path: "generalAssembly/secondCommittee/c2schedule/meetingsAtUNHQC2",
        loadChildren: () => import('./views/meetings-at-unhq-committee/meetings-at-unhq-committee.module').then(m => m.MeetingsAtUnhqCommitteeModule),
      },
      {
        path: "generalAssembly/firstCommittee/speakerlist",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "generalAssembly/imrf/speakers",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "generalAssembly/secondCommittee/c2speakerlist",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "generalAssembly/fourthCommittee",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "generalAssembly/sixthCommittee",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "generalAssembly/plenary/ecredentials",
        loadChildren:
          () => import('./views/credentials/credentials.module').then(m => m.ECredentialsModule),
      },
      {
        path: "generalAssembly/HighlevelPoliticalForumonSustainableDevelopment/eListOfParticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          "./views/list-of-participants/list-of-participants.module#ListOfParticipantsModule",
        canActivate: [AuthorizationGuard],
      },
      {
        path: "generalAssembly/imrf/elistofparticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          "./views/list-of-participants/list-of-participants.module#ListOfParticipantsModule",
        canActivate: [AuthorizationGuard],
      },
      {
        path: "Conference/gstc/eListOfParticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          "./views/list-of-participants/list-of-participants.module#ListOfParticipantsModule",
        canActivate: [AuthorizationGuard],
      },
      {
        path: "Conference/NPT/eListOfParticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          "./views/list-of-participants/list-of-participants.module#ListOfParticipantsModule",
        canActivate: [AuthorizationGuard],
      },
      {
        path: "Conference/BBNJIntergovernmentalConference/elistofparticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          "./views/list-of-participants/list-of-participants.module#ListOfParticipantsModule",
        canActivate: [AuthorizationGuard],
      },
      {
        path: "Conference/ldc/eListOfParticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          "./views/list-of-participants/list-of-participants.module#ListOfParticipantsModule",
        canActivate: [AuthorizationGuard],
      },
      {
        path: "Conference/ocean/elistofparticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          "./views/list-of-participants/list-of-participants.module#ListOfParticipantsModule",
        canActivate: [AuthorizationGuard],
      },
      {
        path: "Conference/stockholm50/elistofparticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          "./views/list-of-participants/list-of-participants.module#ListOfParticipantsModule",
        canActivate: [AuthorizationGuard],
      },
      {
        path: "Conference/ldc/elistofparticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          "./views/list-of-participants/list-of-participants.module#ListOfParticipantsModule",
        canActivate: [AuthorizationGuard],
      },
      {
        path: "Conference/confmezfnwmd/elistofparticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          "./views/list-of-participants/list-of-participants.module#ListOfParticipantsModule",
        canActivate: [AuthorizationGuard],
      },
      {
        path: "Conference/water/elistofparticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          "./views/list-of-participants/list-of-participants.module#ListOfParticipantsModule",
        canActivate: [AuthorizationGuard],
      },
      {
        path: "Conference/ctbt/elistofparticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          "./views/list-of-participants/list-of-participants.module#ListOfParticipantsModule",
        canActivate: [AuthorizationGuard],
      },
      {
        path: "Conference/confcd/elistofparticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          "./views/list-of-participants/list-of-participants.module#ListOfParticipantsModule",
        canActivate: [AuthorizationGuard],
      },
      {
        path: "Conference/confsids/prepcom/elistofparticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          "./views/list-of-participants/list-of-participants.module#ListOfParticipantsModule",
        canActivate: [AuthorizationGuard],
      },
      {
        path: "Conference/confsids/sids/elistofparticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          "./views/list-of-participants/list-of-participants.module#ListOfParticipantsModule",
        canActivate: [AuthorizationGuard],
      },
      {
        path: "Conference/conflldc/prepcomlldc/elistofparticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          "./views/list-of-participants/list-of-participants.module#ListOfParticipantsModule",
        canActivate: [AuthorizationGuard],
      },
      {
        path: "Conference/confsalw/prepcomsalw/elistofparticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          "./views/list-of-participants/list-of-participants.module#ListOfParticipantsModule",
        canActivate: [AuthorizationGuard],
      },
      {
        path: "Conference/confsalw/salw/elistofparticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          "./views/list-of-participants/list-of-participants.module#ListOfParticipantsModule",
        canActivate: [AuthorizationGuard],
      },
      {
        path: "Conference/conficffd/prepcomicffd/elistofparticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          "./views/list-of-participants/list-of-participants.module#ListOfParticipantsModule",
        canActivate: [AuthorizationGuard],
      },
      {
        path: "Conference/tpnw/elistofparticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          "./views/list-of-participants/list-of-participants.module#ListOfParticipantsModule",
        canActivate: [AuthorizationGuard],
      },
      {
        path: "geneva/gencd/elistofparticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          "./views/list-of-participants/list-of-participants.module#ListOfParticipantsModule",
        canActivate: [AuthorizationGuard],
      },
      {
        path: "geneva/apmbc/elistofparticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          "./views/list-of-participants/list-of-participants.module#ListOfParticipantsModule",
        canActivate: [AuthorizationGuard],
      },
      {
        path: "geneva/ccm/elistofparticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          "./views/list-of-participants/list-of-participants.module#ListOfParticipantsModule",
        canActivate: [AuthorizationGuard],
      },
      {
        path: "geneva/ccw/elistofparticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          "./views/list-of-participants/list-of-participants.module#ListOfParticipantsModule",
        canActivate: [AuthorizationGuard],
      },
      {
        path: "generalAssembly/firstCommittee/eListOfParticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          () => import('./views/list-of-participants/list-of-participants.module').then(m => m.ListOfParticipantsModule),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "generalAssembly/secondCommittee/eListOfParticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          () => import('./views/list-of-participants/list-of-participants.module').then(m => m.ListOfParticipantsModule),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "generalAssembly/thirdCommittee/eListOfParticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          () => import('./views/list-of-participants/list-of-participants.module').then(m => m.ListOfParticipantsModule),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "generalAssembly/fourthCommittee/eListOfParticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          () => import('./views/list-of-participants/list-of-participants.module').then(m => m.ListOfParticipantsModule),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "generalAssembly/fifthCommittee/eListOfParticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          () => import('./views/list-of-participants/list-of-participants.module').then(m => m.ListOfParticipantsModule),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "generalAssembly/sixthCommittee/eListOfParticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          () => import('./views/list-of-participants/list-of-participants.module').then(m => m.ListOfParticipantsModule),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "generalAssembly/subsidiaryBodies/SpecialCommitteeonDecolonization/eListOfParticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          () => import('./views/list-of-participants/list-of-participants.module').then(m => m.ListOfParticipantsModule),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "generalAssembly/subsidiaryBodies/specialCommitteeOnPeacekeepingOperations/eListOfParticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          () => import('./views/list-of-participants/list-of-participants.module').then(m => m.ListOfParticipantsModule),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "generalAssembly/subsidiaryBodies/OEWGonAgeing",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/OEWGonAgeing/eListOfParticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          () => import('./views/list-of-participants/list-of-participants.module').then(m => m.ListOfParticipantsModule),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "generalAssembly/subsidiaryBodies/OEWGonConvAmm",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/OEWGonConvAmm/eListOfParticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          () => import('./views/list-of-participants/list-of-participants.module').then(m => m.ListOfParticipantsModule),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "generalAssembly/subsidiaryBodies/disarmamentCommission/elistofparticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          () => import('./views/list-of-participants/list-of-participants.module').then(m => m.ListOfParticipantsModule),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "generalAssembly/subsidiaryBodies/BiennialMeetingonSmallArms/eListOfParticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          () => import('./views/list-of-participants/list-of-participants.module').then(m => m.ListOfParticipantsModule),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "generalAssembly/subsidiaryBodies/OEWGonSecurity/eListOfParticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          () => import('./views/list-of-participants/list-of-participants.module').then(m => m.ListOfParticipantsModule),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "generalAssembly/subsidiaryBodies/ahict/eListOfParticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          () => import('./views/list-of-participants/list-of-participants.module').then(m => m.ListOfParticipantsModule),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "generalAssembly/subsidiaryBodies/OEWGonInformationandTelecommunications",
        loadChildren: () => import('./views/e-speakers/e-speakers.module').then(m => m.ESpeakersModule),
      },
      {
        path: "generalAssembly/subsidiaryBodies/OEWGonInformationandTelecommunications/eListOfParticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          () => import('./views/list-of-participants/list-of-participants.module').then(m => m.ListOfParticipantsModule),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "ecosoc/StatisticalCommission/eListOfParticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          () => import('./views/list-of-participants/list-of-participants.module').then(m => m.ListOfParticipantsModule),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "ecosoc/commissionForSocialDevelopment/eListOfParticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          () => import('./views/list-of-participants/list-of-participants.module').then(m => m.ListOfParticipantsModule),
        canActivate: [AuthorizationGuard]
      },
      {
        path: "ecosoc/commissionOnTheStatusOfWomen/eListOfParticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          () => import('./views/list-of-participants/list-of-participants.module').then(m => m.ListOfParticipantsModule),
        canActivate: [AuthorizationGuard]
      },
      {
        path: "ecosoc/en/eListOfParticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          () => import('./views/list-of-participants/list-of-participants.module').then(m => m.ListOfParticipantsModule),
        canActivate: [AuthorizationGuard]
      },
      {
        path: "ecosoc/commissionOnPopulationAndDevelopment/eListOfParticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          () => import('./views/list-of-participants/list-of-participants.module').then(m => m.ListOfParticipantsModule),
        canActivate: [AuthorizationGuard]
      },
      {
        path: "ecosoc/CommitteeonNon-GovernmentalOrganizations/eListOfParticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          () => import('./views/list-of-participants/list-of-participants.module').then(m => m.ListOfParticipantsModule),
        canActivate: [AuthorizationGuard]
      },
      {
        path: "ecosoc/UnitedNationsCommitteeofExpertsonGGIM/eListOfParticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          "./views/list-of-participants/list-of-participants.module#ListOfParticipantsModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "ecosoc/permanentForumOnIndigenousIssues/eListOfParticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          () => import('./views/list-of-participants/list-of-participants.module').then(m => m.ListOfParticipantsModule),
        canActivate: [AuthorizationGuard]
      },
      {
        path: "ecosoc/StatisticalCommission/eListOfParticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          () => import('./views/list-of-participants/list-of-participants.module').then(m => m.ListOfParticipantsModule),
        canActivate: [AuthorizationGuard]
      },
      {
        path: "ecosoc/unitedNationsForumOnForests/eListOfParticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          () => import('./views/list-of-participants/list-of-participants.module').then(m => m.ListOfParticipantsModule),
        canActivate: [AuthorizationGuard]
      },
      {
        path: "ecosoc/UnitedNationsGroupofExpertsonGeographicalNames/eListOfParticipants",
        data: {
          moduleName: "listofparticipants",
        },
        loadChildren:
          () => import('./views/list-of-participants/list-of-participants.module').then(m => m.ListOfParticipantsModule),
        canActivate: [AuthorizationGuard]
      },
      {
        path: "protocol/TempDecalDriverRequests",
        loadChildren:
          () => import('./views/temp-decal-driver-requests/temp-decal-driver-requests.module').then(m => m.TempDecalDriverRequestsModule),
      },
      {
        path: "Votes",
        loadChildren: () => import('./views/votes/votes.module').then(m => m.VotesModule),
      },
      {
        path: "administration/eVotes",
        loadChildren: () => import('./views/votes/votes.module').then(m => m.VotesModule),
      },
      {
        path: "generalAssembly/plenary/resolutionsDecisions/sponsorshipP",
        loadChildren:
          () => import('./views/sponsorship/sponsorshipP/sponsorshipP.module').then(m => m.SponsorshipPModule),
      },
      {
        path: "generalAssembly/plenary/resolutionsDecisions/sponsorshipP2",
        loadChildren:
          () => import('./views/sponsorship/sponsorshipP2/sponsorshipP2.module').then(m => m.SponsorshipP2Module),
      },
      {
        path: "generalAssembly/plenary/resolutionsDecisions/sponsorshipP3",
        loadChildren:
          () => import('./views/sponsorship/sponsorshipP3/sponsorshipP3.module').then(m => m.SponsorshipP3Module),
      },
      {
        path: "generalAssembly/firstCommittee/draftProposals/sponsorshipC1",
        loadChildren:
          () => import('./views/sponsorship/sponsorshipC1/sponsorshipC1.module').then(m => m.SponsorshipC1Module),
      },
      {
        path: "generalAssembly/secondCommittee/draftProposals/sponsorshipC2",
        loadChildren:
          () => import('./views/sponsorship/sponsorshipC2/sponsorshipC2.module').then(m => m.SponsorshipC2Module),
      },
      {
        path: "generalAssembly/thirdCommittee/draftProposals/sponsorshipC3",
        loadChildren:
          () => import('./views/sponsorship/sponsorshipC3/sponsorshipC3.module').then(m => m.SponsorshipC3Module),
      },
      {
        path: "generalAssembly/fourthCommittee/sponsorshipC4",
        loadChildren:
          () => import('./views/sponsorship/sponsorshipC4/sponsorshipC4.module').then(m => m.SponsorshipC4Module),
      },
      {
        path: "generalAssembly/sixthCommittee/sponsorshipC6",
        loadChildren:
          () => import('./views/sponsorship/sponsorshipC6/sponsorshipC6.module').then(m => m.SponsorshipC6Module),
      },
      {
        path: "generalAssembly/plenary/speakerlist/speakersgd",
        loadChildren:
          () => import('./views/e-speakers-gd/e-speakers-gd.module').then(m => m.ESpeakersGDModule),
      },
      {
        path: "ballot",
        loadChildren: () => import('./views/ballot/ballot.module').then(m => m.BallotModule),
      },
      {
        path: "generalAssembly/plenary/agenda/gaagendastatus",
        loadChildren: () => import('./views/agenda-ga-status/agenda-ga-status.module').then(m => m.AgendaGAStatusModule),
      },
      {
        path: "generalAssembly/plenary/agenda/annotatedagenda",
        loadChildren: () => import('./views/agenda-ga-annotated/agenda-ga-annotated.module').then(m => m.AgendaGAAnnotatedModule),
      },
      {
        path: "listofdelegates",
        loadChildren: () => import('./views/list-of-delegates/list-of-delegates.module').then(m => m.ListOfDelegatesModule),
      },
      {
        path: "administration/userslist",
        loadChildren: () => import('./views/list-of-users/list-of-users.module').then(m => m.ListOfUsersModule),
      },
      {
        path: "Conference/ConferenceofStatesPartiestoCRPD/ecredentials",
        loadChildren:
          () => import('./views/credentials/credentials.module').then(m => m.ECredentialsModule),
      },
      {
        path: "Conference/ConferenceofStatesPartiestoCRPD/ConferencePlace",
        loadChildren:
          () => import('./views/place/place-confcrpd/place-details/place-confcrpd-details.module').then(m => m.PlaceConfCrpdDetailsModule),
      },
      {
        path: "Conference/ConferenceofStatesPartiestoCRPD/ConferencePlace/create/new",
        loadChildren:
          () => import('./views/place/place-confcrpd/place-create/place-confcrpd-create.module').then(m => m.PlaceConfCrpdCreateModule),
      },
      {
        path: "Conference/ConferenceofStatesPartiestoCRPD/ConferencePlace/search",
        loadChildren:
          () => import('./views/place/place-confcrpd/place-search/place-confcrpd-search.module').then(m => m.PlaceConfCrpdSearchModule),
      },
      {
        path: "Conference/ldc/ecredentials",
        loadChildren:
          () => import('./views/credentials/credentials.module').then(m => m.ECredentialsModule),
      },
      {
        path: "Conference/NPT/ecredentials",
        loadChildren:
          () => import('./views/credentials/credentials.module').then(m => m.ECredentialsModule),
      },
      {
        path: "Conference/BBNJIntergovernmentalConference/ecredentials",
        loadChildren:
          () => import('./views/credentials/credentials.module').then(m => m.ECredentialsModule),
      },
      {
        path: "Conference/unclos/ecredentials",
        loadChildren:
          () => import('./views/credentials/credentials.module').then(m => m.ECredentialsModule),
      },
      {
        path: "Conference/ocean/ecredentials",
        loadChildren:
          () => import('./views/credentials/credentials.module').then(m => m.ECredentialsModule),
      },
      {
        path: "Conference/stockholm50/ecredentials",
        loadChildren:
          () => import('./views/credentials/credentials.module').then(m => m.ECredentialsModule),
      },
      {
        path: "Conference/confmezfnwmd/ecredentials",
        loadChildren:
          () => import('./views/credentials/credentials.module').then(m => m.ECredentialsModule),
      },
      {
        path: "Conference/water/ecredentials",
        loadChildren:
          () => import('./views/credentials/credentials.module').then(m => m.ECredentialsModule),
      },
      {
        path: "Conference/ctbt/ecredentials",
        loadChildren:
          () => import('./views/credentials/credentials.module').then(m => m.ECredentialsModule),
      },
      {
        path: "Conference/tpnw/ecredentials",
        loadChildren:
          () => import('./views/credentials/credentials.module').then(m => m.ECredentialsModule),
      },
      {
        path: "Conference/confsalw/salw/ecredentials",
        loadChildren:
          () => import('./views/credentials/credentials.module').then(m => m.ECredentialsModule),
      },
      {
        path: "Conference/confsids/sids/ecredentials",
        loadChildren:
          () => import('./views/credentials/credentials.module').then(m => m.ECredentialsModule),
      },
      {
        path: "administration/auth/accessmanagementsec",
        loadChildren:
          () => import('./views/resources/access-management-sec/access-management-sec.module').then(m => m.AccessManagementSecModule),
      },
      {
        path: "generalAssembly/plenary/regionalgroupchair",
        loadChildren: () => import('./views/regional-group-chair/regional-group-chair.module').then(m => m.RegionalGroupChairModule),
      },
      {
        path: "administration/electionssetup",
        loadChildren: () => import('./views/candidatures-elections-setup/elections-setup.module').then(m => m.ElectionsSetupModule),
      },
      {
        path: "generalAssembly/plenary/elections",
        loadChildren: () => import('./views/candidatures-elections/elections.module').then(m => m.ElectionsModule),
      },
      {
        path: "generalAssembly/fifthCommittee/elections",
        loadChildren: () => import('./views/candidatures-elections/elections.module').then(m => m.ElectionsModule),
      },
      {
        path: "ecosoc/en/elections/elections",
        loadChildren: () => import('./views/candidatures-elections/elections.module').then(m => m.ElectionsModule),
      },
      {
        path: "generalAssembly/fifthCommittee/esubmit",
        loadChildren: () => import('./views/submit/submit.module').then(m => m.SubmitModule),
      },
      {
        path: "generalAssembly/plenary/esubmit",
        loadChildren: () => import('./views/submit/submit.module').then(m => m.SubmitModule),
      },
      {
        path: "generalAssembly/firstCommittee/esubmit",
        loadChildren: () => import('./views/submit/submit.module').then(m => m.SubmitModule),
      },
      {
        path: "administration/proposals",
        loadChildren: () => import('./views/proposals/proposals.module').then(m => m.ProposalsModule),
      },
      {
        path: "proposals",
        loadChildren: () => import('./views/proposals-delegates/proposals-delegates.module').then(m => m.ProposalsDelegatesModule),
      },
      {
        path: "unep/unea/esubmit",
        loadChildren: () => import('./views/submit/submit.module').then(m => m.SubmitModule),
      },
      {
        path: "nairobi/unep/unea/esubmit",
        loadChildren: () => import('./views/submit/submit.module').then(m => m.SubmitModule),
      },
      {
        path: "nairobi/unep/cpr/esubmit",
        loadChildren: () => import('./views/submit/submit.module').then(m => m.SubmitModule),
      },
      {
        path: "administration/esubmitsetup",
        loadChildren: () => import('./views/submit-setup/submit-setup.module').then(m => m.SubmitSetupModule),
      },
      {
        path: "protocol/tickets",
        data: {
          moduleName: "ERegistration",
        },
        loadChildren:
          () => import('./views/tickets-pls/tickets-pls.module').then(m => m.TicketsPLSModule),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "resources/access/accessVienna",
        loadChildren: () => import('./views/resources/accessVienna/access.module').then(m => m.AccessModule),
        data: {
          moduleName: "AccessAdminVienna",
        },
        canActivate: [AuthorizationGuard],
      },
      {
        path: "resources/access/accessNairobi",
        loadChildren: () => import('./views/resources/accessNairobi/access.module').then(m => m.AccessModule),
        data: {
          moduleName: "AccessAdminNairobi",
        },
        canActivate: [AuthorizationGuard],
      },
      {
        path: "escap/announcements",
        data: {
          moduleName: "announcements-escap-acpr",
        },
        loadChildren:
          () => import('./views/announcements/announcements.module').then(m => m.AnnouncementsModule),
      },
      {
        path: "resources/delegates",
        data: {
          moduleName: "DelegatesModule",
        },
        loadChildren: () => import('./views/delegates/delegates.module').then(m => m.DelegatesModule),
      },
      {
        path: "administration",
        loadChildren: () => import('./views/inscriptions/meetings.module').then(m => m.MeetingsModule),
      },
    ],
  },
  {
    path: "",
    component: AuthComponent,
    children: [
      {
        path: "",
        loadChildren: () => import('./views/auth/signin/signin.module').then(m => m.SigninModule),
      },
      {
        path: "newpassword",
        loadChildren:
          () => import('./views/auth/new-password/new-password.module').then(m => m.NewPasswordModule),
      },
      {
        path: "confirminvite",
        loadChildren:
          () => import('./views/auth/confirm-invite/confirm-invite.module').then(m => m.ConfirmInviteModule),
      },
      {
        path: "forgotpassword",
        loadChildren:
          () => import('./views/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
      },
      {
        path: "uns",
        loadChildren: () => import('./views/auth/uns-signin/uns-signin.module').then(m => m.UnsSigninModule),
      },
      {
        path: "uns/create",
        loadChildren: () => import('./views/auth/uns-create/uns-create.module').then(m => m.UnsCreateModule),
      },
    ],
  },
  {
    path: "**",
    redirectTo: "",
  },
];

@NgModule({
  //imports: [RouterModule.forRoot(environment.branch === 'DEV' ? routes : routes_qa)],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
