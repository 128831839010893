import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminComponent } from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { MenuItems } from './shared/menu-items/menu-items';
import { BreadcrumbsComponent } from './layout/admin/breadcrumbs/breadcrumbs.component';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { NgxPaginationModule } from 'ngx-pagination';
import { FileUploadModule } from 'ng2-file-upload';
import { KeysPipe } from './shared/pipes/keyvalue.pipe';
import { SpeakerModalService } from './views/inscriptions/services/speaker-modal.service';
import { ERegistrationService } from './views/e-registration/services/e-registration.service';
import { MissionStaffService } from './views/mission-staff/services/mission-staff.service';
import { PlaceCreateService } from './views/place/placeC1/place-create/services/placeCreate.service';
import { PlaceScdCreateService } from './views/place/placeSCD/place-create/services/placeScdCreate.service';
import { TempDecalDriverRequestsService } from './views/temp-decal-driver-requests/services/temp-decal-driver-requests.service';
import { VotesService } from './views/votes/services/votes.service';
import { ProfileService } from './views/profile/services/profile.service';
import { SponsorshipPService } from './views/sponsorship/sponsorshipP/services/sponsorshipP.service';
import { SponsorshipP2Service } from './views/sponsorship/sponsorshipP2/services/sponsorshipP2.service';
import { SponsorshipP3Service } from './views/sponsorship/sponsorshipP3/services/sponsorshipP3.service';
import { SponsorshipC1Service } from './views/sponsorship/sponsorshipC1/services/sponsorshipC1.service';
import { SponsorshipC2Service } from './views/sponsorship/sponsorshipC2/services/sponsorshipC2.service';
import { SponsorshipC3Service } from './views/sponsorship/sponsorshipC3/services/sponsorshipC3.service';
import { SponsorshipC4Service } from './views/sponsorship/sponsorshipC4/services/sponsorshipC4.service';
import { SponsorshipC6Service } from './views/sponsorship/sponsorshipC6/services/sponsorshipC6.service';
import { SponsorshipECOSOCService } from './views/sponsorship/sponsorshipECOSOC/services/sponsorshipECOSOC.service';
import { SponsorshipCSOCDService } from './views/sponsorship/sponsorshipCSOCD/services/sponsorshipCSOCD.service';
import { SponsorshipCSWService } from './views/sponsorship/sponsorshipCSW/services/sponsorshipCSW.service';
import { SponsorshipCPDService } from './views/sponsorship/sponsorshipCPD/services/sponsorshipCPD.service';
import { SponsorshipFFService } from './views/sponsorship/sponsorshipFF/services/sponsorshipFF.service';
import { SponsorshipHRCService } from './views/sponsorship/sponsorshipHRC/services/sponsorshipHRC.service';
import { SponsorshipHRC2Service } from './views/sponsorship/sponsorshipHRC2/services/sponsorshipHRC2.service';
import { SponsorshipC24Service } from './views/sponsorship/sponsorshipC24/services/sponsorshipC24.service';
import { SponsorshipOEWGAService } from './views/sponsorship/sponsorshipOEWGA/services/sponsorshipOEWGA.service';
import { SponsorshipSCService } from './views/sponsorship/sponsorshipSC/services/sponsorshipSC.service';
import { SponsorshipAHCCService } from './views/sponsorship/sponsorshipAHCC/services/sponsorshipAHCC.service';
import { SponsorshipCNDService } from './views/sponsorship/sponsorshipCND/services/sponsorshipCND.service';
import { SponsorshipCCPCJService } from './views/sponsorship/sponsorshipCCPCJ/services/sponsorshipCCPCJ.service';
import { SponsorshipUNTOCService } from './views/sponsorship/sponsorshipUNTOC/services/sponsorshipUNTOC.service';
import { ESpeakersGDService } from './views/e-speakers-gd/services/e-speakers-gd.service';
import { BallotService } from './views/ballot/services/ballot.service';
import { CredentialsFileUploadService } from './views/credentials/services/credentials-file.service';
import { AgendaGAStatusService } from './views/agenda-ga-status/services/agenda-ga-status.service';
import { AgendaGAAnnotatedService } from './views/agenda-ga-annotated/services/agenda-ga-annotated.service';
import { APP_BASE_HREF } from '@angular/common';
import { ListOfParticipantsSetupService } from './views/list-of-participants-setup/services/list-of-participants-setup.service';
import { ListOfDelegatesService } from './views/list-of-delegates/services/list-of-delegates.service';
import { TicketsPLSService } from './views/tickets-pls/services/tickets-pls.service';
import { RegionalGroupChairService } from './views/regional-group-chair/services/regional-group-chair.service';
import { ElectionsService } from './views/candidatures-elections/services/elections.service';
import { ElectionsSetupService } from './views/candidatures-elections-setup/services/elections-setup.service';
import { SubmitService } from './views/submit/services/submit.service';
import { SubmitSetupService } from './views/submit-setup/services/submit-setup.service';
import { ProposalsService } from './views/proposals/services/proposals.service';
import { NgIdleModule } from '@ng-idle/core';
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeEn, 'en');
registerLocaleData(localeFr, 'fr');
import localeAr from '@angular/common/locales/ar';
import localeEs from '@angular/common/locales/es';
import localeRu from '@angular/common/locales/ru';
import localeZh from '@angular/common/locales/zh';
registerLocaleData(localeAr, 'ar');
registerLocaleData(localeEs, 'es');
registerLocaleData(localeRu, 'ru');
registerLocaleData(localeZh, 'zh');
// AWS & user authentication related services
import {
  AwsUtil,
  CognitoUtil,
  AccessControlService,
  GlobalVariableService,
  AuthorizationGuard,
  UserSigninService,
  UserCreateService,
  UserProfileService,
  NotifyService,
  LoaderService,
  LoaderInterceptor,
  JWTInterceptor,
  UtilityService
} from './services_shared';
import { SpeakerUpdateService } from './views/e-speakers/services/e-speakerUpdate.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { QuillModule } from 'ngx-quill';
import { SpeakerService } from './views/meetings/services/speaker.service';
import { MarkdownModule } from 'ngx-markdown';
import { CommonUtilsService } from './services_shared/common-utils.service';
import { UiSwitchModule } from 'ngx-ui-switch';
import { AgGridModule } from 'ag-grid-angular';

import { MenuListItemComponent } from './shared/menu-list-item/menu-list-item.component';
import { DataShareService } from './services_shared/data-share.service';
import { DeviceService } from './services_shared/device.service';
import { SpeakerListService } from './views/published-list-of-speakers/services/speaker-list.service';


// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, "/assets/i18n/", ".json");
}

@NgModule({
  declarations: [AppComponent, AdminComponent, AuthComponent, BreadcrumbsComponent, KeysPipe, MenuListItemComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgxPaginationModule,
    FileUploadModule,
    QuillModule.forRoot(),
    MarkdownModule.forRoot({ loader: HttpClient }),
    SimpleNotificationsModule.forRoot(),
    NgIdleModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    UiSwitchModule.forRoot({
      // Override default settings in this place.
    }),
    AgGridModule.withComponents(
      [
      ],
    )
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [
    { provide: LOCALE_ID, useValue: "en-US" }, //replace "en-US" with your locale
    MenuItems,
    AwsUtil,
    CognitoUtil,
    AccessControlService,
    GlobalVariableService,
    AuthorizationGuard,
    UserSigninService,
    UserCreateService,
    UserProfileService,
    SpeakerModalService,
    SpeakerUpdateService,
    ERegistrationService,
    MissionStaffService,
    PlaceCreateService,
    PlaceScdCreateService,
    CredentialsFileUploadService,
    ListOfParticipantsSetupService,
    TempDecalDriverRequestsService,
    VotesService,
    ProfileService,
    SponsorshipPService,
    SponsorshipP2Service,
    SponsorshipP3Service,
    SponsorshipC1Service,
    SponsorshipC2Service,
    SponsorshipC3Service,
    SponsorshipC4Service,
    SponsorshipC6Service,
    SponsorshipECOSOCService,
    SponsorshipCSOCDService,
    SponsorshipCSWService,
    SponsorshipCPDService,
    SponsorshipFFService,
    SponsorshipHRCService,
    SponsorshipHRC2Service,
    SponsorshipC24Service,
    SponsorshipOEWGAService,
    SponsorshipSCService,
    SponsorshipAHCCService,
    SponsorshipCNDService,
    SponsorshipCCPCJService,
    SponsorshipUNTOCService,
    ESpeakersGDService,
    BallotService,
    AgendaGAStatusService,
    AgendaGAAnnotatedService,
    NotifyService,
    LoaderService,
    SpeakerService,
    UtilityService,
    ListOfDelegatesService,
    CommonUtilsService,
    DataShareService,
    DeviceService,
    SpeakerListService,
    TicketsPLSService,
    RegionalGroupChairService,
    ElectionsService,
    ElectionsSetupService,
    SubmitService,
    SubmitSetupService,
    ProposalsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JWTInterceptor,
      multi: true
    },
    { provide: APP_BASE_HREF, useValue: '/' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
