import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class SpeakerListService {
  private languageSubject = new BehaviorSubject('English');
  private entitesSubject = new BehaviorSubject({});

  constructor() { }

  setLanguageSubject(language) {
    this.languageSubject.next(language);
  }

  getLanguageSubject() {
    return this.languageSubject;
  }

  setEntitesSubject(entites) {
    this.entitesSubject.next(entites);
  }

  getEntitesSubject() {
    return this.entitesSubject;
  }

  // sortSpeakerList(speakerList) {
  //   speakerList.sort((a, b) => {
  //     if (a['MT_title'] === b['MT_title']) {
  //       if (a['MT_agendaitem']['MT_AG_item'] === b['MT_agendaitem']['MT_AG_item']) {
  //         if (a['MT_cluster']['MT_clusterName'] === b['MT_cluster']['MT_clusterName']) {
  //           if (a['MT_statcat'] === b['MT_statcat']) {
  //             return a['SP_slotNo'] - b['SP_slotNo'];
  //           } else {
  //             return a['MT_statcat'] > b['MT_statcat'] ? 1 : -1;
  //           }
  //         } else {
  //           return a['MT_cluster']['MT_clusterName'] > b['MT_cluster']['MT_clusterName'] ? 1 : -1;
  //         }
  //       } else {
  //         return a['MT_agendaitem']['MT_AG_item'] > b['MT_agendaitem']['MT_AG_item'] ? 1 : -1;
  //       }
  //     } else {
  //       return a['MT_title'] > b['MT_title'] ? 1 : -1;
  //     }
  //   });
  // }

  StatCatOrder(statcat) {
    switch (statcat) {
      case 'Opening of the meeting':
        return 1;
      case 'Chair':
        return 2;
      case 'Debate':
        return 3;
      case 'Before':
        return 4;
      case 'Right of reply':
        return 5;
      case 'Points of order':
        return 6;
      case 'Statement after adoption':
        return 7;
      case 'Closing of the meeting':
        return 8;
      case 'Other':
        return 9;
      case 'Observers':
        return 10;
      case 'UN Entities':
        return 11;
      case 'NGOs and Civil Society':
        return 12;
      case 'Explanation of vote (position) before':
        return 13;
      case 'Explanation of vote (position) after':
        return 14;
      default:
        return 15;
    }
  }

  sortSpeakerList(meetingList) {
    // meetingList.sort((a, b) => {
    //   if (a['MT_id'] === b['MT_id']) {
    //     if (a['MT_AgendaOrder'] && b['MT_AgendaOrder']) {
    //       if (a['MT_AgendaOrder'] !== b['MT_AgendaOrder']) {
    //         return a['MT_AgendaOrder'] - b['MT_AgendaOrder'];
    //       } else {
    //         // compare COS
    //         if (a['MT_statcat'] && b['MT_statcat']) {
    //           const orderA = this.COSOrder(a['MT_statcat']);
    //           const orderB = this.COSOrder(b['MT_statcat']);
    //           if (orderA !== orderB) {
    //             return orderA - orderB;
    //           } else {
    //             return a['MT_title'] > b['MT_title'] ? 1 : -1;
    //           }
    //         } else if (a['MT_statcat']) {
    //           return -1;
    //         } else if (b['MT_statcat']) {
    //           return 1;
    //         } else {
    //           return a['SP_slotNo'] - b['SP_slotNo'];
    //         }
    //       }
    //     } else if (a['MT_AgendaOrder']) {
    //       return -1;
    //     } else if (b['MT_AgendaOrder']) {
    //       return 1;
    //     } else {
    //       // compare COS
    //       if (a['MT_statcat'] && b['MT_statcat']) {
    //         const orderA = this.COSOrder(a['MT_statcat']);
    //         const orderB = this.COSOrder(b['MT_statcat']);
    //         if (orderA !== orderB) {
    //           return orderA - orderB;
    //         } else {
    //           return a['MT_title'] > b['MT_title'] ? 1 : -1;
    //         }
    //       } else if (a['MT_statcat']) {
    //         return -1;
    //       } else if (b['MT_statcat']) {
    //         return 1;
    //       } else {
    //         return a['SP_slotNo'] - b['SP_slotNo'];
    //       }
    //     }
    //   } else {
    //     if (a['MT_title'] === b['MT_title']) {
    //       if (a['MT_agendaitem']['MT_AG_item'] && b['MT_agendaitem']['MT_AG_item']) {
    //         if (a['MT_agendaitem']['MT_AG_item'] === b['MT_agendaitem']['MT_AG_item']) {
    //           if (a['MT_statcat'] === b['MT_statcat']) {
    //             return a['MT_startdate'] > b['MT_startdate'] ? 1 : -1;
    //           } else {
    //             return a['MT_statcat'] > b['MT_statcat'] ? 1 : -1;
    //           }
    //         } else {
    //           return a['MT_agendaitem']['MT_AG_item'] > b['MT_agendaitem']['MT_AG_item'] ? 1 : -1;
    //         }
    //       }
    //       // if (a['MT_agendaitem']['MT_AG_item'] && b['MT_cluster']['MT_clusterName']) {
    //       //   if (a['MT_agendaitem']['MT_AG_item'] === b['MT_cluster']['MT_clusterName']) {
    //       //     if (a['MT_statcat'] === b['MT_statcat']) {
    //       //       return a['MT_startdate'] > b['MT_startdate'] ? 1 : -1;
    //       //     } else {
    //       //       return a['MT_statcat'] > b['MT_statcat'] ? 1 : -1;
    //       //     }
    //       //   } else {
    //       //     return a['MT_agendaitem']['MT_AG_item'] > b['MT_cluster']['MT_clusterName'] ? 1 : -1;
    //       //   }
    //       // }
    //       // if (a['MT_cluster']['MT_clusterName'] && b['MT_agendaitem']['MT_AG_item']) {
    //       //   if (a['MT_cluster']['MT_clusterName'] === b['MT_agendaitem']['MT_AG_item']) {
    //       //     if (a['MT_statcat'] === b['MT_statcat']) {
    //       //       return a['MT_startdate'] > b['MT_startdate'] ? 1 : -1;
    //       //     } else {
    //       //       return a['MT_statcat'] > b['MT_statcat'] ? 1 : -1;
    //       //     }
    //       //   } else {
    //       //     return a['MT_cluster']['MT_clusterName'] > b['MT_agendaitem']['MT_AG_item'] ? 1 : -1;
    //       //   }
    //       // }
    //       if (a['MT_cluster']['MT_clusterName'] && b['MT_cluster']['MT_clusterName']) {
    //         if (a['MT_cluster']['MT_clusterName'] === b['MT_cluster']['MT_clusterName']) {
    //           if (a['MT_statcat'] === b['MT_statcat']) {
    //             return a['MT_startdate'] > b['MT_startdate'] ? 1 : -1;
    //           } else {
    //             return a['MT_statcat'] > b['MT_statcat'] ? 1 : -1;
    //           }
    //         } else {
    //           return a['MT_cluster']['MT_clusterName'] > b['MT_cluster']['MT_clusterName'] ? 1 : -1;
    //         }
    //       }
    //     } else {
    //       return a['MT_title'] > b['MT_title'] ? 1 : -1;
    //     }
    //   }
    // });

    meetingList.forEach(i => {
      //get date of meeting, if there is no date, check when it was created
      if (i.MT_date != null && i.MT_date != "") {
        i.MT_datetmp = i.MT_date.toString().substr(0.10);
      } else {
        i.MT_datetmp = "";
      };
      let tmpstatcat = '';
      if (i.MT_statcat === null || i.MT_statcat === '') {
        tmpstatcat = 'Debate'
      } else {
        tmpstatcat = i.MT_statcat;
      };
      i.MT_statcatorder = this.StatCatOrder(tmpstatcat);
    });

    meetingList.sort(function (a, b) {
      if (a.MT_datetmp < b.MT_datetmp) { return 1; }
      if (a.MT_datetmp > b.MT_datetmp) { return -1; }
      if (a.MT_statcatorder < b.MT_statcatorder) { return -1; }
      if (a.MT_statcatorder > b.MT_statcatorder) { return 1; }
      if (a.MT_AgendaOrder < b.MT_AgendaOrder) { return -1; }
      if (a.MT_AgendaOrder > b.MT_AgendaOrder) { return 1; }
      if (parseInt(a.SP_slotNo, 10) < parseInt(b.SP_slotNo, 10)) { return -1; }
      if (parseInt(a.SP_slotNo, 10) > parseInt(b.SP_slotNo, 10)) { return 1; }
      return 0;
    });

  }
}
