import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { IERegistrationMeetingsSchema, IMeetingForERegUI } from '../../../interfaces/IERegistrationMeetingsSchema';
import { IRegistration } from '../../../interfaces/IRegistration';

@Injectable()
export class ERegistrationService {
  private JWT = new BehaviorSubject<any>('');
  private k: number;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient) { }

  setJWT(jwt) {
    this.JWT.next(jwt);
  }

  getJWT() {
    return this.JWT.asObservable();
  }

  getEntity(entity) {
    return this.http.get(`${environment.lookupsApi}/api/lookups/getentity/${entity}`, {
      headers: this.headers
    });
  }

  getLookupListByCode(code) {
    return this.http.get(`${environment.lookupsApi}/api/lookups/getlookups/${code}`, {
      headers: this.headers
    });
  }

  getRegistrations() {
    return this.http.get(`${environment.eregistrationApi}/api/eregistration/get`, {
      headers: this.headers
    });
  };

  getRegistrationById(id) {
    return this.http.get(`${environment.eregistrationApi}/api/eregistration/get/${id}`, {
      headers: this.headers
    });
  };

  getRegistrationsByStatusAll(status) {
    return this.http.get(`${environment.eregistrationApi}/api/eregistration/statusall/${status}`, {
      headers: this.headers
    });
  };

  getRegistrationsByStatusByYear(param) {
    return this.http.post(`${environment.eregistrationApi}/api/eregistration/statusyear`, param, {
      headers: this.headers
    });
  };

  getDelegatesByEntityByModule(param) {
    return this.http.post(
      `${environment.usersApi}/api/getaccessdelegatesbyentitybymodule`, param, { headers: this.headers });
  };


  updateRegistrationById(registration, action) {
    var body = { registration: registration, action: action }
    return this.http.put(`${environment.eregistrationApi}/api/eregistration/update/${registration._id}`, body, {
      headers: this.headers
    });
  };

  approveRegistrations(ids, name, lastname) {
    var body = { ids: ids, name: name, lastname: lastname }
    return this.http.put(`${environment.eregistrationApi}/api/eregistration/status/approve`, body, { headers: this.headers });
  }

  rejectRegistrations(ids, name, reason) {
    var body = { ids: ids, name: name, reason: reason }
    return this.http.put(`${environment.eregistrationApi}/api/eregistration/status/reject`, body, { headers: this.headers });
  }

  cancelRegistrations(ids, status, name, reason) {
    var body = { ids: ids, status: status, name: name, reason: reason }
    return this.http.put(`${environment.eregistrationApi}/api/eregistration/status/cancel`, body, { headers: this.headers });
  }

  reviewRegistration(ids, status, name) {
    var body = { ids: ids, status: status, name: name }
    return this.http.put(`${environment.eregistrationApi}/api/eregistration/review`, body, { headers: this.headers });
  };

  pendingRegistration(ids, pending, name) {
    var body = { ids: ids, pending: pending, name: name }
    return this.http.put(`${environment.eregistrationApi}/api/eregistration/pending`, body, { headers: this.headers });
  };

  printRegistration(ids, name) {
    var body = { ids: ids, name: name }
    return this.http.put(`${environment.eregistrationApi}/api/eregistration/print`, body, { headers: this.headers });
  };

  getRegistrationsByStatusAndEntity(status, entity) {
    status = status.replace(/\s/g, "@");
    entity = entity.replace(/\s/g, "@");

    const params = {
      status: status,
      entity: entity
    };
    return this.http.get(`${environment.eregistrationApi}/api/eregistration/get/status/mission`, {
      headers: this.headers,
      params: params
    });


  };

  getRegistrationsForLopByMission(status, entity, meeting) {
    status = status.replace(/\s/g, "@");
    entity = entity.replace(/\s/g, "@");
    meeting = meeting.replace(/\s/g, "@");

    const params = {
      status: status,
      entity: entity,
      meeting: meeting,
      meetingStartYear: 2024,
    };
    return this.http.get(`${environment.eregistrationApi}/api/eregistration/getregistrationsforlopbymission`, {
      headers: this.headers,
      params: <any>params
    });

  };

  getRegistrationsByStatus(status) {
    status = status.replace(/\s/g, "@");

    const params = {
      status: status
    };
    return this.http.get(`${environment.eregistrationApi}/api/eregistration/get/status/mission`, {
      headers: this.headers,
      params: params
    });


  };

  getMediaRegistrationsByStatus(status) {
    return this.http.get(`${environment.eregistrationApi}/api/eregistration/media/get/status/${status}`, {
      headers: this.headers
    });
  };

  getSecurityRegistrationsByStatus(status) {
    return this.http.get(`${environment.eregistrationApi}/api/eregistration/security/get/status/${status}`, {
      headers: this.headers
    });
  };

  getRegistrationsByLastName(status, entity) {
    status = (status || '').replace(/\s/g, "@");
    entity = (entity || '').replace(/\s/g, "@");

    const params = {
      id: status,
      entity: entity
    };
    return this.http.get(`${environment.eregistrationApi}/api/eregistration/get/lastname/mission`, {
      headers: this.headers,
      params: params
    });


  };

  getMediaRegistrationsByLastName(status) {


    return this.http.get(`${environment.eregistrationApi}/api/eregistration/media/get/lastname/${status}`, {
      headers: this.headers
    });


  };

  getRegistrationsByMission(mission) {


    return this.http.get(`${environment.eregistrationApi}/api/eregistration/get/mission/${mission}`, {
      headers: this.headers
    });
  }

  createRegistration(registration) {


    return this.http.post(`${environment.eregistrationApi}/api/eregistration/create`, registration, {
      headers: this.headers
    });


  };

  createStaffRegistration(registration) {
    return this.http.post(`${environment.eregistrationApi}/api/eregistration-staff/create`, registration, { headers: this.headers });
  };



  // check attached file's image
  isImage(file: File): boolean {



    return /^image\//.test(file.type);


  };



  // save as pdf
  saveFile(response, filename) {
    var result = filename.match('.pdf');
    if (result) {
      let blob = new Blob([response], { type: 'application/pdf' });
      var blobURL = URL.createObjectURL(blob);
      var open = window.open(blobURL, '_blank');
      if (open == null || typeof (open) == 'undefined') {
        let a = document.createElement('a');
        a.target = '_self';
        a.href = blobURL;
        a.click();
      };
    } else {
      const blob = new Blob([response], { type: 'application/octet-stream' });
      saveAs(blob, filename);
    }
  }


  uploadFile(file, ID) {
    if (!file || !file.name) {
      return;
    };
    const formData: FormData = new FormData();

    formData.append('file', file, file.name);
    formData.append('mongoID', ID);

    return this.http.post(`${environment.eregistrationApi}/api/eregistration/uploadfiles`, formData).toPromise();
  }


  async updateAttachment(originalList, currentList, uploader, ID) {

    if (!originalList) {
      originalList = [];
    }

    // Delete
    if (originalList.length) {
      for (const originalAttachment of originalList) {
        let found = false;
        currentList.forEach((currentAttachment) => {
          if (currentAttachment.uploadname === originalAttachment.uploadname) {
            found = true;
          }
        });
        if (!found) {
          await this.deleteFile(originalAttachment.uploadname).then(
            result => {

            },
            error => {
              return Promise.reject('Delete file failed');
            }
          );
        }
      }
    }

    // Upload
    for (const file of (uploader.files || [])) {
      await this.uploadFile(file, ID).then(
        result => {
          const date = new Date();
          currentList.push({
            originalname: result['originalname'],
            uploadname: result['uploadname'],
            uploadtime: date.getDate() + ' ' + date.toLocaleString('default', { month: 'short' }) + ' ' + date.getFullYear(),
            uploadentity: result['entity']
          });
        },
        error => {
          return Promise.reject('Upload file failed');
        }
      );
    }
    if (uploader) uploader.files = [];

    // return
    return Promise.resolve();
  }

  // setup authorization header
  onBeforeSend = async function (uploader, type, msg) {
    // event.xhr.setRequestHeader('Authorization', this.JWT.getValue());
    var length = 0;
    if (type == "validate" && uploader.files.length > 0) {


      let result = await this.getImageDimension(uploader.files[0]);

      length++;
      var flag = true;

      if (result == false) {

        msg.push("Attachment should be of jpg or jpeg or pjpeg format, size less than 240KB");

        // event.xhr.abort();
        flag = false;
      }
      else {
        msg.push("");

      }


      return flag;



    }
    else {
      msg.push("");
      return true;
    }


  };

  getImageDimension(evt) {

    var flag = false;
    var type = evt.name.split(".");
    // console.log(type[1]);
    return new Promise((resolve, reject) => {
      if ((type[1] != 'jpg' && type[1] != 'jpeg' && type[1] != 'pjpeg')) {
        return false;
      }
      let image: any = evt;
      // console.log(image.size);
      let fr = new FileReader();
      fr.onload = () => { // when file has loaded
        var img = new Image();
        img.onload = () => {
          // console.log(img.height);
          //img.width > 1200 || img.width < 600 || img.height > 1200 || img.height < 600 ||
          if (image.size > 240000 || (type[1] != 'jpg' && type[1] != 'jpeg' && type[1] != 'pjpeg')) {
            flag = false;
            resolve(flag);
          }
          else {
            flag = true;
            resolve(flag);
          }

        };

        img.src = fr.result.toString(); // The data URL 
      };
      fr.readAsDataURL(image);
    });

  };
  // on upload file
  onUpload(event, list, type, msgs) {
    // this.showMsg(msgs, 'upload', true, type);
    //console.log(list);



    event.files.forEach(file => {
      //console.log(file);
      if (type == 'validate') {
        if (list.length > 0) {
          list.splice(0, 1);
        }
      }

      list.push({
        originalname: file.name,
        uploadname: file.name


      });


    });
  };


  // delete file
  deleteFile = function (name) {
    const body = { filename: name };

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      responseType: 'blob'
    };

    return this.http.post(`${environment.eregistrationApi}/api/eregistration/deletefile`, body, options).toPromise();
  };


  // download file
  downloadFile = function (list: any, index: any, param3?: any, param4?: any) {
    const body = { filename: list[index].uploadname };

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Accept: 'application/pdf' }),
      responseType: 'blob'
    };

    this.http.post(`${environment.eregistrationApi}/api/eregistration/downloadfile`, body, options).subscribe(
      data => {
        // this.showMsg(msgs, 'delete', true, type);
        this.saveFile(data, list[index].originalname);


      }
    );
  };

  // download file
  getFile = function (list, index, type, msgs) {
    const body = { filename: list[index].uploadname };

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Accept: 'application/pdf' }),
      responseType: 'blob'
    };

    return this.http.post(`${environment.eregistrationApi}/api/eregistration/downloadfile`, body, options)
  };

  getUpcomingListOfMeetings(params?: any) {
    return this.http.get(`${environment.eregistrationApi}/api/eregistrationmeetings/upcoming-list`, { headers: this.headers, params: params || {} });
  };

  getUpcomingListOfMeetingsOfConference() {
    return this.http.get(`${environment.eregistrationApi}/api/eregistrationmeetings/upcoming-list-conference-meetings`, { headers: this.headers });
  };

  public getMeetingObject(item: IERegistrationMeetingsSchema): IMeetingForERegUI {
    return {
      _id: item._id,
      Meeting_Title: item.REG_Mtng,
      Start_Date: item.REG_MtngStartD,
      End_Date: item.REG_MtngEndD,
      REG_MtngBody: item.REG_MtngBody,
    };
  }

  updateStaffRegistrationByID(registration, action) {
    var body = { registration: registration, action: action }
    return this.http.put(`${environment.eregistrationApi}/api/eregistration-staff/update/${registration._id}`, body, {
      headers: this.headers
    });
  };

  getStaffRegistrationsByStatus(status) {
    return this.http.get(`${environment.eregistrationApi}/api/eregistration-staff/get/status/${status}`, {
      headers: this.headers
    });
  };

  getStaffRegistrationsByEmail(email) {
    return this.http.get(`${environment.eregistrationApi}/api/eregistration-staff/get/email/${email}`, {
      headers: this.headers
    });
  };

  getStaffRegistrationsByStatusAndEntity(status, entity) {
    const params = {
      status: status,
      entity: entity
    };
    return this.http.get(`${environment.eregistrationApi}/api/eregistration-staff/get/statusandentity`, {
      headers: this.headers,
      params: params
    });
  };

  getStaffRegistrationByID(id) {
    return this.http.get(`${environment.eregistrationApi}/api/eregistration-staff/getbyid/${id}`, {
      headers: this.headers
    });
  };

  approveStaffRegistrations(ids, name, lastname) {
    const body = { ids: ids, name: name, lastname: lastname };
    return this.http.put(`${environment.eregistrationApi}/api/eregistration-staff/approve`, body, { headers: this.headers });
  }

  rejectStaffRegistrations(ids, name, reason) {
    const body = { ids: ids, name: name, reason: reason };
    return this.http.put(`${environment.eregistrationApi}/api/eregistration-staff/reject`, body, { headers: this.headers });
  }

  cancelStaffRegistrations(ids, status, name, reason) {
    const body = { ids: ids, status: status, name: name, reason: reason };
    return this.http.put(`${environment.eregistrationApi}/api/eregistration-staff/cancel`, body, { headers: this.headers });
  }

  resendEmailStaffRegistrations(ids) {
    const body = { ids: ids };
    return this.http.put(`${environment.eregistrationApi}/api/eregistration-staff/resendemail`, body, { headers: this.headers });
  }

  resendEmailMedia(id: string) {
    return this.http.put(`${environment.eregistrationApi}/api/eregistration/resendEmail/${id}`, {}, { headers: this.headers });
  }

  uploadStaffFile(file, ID) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('mongoID', ID);
    return this.http.post(`${environment.eregistrationApi}/api/eregistration-staff/uploadfiles`, formData).toPromise();
  }


  async updateStaffAttachment(originalList, currentList, uploader, ID) {

    if (!originalList) {
      originalList = [];
    }
    // Delete
    if (originalList.length) {
      for (const originalAttachment of originalList) {
        let found = false;
        currentList.forEach((currentAttachment) => {
          if (currentAttachment.uploadname === originalAttachment.uploadname) {
            found = true;
          }
        });
        if (!found) {
          await this.deleteStaffFile(originalAttachment.uploadname).then(
            result => {

            },
            error => {
              return Promise.reject('Delete file failed');
            }
          );
        }
      }
    }

    // Upload
    for (const file of (uploader.files || [])) {
      await this.uploadStaffFile(file, ID).then(
        result => {
          const date = new Date();
          currentList.push({
            originalname: result['originalname'],
            uploadname: result['uploadname'],
            uploadtime: date.getDate() + ' ' + date.toLocaleString('default', { month: 'short' }) + ' ' + date.getFullYear(),
            uploadentity: result['entity']
          });
        },
        error => {
          return Promise.reject('Upload file failed');
        }
      );
    }
    if (uploader) uploader.files = [];

    // return
    return Promise.resolve();
  }

  // delete file
  deleteStaffFile = function (name) {
    const body = { filename: name };
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      responseType: 'blob'
    };
    return this.http.post(`${environment.eregistrationApi}/api/eregistration-staff/deletefile`, body, options).toPromise();
  };


  // download file
  downloadStaffFile = function (list: any, index: any, param3?: any, param4?: any) {
    const body = { filename: list[index].uploadname };
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Accept: 'application/pdf' }),
      responseType: 'blob'
    };
    this.http.post(`${environment.eregistrationApi}/api/eregistration-staff/downloadfile`, body, options).subscribe(
      data => {
        // this.showMsg(msgs, 'delete', true, type);
        this.saveFile(data, list[index].originalname)

      }
    );
  };

  // download file
  getStaffFile = function (list, index, type, msgs) {
    const body = { filename: list[index].uploadname };
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Accept: 'application/pdf' }),
      responseType: 'blob'
    };
    return this.http.post(`${environment.eregistrationApi}/api/eregistration-staff/downloadfile`, body, options)
  };

  public setAssignCode(registration: IRegistration, category) {
    if (registration.REG_MtngLocation === "CONF") {
      switch (registration.REG_Capacity) {
        case "VVIP (Head of State or Head of Government) or spouse of VVIP": {
          registration.REG_AssignCode = 'VVIP';
          break;
        }
        case "VIP (Cabinet Ministers) or spouse of VIP": {
          registration.REG_AssignCode = 'VIP';
          break;
        }
        case "Head of the Associate Member of the Regional Commissions": {
          registration.REG_AssignCode = 'VIP';
          break;
        }
        case "Head of delegation - Government": {
          registration.REG_AssignCode = 'HOD - Government';
          break;
        }
        case "Government delegate / spouse": {
          registration.REG_AssignCode = 'Government';
          break;
        }
        case "Head of Organization or spouse of Head of Organization": {
          registration.REG_AssignCode = 'VIP';
          break;
        }
        case "Head of delegation": {
          registration.REG_AssignCode = 'HOD - Observer';
          break;
        }
        case "Observer delegate": {
          registration.REG_AssignCode = 'Observer';
          break;
        }
        case "Member of delegation": {
          registration.REG_AssignCode = 'Observer';
          break;
        }
        default: {
          registration.REG_AssignCode = '';
          break;
        }
      }
    } else {
      if (registration["REG_Capacity"] == "VVIP (Head of State/Government, Vice President, Crown Prince/Princess & their spouses)") {
        registration["REG_AssignCode"] = "VVIP";
      } else if (registration["REG_Capacity"] == "VIP or spouse of VIP" || registration["REG_Capacity"] == "VIP as Head of Delegation") {
        registration["REG_AssignCode"] = "VIP";
      } else {
        if (category != "1") {
          if (registration["REG_Capacity"] == "Supporting Staff" ||
            registration["REG_Capacity"] == "Freelance/local interpreter") {
            registration["REG_AssignCode"] = "OS";
          } else {
            registration["REG_AssignCode"] = "AD";
          }
        } else {
          if (registration["REG_Capacity"] == "Supporting Staff" ||
            registration["REG_Capacity"] == "Freelance/local interpreter") {
            registration["REG_AssignCode"] = "PS";
          } else {
            registration["REG_AssignCode"] = "DE";
          }
        }
      }
    }
  }


}
