
import {tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';


import { CognitoUtil } from './cognito.service';
import { SpeakerModalService } from '../views/inscriptions/services/speaker-modal.service';
import { ERegistrationService } from '../views/e-registration/services/e-registration.service';
import { ListOfParticipantsSetupService } from '../views/list-of-participants-setup/services/list-of-participants-setup.service';

import { PlaceCreateService } from '../views/place/placeC1/place-create/services/placeCreate.service';
import { PlaceScdCreateService } from '../views/place/placeSCD/place-create/services/placeScdCreate.service';
import { CredentialsFileUploadService } from '../views/credentials/services/credentials-file.service';
import { UserSigninService } from './user-signin.service';
import { Router } from '@angular/router';
import { SpeakerUpdateService } from '../views/e-speakers/services/e-speakerUpdate.service';
import { NotifyService } from './notify.service';
import { SpeakerService } from '../views/meetings/services/speaker.service';

@Injectable()
export class JWTInterceptor implements HttpInterceptor {

  public jwt: string;

  constructor(
    private cognitoUtil: CognitoUtil,
    private speakerModalService: SpeakerModalService,
    private speakerUpdateService: SpeakerUpdateService,
    private eRegistrationService: ERegistrationService,
    private placeCreateService: PlaceCreateService,
    private placeScdCreateService: PlaceScdCreateService,
    private credentialsFileUploadService: CredentialsFileUploadService,
    private listOfParticipantsSetupService: ListOfParticipantsSetupService,
    private userSignService: UserSigninService,
    private router: Router,
    private notifyService: NotifyService,
    private speakerService: SpeakerService
  ) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // start our loader here
    const currentUser = this.cognitoUtil.getCurrentUser();

    if (currentUser) {
      currentUser.getSession((err, session) => {
        if (err) {
          this.userSignService.logout();
        } else {
          if (session.isValid()) {
            this.jwt = session.getAccessToken().getJwtToken();
          } else {
            this.userSignService.logout();
          }
        }
      });
    } else {
      this.userSignService.logout();
    }

    this.speakerModalService.setJWT(this.jwt);
    this.eRegistrationService.setJWT(this.jwt);
    this.placeCreateService.setJWT(this.jwt);
    this.credentialsFileUploadService.setJWT(this.jwt);

    this.listOfParticipantsSetupService.setJWT(this.jwt);
    this.speakerUpdateService.setJWT(this.jwt);
    this.placeScdCreateService.setJWT(this.jwt);

    this.speakerService.setJWT(this.jwt);

    req = req.clone({
      setHeaders: {
        Authorization: this.jwt
      }
    });

    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      // if the event is for http response
      if (event instanceof HttpResponse) {
        // stop our loader here
      }

    }, (err) => {
      if (err.status === 409) {
        this.notifyService.addNotify(
          {
            title: 'Warning',
            msg: 'You have been logged out because you signed in on another device.',
            type: 'error',
            animate: 'fromRight',
            theClass: 'small-icon'
          },
          this
        );
        setTimeout(() => {
          this.userSignService.logout();
        }, 5000);
      } else {
        if (
            !req.url.includes('/listofspeakers/byreference/')
            && !req.url.includes('/spokespersonDownloadFile')
        ) {
          this.router.navigate(['/portal/profile']);
        }
      }
    }));
  }
}
