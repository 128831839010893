import { Inject, Injectable } from "@angular/core";
import { CognitoCallback, CognitoUtil } from "./cognito.service";

@Injectable()
export class UserChangePasswordService {

    constructor(@Inject(CognitoUtil) public cognitoUtil: CognitoUtil) {

    }

    changePassword(callback: CognitoCallback, cognitoUser: any, oldPassword: string, newPassword: string) {

        cognitoUser.changePassword(oldPassword, newPassword, (err, res) => {
            if (err) {
                callback.cognitoCallback('error', err);
            } else {
                callback.cognitoCallback('success', res);
            }
        });
    }

}