import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  deviceWindow = window as any;

  constructor() { }


  isMobileScreen() {
    return this.deviceWindow.innerWidth <= 768;
  }
}
