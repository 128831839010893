import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from "@angular/router";

import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from '../../services_shared/device.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


@Component({
    selector: 'app-menu-list-item',
    templateUrl: './menu-list-item.component.html',
    styleUrls: ['./menu-list-item.component.scss']
})
export class MenuListItemComponent implements OnInit, OnDestroy {

    public expanded: boolean = false;
    @Input() item: any;
    @Input() language: string;
    @Input() depth: number;
    @Output() closeMenu = new EventEmitter<any>();
    public toggleMenu: boolean = false;
    public depthArray: Array<string | number> = [];
    public states: Array<string | number> = [];
    public isMobile: boolean;

    background: string;
    color: string = '#FFFFFF';

    constructor(
        public router: Router,
        public translate: TranslateService,
        public device: DeviceService) {
        if (!this.depth) {
            this.depth = 0;
        }
    }


    ngOnInit() {
        this.isMobile = this.device.isMobileScreen();
    }

    getStyles() {

        this.color = '#4d4d4d'; // #ffffff
        switch (this.depth) {
            case 0:
                this.background = '#f2f2f2' // #274e72
                break;
            case 1:
                this.background = '#f2f2f2' // #3f77af
                break;
            case 2:
                this.background = '#f2f2f2' // #55a8e8
                break;
            case 3:
                this.background = '#f2f2f2'; // #d7e6f4
                // this.color = 'rgba(39, 78, 114, 0.9)';
                break;

            default:
                this.background = '#f2f2f2' // #285077
                break;
        }
        return {
            'padding-left': (this.depth * 12) + 'px',
            'background': this.background,
            'color': this.color
        };
    }


    onItemSelected(item, event) {

        // state path is kept in session storage just in case user reloads page.
        event.stopPropagation();
        event.preventDefault();
        let path = sessionStorage.getItem('path') || '';
        let depth = sessionStorage.getItem('depth') || '';
        if (!path.includes(item.state) && !this.expanded) {
            sessionStorage.setItem('path', `${path}${item.state}/`);
            sessionStorage.setItem('depth', `${depth}${this.depth},`);
            path = sessionStorage.getItem('path');
            depth = sessionStorage.getItem('depth');
            this.states = path.split("/");
            this.depthArray = depth.split(",");
            this.states.pop(); // eliminate empty string element
            this.depthArray.pop(); // eliminate empty string element
            this.navigationPathBuilder(this.depthArray);
        }
        if (item.children && item.children.length) {
            this.expanded = !this.expanded;
        }
        if (item.type && item.type === 'external') {
            const lang = this.translate.currentLang ? this.translate.currentLang : 'en';
            // this.sendMessage();
            item.external = item.external.replace('-$lang$-', lang);
            this.closeMenu.emit(1);
            window.open(item.external ? item.external : 'javascript:void(0);', item.target ? '_blank' : '_self');
        } else if (!item.type) {
            this.router.navigate([`/portal/${sessionStorage.getItem('path')}`]);
            // this.sendMessage();
            this.closeMenu.emit(1);
        }
    }

    navigationPathBuilder(arr) {
        let n = arr.length;
        let x = arr[n - 1];
        if (this.countDepthOccurrences(arr, n, x) > 1) {
            this.states.splice(-2, 1);
            this.depthArray.splice(-2, 1);
            sessionStorage.setItem('path', `${this.states.toString().split(",").join("/")}/`); // replaces commas with slash
            sessionStorage.setItem('depth', `${this.depthArray.toString()},`);
        }
    }

    countDepthOccurrences(arr, n, x) {
        let res = 0;
        for (let i = 0; i < n; i++) {
            if (x == arr[i])
                res++;
        }
        return res;
    }

    checkStringLength(name: string): boolean {
        // If string is longer than 42 characters menu item receives a larger height
        // to accomodate text wrapping to new line.
        return this.translate.instant(name).length >= 39;
    }

    ngOnDestroy() {
    }


}