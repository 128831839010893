import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject ,  Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { saveAs } from 'file-saver';
// import { UserLoginService } from 'app/shared/_services/user-login.service';
import * as moment from 'moment';
import { NotifyService } from '../../../services_shared/notify.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class SpeakerService {
	public headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/pdf' });
	private token;
	private JWT = new BehaviorSubject<any>('');

	private curSlotNoSubject = new Subject<any>();
	private curSpeakerSubject = new Subject<any>();
	private delSpeakerNoSubject = new Subject<any>();


	constructor(
		private http: HttpClient,
		// private userLoginService: UserLoginService,
		private notifyService: NotifyService
	) {
		// this.userLoginService.isAuthenticated(this);
	}

	setJWT(jwt) {
		this.JWT.next(jwt);
	}

	getJWT() {
		return this.JWT.asObservable();
	}

	isLoggedIn(token, session) {
		if (session) {
			this.token = token;
			this.headers.append('authorization', token);
		}
	}

	setCurSlotNo(curNo) {
		this.curSlotNoSubject.next(curNo);
	}

	getCurSlotNo() {
		return this.curSlotNoSubject.asObservable();
	}

	setCurSpeaker(speaker) {
		this.curSpeakerSubject.next(speaker);
	}

	getCurSpeaker(): Observable<any> {
		return this.curSpeakerSubject.asObservable();
	}

	setDelSpeakerNo(speakerNo) {
		this.delSpeakerNoSubject.next(speakerNo);
	}

	getDelSpeakerNo() {
		return this.delSpeakerNoSubject.asObservable();
	}

	assignCurTime(model, field) {
		model[field] = new Date();
		this.calculateTime(model);
	}

	calculateTime(model) {
		if (model['MT_SPKR_starttime'] && model['MT_SPKR_endtime']) {
			var startTime = moment({
				y: model['MT_SPKR_starttime'].getFullYear(),
				M: model['MT_SPKR_starttime'].getMonth(),
				d: model['MT_SPKR_starttime'].getDate(),
				h: model['MT_SPKR_starttime'].getHours(),
				m: model['MT_SPKR_starttime'].getMinutes(),
				s: model['MT_SPKR_starttime'].getSeconds()
			});
			var endTime = moment({
				y: model['MT_SPKR_endtime'].getFullYear(),
				M: model['MT_SPKR_endtime'].getMonth(),
				d: model['MT_SPKR_endtime'].getDate(),
				h: model['MT_SPKR_endtime'].getHours(),
				m: model['MT_SPKR_endtime'].getMinutes(),
				s: model['MT_SPKR_endtime'].getSeconds()
			});

			var diff = endTime.diff(startTime, 'seconds');

			if (diff < 0) {
				model['MT_SPKR_time'] = "INVALID END TIME";
			} else {
				model['MT_SPKR_time'] = Math.floor(diff / 60);
			}
		}
	}

	// convert lookup data
	convertLookupData(data, prefix) {
		if (data) {
			const tempList: any = [];

			data.forEach(i => {
				const temp: any = {};

				switch (i.LKP_Code) {
					case 'LOR':
						temp[prefix + 'lvlReprOrder'] = i.LKP_Order;
						temp[prefix + 'lvlRepr'] = i.LKP_Value;
						temp[prefix + 'lvlReprShort'] = i.LKP_ValueShort;
						break;
					case 'TITLE':
						temp[prefix + 'titleOrder'] = i.LKP_Order;
						temp[prefix + 'title'] = i.LKP_Value;
						temp[prefix + 'titleShort'] = i.LKP_ValueShort;
						break;
				}

				tempList.push(temp);
			});

			return tempList;
		} else {
			return data;
		}
	}

	//convert entity data
	convertEntityData(data, prefix) {
		if (data) {
			var tempList: any = [];

			data.forEach((i) => {
				var temp: any = {};
				temp[prefix + 'entity'] = i.MC_Entity;
				temp[prefix + 'entityShort'] = i.MC_EntityShort;
				temp[prefix + 'entityLong'] = i.MC_EntityLong;
				temp[prefix + 'entityCategory'] = i.MC_Category;
				tempList.push(temp);
			});

			return tempList;
		} else {
			return data;
		}
	}

	// convert Date Time before saving
	convertDateTime(model) {
		if (model['MT_SPKR_starttime']) {
			let t = model['MT_SPKR_starttime'];
			model['MT_SPKR_starttime'] = t.getDate() + '/' + (t.getMonth() + 1) + '/' + t.getFullYear() + ' ' + t.getHours() + ':' + t.getMinutes();
		}

		if (model['MT_SPKR_endtime']) {
			let t = model['MT_SPKR_endtime'];
			model['MT_SPKR_endtime'] = t.getDate() + '/' + (t.getMonth() + 1) + '/' + t.getFullYear() + ' ' + t.getHours() + ':' + t.getMinutes();
		}
	}

	//check attached file's image
	isImage(file: File): boolean {
		return /^image\//.test(file.type);
	}

	//show message
	showMsg(msgs, operation, isSuccess, type) {
		msgs.list = [];
		msgs.showMsg = type;

		switch (operation) {
			case "valid": {
				if (!isSuccess) {
					msgs.list.push({ severity: 'warn', summary: 'Warn Message', detail: 'Required field(s) missing' });
				}
				break;
			}
			default: {
				if (isSuccess) {
					msgs.list.push({ severity: 'success', summary: 'Success Message', detail: operation + ' successfully' });
				} else {
					msgs.list.push({ severity: 'error', summary: 'Error Message', detail: operation + ' failed' });
				}
			}
		}
	}

	async updateAttachment(originalList, currentList, uploader) {
		if (!originalList) {
			originalList = [];
		}

		// Delete
		if (originalList.length) {
			for (let originalAttachment of originalList) {
				let found = false;
				currentList.forEach((currentAttachment) => {
					if (currentAttachment.uploadname === originalAttachment.uploadname) {
						found = true;
					}
				});
				if (!found) {
					await this.deleteFile(originalAttachment.uploadname).then(
						result => {
							console.log(originalAttachment.uploaderName + ' delete success');
						},
						error => {
							this.notifyService.addNotify({
								title: 'Failed',
								msg: 'Delete file',
								type: 'error',
								animate: 'fromRight',
								theClass: 'small-icon',
								timeOut: 3000
							}, this);
							return Promise.reject('Delete file failed');
						}
					);
				}
			}
		}

		// Upload
		for (let file of uploader.files) {
			await this.uploadFile(file).then(
				result => {
					const date = new Date();
					currentList.push({
						originalname: result['originalname'],
						uploadname: result['uploadname'],
						uploadtime: date.getDate() + ' ' + date.toLocaleString('default', { month: 'short' }) + ' ' + date.getFullYear(),
						uploadentity: ''
					});
				},
				error => {
					this.notifyService.addNotify({
						title: 'Failed',
						msg: 'Upload file',
						type: 'error',
						animate: 'fromRight',
						theClass: 'small-icon',
						timeOut: 3000
					}, this);
					return Promise.reject('Upload file failed');
				}
			);
		}
		uploader.files = [];

		// return
		return Promise.resolve();
	}

	//save as pdf
	saveFile(response, filename) {
		var result = filename.match('.pdf');
		if (result) {
			let blob = new Blob([response], { type: 'application/pdf' });
			var blobURL = URL.createObjectURL(blob);
			var open = window.open(blobURL, '_blank');
			if (open == null || typeof (open) == 'undefined') {
				let a = document.createElement('a');
				a.target = '_self';
				a.href = blobURL;
				a.click();
			};
		} else {
			const blob = new Blob([response], { type: 'application/octet-stream' });
			saveAs(blob, filename);
		}
	}

	removeFile(list, index) {
		list.splice(index, 1);
	}

	// upload file
	uploadFile(file) {
		const formData: FormData = new FormData();

		formData.append('file', file, file.name);

		return this.http.post(`${environment.meetingProceedingsApi}/api/meetings/uploadfiles`, formData)
			.toPromise();
	}

	// delete file
	deleteFile = function (name) {
		const body = { filename: name };

		const options = {
			headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
			responseType: 'blob'
		};

		return this.http.post(`${environment.meetingProceedingsApi}/api/meetings/deletefile`, body, options).toPromise();
	};

	// download file
	downloadFile = function (list: any, index: any, param3?: any, param4?: any) {
		const body = { filename: list[index].uploadname };

		const options = {
			headers: new HttpHeaders({ 'Content-Type': 'application/json', Accept: 'application/pdf' }),
			responseType: 'blob'
		};

		this.http.post(`${environment.meetingProceedingsApi}/api/meetings/downloadfile`, body, options).subscribe(
			data => {
				this.saveFile(data, list[index].uploadname);
			}
		);
	};

	fillOutEntity(speakerModel, missionData) {
		const mission = missionData.filter(mission => {
			return mission.MT_SPKR_entity === speakerModel.MT_SPKR_entity.MT_SPKR_entity
		});

		if (mission && mission.length) {
			speakerModel.MT_SPKR_entity = mission[0];
		} else {
			speakerModel.MT_SPKR_entity.MT_SPKR_entityShort = '';
			speakerModel.MT_SPKR_entity.MT_SPKR_entityLong = '';
			speakerModel.MT_SPKR_entity.MT_SPKR_entityCategory = '';
		}
	}

	// get lookups by Code
	getlookupListByCode(code) {
		return this.http.get(`${environment.lookupsApi}/api/lookups/getlookups/${code}`, {
			headers: this.headers
		});
	}

	// get entities
	getEntities() {
		return this.http.get(`${environment.meetingProceedingsApi}/api/meetings/entities/get`, { headers: this.headers });
	}
}
