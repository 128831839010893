import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, NavigationStart, ActivatedRoute } from '@angular/router';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';

import {
  AwsUtil,
  UserSigninService,
  CognitoUtil, CognitoCallback, LoggedInCallback
} from './services_shared';
import { VersionCheckService } from './services_shared/version-check.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'e-deleGATE';

  constructor(
    private router: Router,
    public awsUtil: AwsUtil,
    public userSignService: UserSigninService,
    public cognito: CognitoUtil,
    private idle: Idle,
    private activatedRoute: ActivatedRoute,
    private versionCheckService: VersionCheckService) {
    }

  ngOnInit() {
    //this.versionCheckService.initVersionCheck();
    this.router.events.subscribe((evt) => {
      // if (evt instanceof NavigationStart) { // not required anymore because code moved in index.html. It is not working for uniteid login on server
      //   if (evt.url.startsWith('/docs') || evt.url.startsWith('/portal/')) {
      //     if (evt.url.indexOf('error') === -1) {
      //       localStorage.setItem('returnURL', evt.url.slice(1));
      //     }
      //   }
      // }
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.idle.setIdle(1800);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onIdleStart.subscribe(() => {
      if (this.activatedRoute.snapshot['_routerState'].url.indexOf('/portal/administration/listofspeakers/') === -1) {
        this.userSignService.idleLogout();
      }
    });
    this.idle.watch();
  }
}
