import { Injectable } from '@angular/core';

@Injectable()
export class AccessControlService {

  constructor() {

  }

  disableByAccess(accessType) {
    if (accessType !== 'edit' && accessType !== 'superUser' && accessType !== 'admin') {
      return {
        'pointer-events': 'none'
      };
    }
  }

  removeByAccess(accessType) {
    if (accessType !== 'edit' && accessType !== 'superUser' && accessType !== 'admin') {
      return false;
    } else {
      return true;
    }
  }

  checkUserSystemAdmin() {
    let userProfile = JSON.parse(localStorage.getItem('user'));
    let userAccess = userProfile['USR_Access'] && userProfile['USR_Access'].length > 0 ? userProfile['USR_Access'][0]['ACC_Access'] : []
    return userAccess.filter((i) => i.moduleName === 'SystemAccessManagement' && i.accessType === 'admin').length > 0
  }

  // checkUserModuleAccess(moduleName, accessType) {
  //   let userProfile = JSON.parse(localStorage.getItem('user'));

  //   let userAccess = userProfile['USR_Access'] && userProfile['USR_Access'].length > 0 ? userProfile['USR_Access'][0]['ACC_Access'] : []
  //   return userAccess.filter((i) => i.moduleName === moduleName && i.accessType === 'admin').length > 0

  // }



}
