import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'translateSpecific'
})
export class TranslateSpecificPipe implements PipeTransform {
  private entities: any = {};

  constructor() {
  }

  transform(value: string, language: string, entities: object, lvlRepr: object): string {
    this.entities = entities;

    switch (language) {
      case 'Español':
        if (this.entities[value]) {
          return this.entities[value].MC_EntityShortS;
        } else {
          return value;
        }
      case 'Русский':
        if (this.entities[value]) {
          return this.entities[value].MC_EntityShortR;
        } else {
          return value;
        }
      case 'Français':
        if (this.entities[value]) {
          return this.entities[value].MC_EntityShortF;
        } else {
          return value;
        }
      case 'English':
        if (this.entities[value]) {
          if (lvlRepr && lvlRepr['SP_LS_lvlReprShort']) {
            if (['HS', 'HG', 'VP'].includes(lvlRepr['SP_LS_lvlReprShort'])) {
              return this.entities[value].MC_EntityLong;
            } else {
              return this.entities[value].MC_EntityShort;
            }
          } else {
            return this.entities[value].MC_EntityShort;
          }
        } else {
          return value;
        }
      case '中文':
        if (this.entities[value]) {
          return this.entities[value].MC_EntityShortC;
        } else {
          return value;
        }
      case 'العربية':
        if (this.entities[value]) {
          return this.entities[value].MC_EntityShortA;
        } else {
          return value;
        }
    }
  }

}
