import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import * as environment from '../../environments/environment';
import {ERegDataType} from '../interfaces/ERegDataType';
import * as _ from 'lodash';
import {IAutoRefreshComponentFields} from '../interfaces/IAutoRefreshComponentFields';


@Injectable()
export class CommonUtilsService {

  public headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/pdf'});

  constructor(public http: HttpClient) {
  }

  async getCollectionStatus(pathSection: string) {
    try {
      let resp = <any>await this.http.get(`${environment.environment.eregistrationApi}/api/${pathSection}/collection-status`, {headers: this.headers}).toPromise();
      return resp.result;
    } catch (e) {
      return '';
    }
  };

  setDataInGrid(newDataType: ERegDataType, newData: any[], isAutoRefreshCall: boolean, tempThis: IAutoRefreshComponentFields) {
    if (tempThis.oldDataType === newDataType && isAutoRefreshCall) {
      if (tempThis.searchValue) tempThis.oldRowData = tempThis.rowData;
      let oldDataMap = _.keyBy(tempThis.oldRowData, '_id');
      let newDataMap = _.keyBy(newData, '_id');

      // find new rows & update rows
      let newRowsToAdd = [];
      let updateRows = [];
      let deleteRows = [];
      for (let newRow of newData) {
        if (!oldDataMap[newRow._id]) newRowsToAdd.push(newRow);
        else {
          let oldRow = oldDataMap[newRow._id];
          // do not consider update when we have search value because it modifies actual data to highlight search.
          if (!tempThis.searchValue && !_.isEqual(oldRow, newRow)) {
            updateRows.push(newRow);
          }
        }
      }

      // find for delete rows
      if (!tempThis.searchValue) { // do not consider delete when we have search value because it refers some diff source in grid internally.
        for (let item of tempThis.oldRowData) {
          if (!newDataMap[item._id]) deleteRows.push(item);
        }
      }

      // update changes in grid
      if (deleteRows.length) {
        tempThis.gridOptions.api.updateRowData({remove: deleteRows});
        for (let item of deleteRows) tempThis.oldRowData.splice(tempThis.oldRowData.indexOf(item), 1);
      }
      if (newRowsToAdd.length) {
        tempThis.gridOptions.api.updateRowData({add: newRowsToAdd});
        tempThis.oldRowData.push(...newRowsToAdd);
      }
      if (updateRows.length) tempThis.gridOptions.api.updateRowData({update: updateRows});
      console.log(`Add : ${newRowsToAdd.length}, Update : ${updateRows.length}, Remove : ${deleteRows.length}`);
    } else {
      tempThis.oldDataType = newDataType;
      tempThis.oldRowData = newData;
      tempThis.rowData = newData;
      tempThis.gridOptions.api.setRowData(newData);
    }
  }

  startRefreshMeetingListInterval(isAutoRefreshCall: boolean, tempThis: IAutoRefreshComponentFields) {
    return setInterval(async () => {
      let newStatus = await this.getCollectionStatus('eregistration');
      if (!newStatus) return; // status API gave some error, no need to show error and no need to proceed further.
      if (!tempThis.oldStatus) tempThis.oldStatus = newStatus;
      if (tempThis.oldStatus !== newStatus) {
        tempThis.createRowData(true);
        tempThis.oldStatus = newStatus;
      }
    }, 5000); // check for update every 5 seconds.
  }
}
