import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class ProfileService {
  private JWT = new BehaviorSubject<any>('');
  private k: number;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient) { }

  setJWT(jwt) {
    this.JWT.next(jwt);
  }

  getJWT() {
    return this.JWT.asObservable();
  }
  getUserModuleAccessList(module) {
    return this.http.get(
      `${environment.usersApi}/api/getdelegatesbymodule/delegates/${module}`,
      {
        headers: this.headers
      });
  }
  getLookupListByCode(code) {
    return this.http.get(`${environment.lookupsApi}/api/lookups/getlookups/${code}`, {
      headers: this.headers
    });
  }

  getDelegatesByEntityByModule(param) {
    return this.http.post(
      `${environment.usersApi}/api/getaccessdelegatesbyentitybymodule`, param, { headers: this.headers });
  }

  getEntities() {
    return this.http.get(`${environment.lookupsApi}/api/lookups/getentities`, { headers: this.headers });
  }

}
