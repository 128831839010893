import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '../../../../environments/environment';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { saveAs } from 'file-saver';

@Injectable()
export class SpeakerUpdateService {
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private JWT = new BehaviorSubject<any>('');

  constructor(private http: HttpClient) { }

  setJWT(jwt) {
    this.JWT.next(jwt);
  }

  getJWT() {
    return this.JWT.asObservable();
  }

  updateSpeaker(speakerForm) {
    const body = {
      step: 'speakerupdate',
      speakerForm: speakerForm
    };
    return this.http.put(`${environment.eSpeakersApi}/api/speaker`, JSON.stringify(body), {
      headers: this.headers
    });
  }

  // check attached file's image
  isImage(file: File): boolean {
    return /^image\//.test(file.type);
  }

  // save as pdf
  saveFile(response, filename) {
    var result = filename.match('.pdf');
    if (result) {
      let blob = new Blob([response], { type: 'application/pdf' });
      var blobURL = URL.createObjectURL(blob);
      var open = window.open(blobURL, '_blank');
      if (open == null || typeof (open) == 'undefined') {
        let a = document.createElement('a');
        a.target = '_self';
        a.href = blobURL;
        a.click();
      };
    } else {
      const blob = new Blob([response], { type: 'application/octet-stream' });
      saveAs(blob, filename);
    }
  }

  uploadFile(file) {
    const formData: FormData = new FormData();

    formData.append('file', file, file.name);

    return this.http.post(`${environment.eSpeakersApi}/api/speaker/uploadfiles`, formData);
  }

  // delete file
  deleteFile = function (name) {
    const body = { filename: name };

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      responseType: 'blob'
    };

    return this.http.post(`${environment.eSpeakersApi}/api/speaker/deletefile`, body, options);
  };

  // download file
  downloadFile = function (list: any, index: any, param3?: any, param4?: any) {
    const body = { filename: list[index].uploadname };

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Accept: 'application/pdf' }),
      responseType: 'blob'
    };

    this.http.post(`${environment.eSpeakersApi}/api/speaker/downloadfile`, body, options).subscribe(
      data => {
        this.saveFile(data, list[index].uploadname);
      }
    );
  };
}
