import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable ,  Subject } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable()
export class UserCreateService {
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    constructor(
        private http: HttpClient) { }

    // service: create a user when a new user signin at first time
    userCreate(username, headers?: any) {
        let body = JSON.stringify({
            USR_username: username
        });

        return this.http.post(
            `${environment.usersApi}/api/usercreate`,
            body,
            { headers: headers || this.headers });
    }

    createLog(type, moduleName, message) {
        let body = JSON.stringify({
            type,
            moduleName,
            message
        });

        return this.http.post(
            `${environment.usersApi}/api/logaccess`,
            body,
            { headers: this.headers });
    }

    createUNSAccount(body: any) {
        return this.http.post(`${environment.usersApi}/api/uns/create`, body, { headers: this.headers });
    }

    getCaptcha(previousCaptcha?: string) {
        return this.http.get(`${environment.usersApi}/api/uns/get-captcha`, { 
            params: {
                oldKey: previousCaptcha,
            },
            headers: this.headers, responseType: 'text',
        });
    }
}