import { Component, Directive, ElementRef, HostListener, ChangeDetectorRef, ViewEncapsulation, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";

import { MainMenuItems, MenuItems } from '../../shared/menu-items/menu-items';

import {
  CognitoUtil, CognitoCallback, LoggedInCallback,
  UserSigninService,
  UserCreateService,
  UserProfileService,
  NotifyService,
  LoaderService,
  DeviceService
} from '../../services_shared';
import { DataShareService } from '../../services_shared/data-share.service';
import { NotificationsService } from 'angular2-notifications';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { SpeakerListService } from '../../views/published-list-of-speakers/services/speaker-list.service';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  animations: [
    trigger('notificationBottom', [
      state('an-off, void',
        style({
          overflow: 'hidden',
          height: '0px',
        })
      ),
      state('an-animate',
        style({
          overflow: 'visible',
          height: AUTO_STYLE,
        })
      ),
      transition('an-off <=> an-animate', [
        animate('400ms ease-in-out')
      ])
    ]),
    trigger('slideInOut', [
      state('in', style({
        width: '280px',
        // transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        width: '0',
        // transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
    trigger('mobileHeaderNavRight', [
      state('nav-off, void',
        style({
          overflow: 'hidden',
          height: '0px',
        })
      ),
      state('nav-on',
        style({
          height: AUTO_STYLE,
        })
      ),
      transition('nav-off <=> nav-on', [
        animate('400ms ease-in-out')
      ])
    ]),
    trigger('fadeInOutTranslate', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('400ms ease-in-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translate(0)' }),
        animate('400ms ease-in-out', style({ opacity: 0 }))
      ])
    ]),
    trigger('mobileMenuTop', [
      state('no-block, void',
        style({
          overflow: 'hidden',
          height: '0px',
        })
      ),
      state('yes-block',
        style({
          height: AUTO_STYLE,
        })
      ),
      transition('no-block <=> yes-block', [
        animate('400ms ease-in-out')
      ])
    ])
  ]
})
export class AdminComponent implements LoggedInCallback, OnInit, AfterViewInit, OnDestroy {
  @ViewChild('subMenus') subMenus: ElementRef;
  @ViewChild('subSubMenu') subSubMenu: ElementRef;
  @ViewChild('clickMenuTrigger') clickMenuTrigger: MatMenuTrigger;

  public lang: string;
  public animateSidebar: string;
  public navType: string;
  public themeLayout: string;
  public verticalPlacement: string;
  public verticalLayout: string;
  public pcodedDeviceType: string;
  public verticalNavType: string;
  public verticalEffect: string;
  public vnavigationView: string;
  public freamType: string;
  public sidebarImg: string;
  public sidebarImgType: string;
  public layoutType: string;

  public headerTheme: string;
  public pcodedHeaderPosition: string;

  public liveNotification: string;
  public liveNotificationClass: string;

  public profileNotification: string;
  public profileNotificationClass: string;

  public chatSlideInOut: string;
  public innerChatSlideInOut: string;

  public searchWidth: number;
  public searchWidthString: string;

  public navRight: string;
  public windowWidth: number;
  public chatTopPosition: string;

  public toggleOn: boolean;
  public toggleIcon: string;
  public navBarTheme: string;
  public activeItemTheme: string;
  public pcodedSidebarPosition: string;

  public headerFixedTop: string;

  public menuTitleTheme: string;
  public dropDownIcon: string;
  public subItemIcon: string;

  public configOpenRightBar: string;
  public displayBoxLayout: string;
  public isVerticalLayoutChecked: boolean;
  public isSidebarChecked: boolean;
  public isHeaderChecked: boolean;
  public headerFixedMargin: string;
  public sidebarFixedHeight: string;
  public sidebarFixedNavHeight: string;
  public itemBorderStyle: string;
  public subItemBorder: boolean;
  public itemBorder: boolean;

  public isCollapsedSideBar: string;
  public psDisabled: string;
  public perfectDisable: string;

  public config: any;

  public currentUserName: string = 'Unknow User';
  public currentFullName: string = '';
  public isAuthenticated: boolean;

  // //** options for notify start */
  public options: any = {
    position: ['bottom', 'right'],
  };
  // //** options for notify end */

  // show loader
  public showLoader: boolean = false;
  public loaderSubscription: Subscription;
  public environment = environment;
  public withoutTheme = false;

  public enLang;
  public frLang: boolean = false;

  public isLanguageSelectionAllowed: boolean = false;

  public backClick = true;

  messages: any[] = [];
  public navigationItems: Array<any>;
  public toggleMenu: Array<string> = [];
  public previousSubMenu: any = null;
  public languages: Array<object> = [{ lang: 'العربية', shortLang: 'ar' }, { lang: '中文', shortLang: 'zh-hans' }, { lang: 'English', shortLang: 'en' }, { lang: 'Français', shortLang: 'fr' }, { lang: 'Русский', shortLang: 'ru' }, [{ lang: 'Español', shortLang: 'es' }]];
  public isMobile: boolean = false;
  public panelOpenState: boolean = false;
  public items: Array<any> = []; // MainMenuItems
  public isPreviousSelectedLanguageAvailable = false;
  private defaultLanguage: string = 'en';
  private languagesList = ['en', 'fr', 'ar', 'zh', 'es', 'ru'];
  public whatsnewMenuItems: Array<any> = [];
  public generalAssemblyItems: Array<any> = [];
  public showSecretariat: boolean;
  public showUNSDMenu?: boolean = false;

  scroll = (): void => {
    const scrollPosition = window.pageYOffset;
    if (scrollPosition > 50) {
      if (this.isSidebarChecked === true) {
        this.pcodedSidebarPosition = 'fixed';
      }
      this.headerFixedTop = '0';
      this.sidebarFixedNavHeight = '100%';
    } else {
      this.headerFixedTop = 'auto';
      this.pcodedSidebarPosition = 'absolute';
      this.sidebarFixedNavHeight = '';
    }
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.backClick = false;
  }

  constructor(
    public router: Router,
    public menuItems: MenuItems,
    public cognitoUtil: CognitoUtil,
    public cdr: ChangeDetectorRef,
    public device: DeviceService,
    public notifyService: NotifyService,
    public userSignService: UserSigninService,
    public userCreateService: UserCreateService,
    public userProfileService: UserProfileService,
    private servicePNotify: NotificationsService,
    private loaderService: LoaderService,
    private activatedRoute: ActivatedRoute,
    private sharedMessaging: DataShareService,
    public translate: TranslateService,
    public speakerListService: SpeakerListService) {
    this.translate.setDefaultLang('en');
    this.lang = localStorage.getItem('defaultLanguage');
    if (this.lang && this.languagesList.includes(this.lang)) {
      this.isPreviousSelectedLanguageAvailable = true;
      this.defaultLanguage = this.lang;
      // this.changeLang(lang);
    } else {
      // this.changeLang('en');
    }


    let getAllOut = this.menuItems.getAll();
    this.items = getAllOut.menuItems;
    this.showUNSDMenu = getAllOut.showUNSDMenu;

    this.userProfileService.getUserProfile().subscribe(
      data => {
        if (data['success']) {
          if (data['data']['USR_Type'] !== 'UniteId') {
            this.navigationItems = this.items.slice(0, this.items.length - 1);
            this.showSecretariat = false;
          } else {
            this.navigationItems = this.items;
            this.showSecretariat = true;
          }
        } else {
          this.navigationItems = this.items.slice(0, this.items.length - 1);
          this.showSecretariat = false;
        }
        this.splitFirstLevelItems();
      }
    );

    this.router.events.subscribe(
      event => {
        if (event instanceof NavigationEnd) {
          let a: Boolean = false;
          let b: Boolean = false;
          a = event.url.startsWith('/portal/administration/listofspeakers/');
          b = event.url.startsWith('/portal/administration/eRecordedVotesReport/');
          if (a || b) {
            this.withoutTheme = true;
          } else {
            this.withoutTheme = false;
          };
          let c: Boolean = false;
          c = event.url.startsWith('/portal/administration');
          if (c) {
            this.isLanguageSelectionAllowed = false;
            this.changeLang('en');
          } else {
            this.isLanguageSelectionAllowed = true;
          }
        }
      }
    );

    // receive loader status
    this.loaderSubscription = this.loaderService.getLoader().subscribe(data => {
      this.showLoader = data['display'];
    });

    this.animateSidebar = '';
    this.navType = 'st2';
    this.themeLayout = 'horizontal';
    this.verticalPlacement = 'top';
    this.verticalLayout = 'wide';
    this.pcodedDeviceType = 'desktop';
    this.verticalNavType = 'expanded';
    this.verticalEffect = 'shrink';
    this.vnavigationView = 'view1';
    this.freamType = 'theme1';
    this.sidebarImg = 'false';
    this.sidebarImgType = 'img1';
    this.layoutType = 'light'; // light(default) dark(dark)

    this.headerTheme = 'theme1'; // theme1(default)
    this.pcodedHeaderPosition = 'fixed';

    this.headerFixedTop = 'auto';

    this.liveNotification = 'an-off';
    this.profileNotification = 'an-off';

    this.chatSlideInOut = 'out';
    this.innerChatSlideInOut = 'out';

    this.searchWidth = 0;

    this.navRight = 'nav-on';

    this.windowWidth = window.innerWidth;
    this.setHeaderAttributes(this.windowWidth);

    this.toggleOn = true;
    this.toggleIcon = 'icon-toggle-right';
    this.navBarTheme = 'themelight1'; // themelight1(default) theme1(dark)
    this.activeItemTheme = 'theme1';
    this.pcodedSidebarPosition = 'fixed';
    this.menuTitleTheme = 'theme1'; // theme1(default) theme10(dark)
    this.dropDownIcon = 'style1';
    this.subItemIcon = 'style1';

    this.displayBoxLayout = 'd-none';
    this.isVerticalLayoutChecked = false;
    this.isSidebarChecked = true;
    this.isHeaderChecked = true;
    this.headerFixedMargin = '0px'; // 50px
    this.sidebarFixedHeight = '100%'; // calc(100vh - 190px)
    this.itemBorderStyle = 'none';
    this.subItemBorder = true;
    this.itemBorder = true;

    this.isCollapsedSideBar = 'no-block';

    this.perfectDisable = '';

    this.setMenuAttributes(this.windowWidth);
    this.setHeaderAttributes(this.windowWidth);

    // dark theme
    /*this.setLayoutType('dark');*/

    // light-dark
    /*this.setLayoutType('dark');
    this.setNavBarTheme('themelight1');*/

    // dark-light theme
    /*this.setNavBarTheme('theme1');*/

    // box layout
    /*this.setVerticalLayout();*/

    // for some reason defaultLanguage on TranslationModule.forRoot causes infinite reloads of the site
    this.translate.use(this.defaultLanguage);
    this.translate.addLangs(this.languagesList);
  }

  ngOnInit() {
    this.isMobile = this.device.isMobileScreen();
    this.setBackgroundPattern('theme1');
    /*document.querySelector('body').classList.remove('dark');*/

    // localStorage.set('defaultLanguage', 'en');
    this.enLang = true;
  }

  splitFirstLevelItems() {
    this.whatsnewMenuItems = this.navigationItems.filter(elem => elem.name === "What's New");
    this.generalAssemblyItems = this.navigationItems.filter(elem => elem.name === "General Assembly");
    console.log(this.generalAssemblyItems[0].children);
  }


  getViewportHeightOverlap(subMenu: HTMLElement): number {
    const { top: parentTop } = subMenu.parentElement.getBoundingClientRect();

    const vHeight = window.innerHeight || document.documentElement.clientHeight;
    const resultingElementBottom = parentTop + subMenu.offsetHeight;

    return resultingElementBottom - vHeight;
  }

  setActive(event: MouseEvent, menu, item, subMenu: HTMLElement = null) {
    this.toggleMenu = (!this.toggleMenu.includes(item)) ? menu : this.toggleMenu.filter(i => i !== item); //

    if (subMenu) {
      const currentItemToggled = this.toggleMenu.includes(item);

      if (event.type === 'click' && currentItemToggled) {
        const viewportOverlap = this.getViewportHeightOverlap(subMenu);
        setTimeout(() => {
          if (viewportOverlap > 0) {
            subMenu.style.top = `-${viewportOverlap}px`;
          } else if (subMenu.style.top !== '-1px') {
            subMenu.style.top = `-1px`;
          }
        }, 0);
      }
    }

    const element = (<HTMLElement>event.target);
    const elementBound = element.getBoundingClientRect();
    const offset = elementBound.left + window.pageXOffset - document.documentElement.clientLeft;

    if ((2 * 200 + offset) > this.windowWidth) {
      const parent = element.parentElement;
      if (parent.classList.contains('pcoded-submenu') || parent.classList.contains('pcoded-hasmenu')) {
        parent.classList.add('edge');
      } else {
        const superParent = parent.parentElement;

        if (superParent.classList.contains('pcoded-submenu') || superParent.classList.contains('pcoded-hasmenu')) {
          superParent.classList.add('edge');
        }
      }
    }

  }

  hoverOutsideSidebar() {
    /*if (this.windowWidth > 1024) {
      const mainEle = document.querySelectorAll('.pcoded-trigger');
      for (let i = 0; i < mainEle.length; i++) {
        mainEle[i].classList.remove('pcoded-trigger');
      }
    }*/
    this.toggleMenu = [];
  }

  ngAfterViewInit() {
    // check whether user is authenticated
    this.userSignService.isAuthenticated(this);
  }

  ngOnDestroy() {
    this.loaderSubscription.unsubscribe();
  }

  onResize(event) {
    this.windowWidth = event.target.innerWidth;
    this.setHeaderAttributes(this.windowWidth);

    let reSizeFlag = true;
    if (this.pcodedDeviceType === 'tablet' && this.windowWidth >= 768 && this.windowWidth <= 1271) {
      reSizeFlag = false;
    } else if (this.pcodedDeviceType === 'phone' && this.windowWidth < 768) {
      reSizeFlag = false;
    }
    /* for check device */
    if (reSizeFlag) {
      this.setMenuAttributes(this.windowWidth);
    }
  }

  setHeaderAttributes(windowWidth) {
    if (windowWidth < 992) {
      this.navRight = 'nav-off';
    } else {
      this.navRight = 'nav-on';
    }
  }

  setMenuAttributes(windowWidth) {
    if (windowWidth >= 768 && windowWidth <= 1271) {
      this.pcodedDeviceType = 'tablet';
      this.verticalNavType = 'offcanvas';
      this.verticalEffect = 'overlay';
      this.toggleIcon = 'icon-toggle-left';
      // this.headerFixedTop = '50px';
    } else if (windowWidth < 768) {
      this.pcodedDeviceType = 'phone';
      this.verticalNavType = 'offcanvas';
      this.verticalEffect = 'overlay';
      this.toggleIcon = 'icon-toggle-left';
    } else {
      this.pcodedDeviceType = 'desktop';
      this.verticalNavType = 'expanded';
      this.verticalEffect = 'shrink';
      this.toggleIcon = 'icon-toggle-right';
    }
  }

  toggleHeaderNavRight() {
    this.navRight = this.navRight === 'nav-on' ? 'nav-off' : 'nav-on';
    this.chatTopPosition = this.chatTopPosition === 'nav-on' ? '112px' : '';
    if (this.navRight === 'nav-off' && this.innerChatSlideInOut === 'in') {
      this.toggleInnerChat();
    }
    if (this.navRight === 'nav-off' && this.chatSlideInOut === 'in') {
      this.toggleChat();
    }
  }

  toggleLiveNotification() {
    if (this.profileNotification === 'an-animate') {
      this.toggleProfileNotification();
    }

    this.liveNotification = this.liveNotification === 'an-off' ? 'an-animate' : 'an-off';
    this.liveNotificationClass = this.liveNotification === 'an-animate' ? 'show' : '';

    if (this.liveNotification === 'an-animate' && this.innerChatSlideInOut === 'in') {
      this.toggleInnerChat();
    }
    if (this.liveNotification === 'an-animate' && this.chatSlideInOut === 'in') {
      this.toggleChat();
    }
  }

  toggleProfileNotification() {
    if (this.liveNotification === 'an-animate') {
      this.toggleLiveNotification();
    }

    this.profileNotification = this.profileNotification === 'an-off' ? 'an-animate' : 'an-off';
    this.profileNotificationClass = this.profileNotification === 'an-animate' ? 'show' : '';

    if (this.profileNotification === 'an-animate' && this.innerChatSlideInOut === 'in') {
      this.toggleInnerChat();
    }
    if (this.profileNotification === 'an-animate' && this.chatSlideInOut === 'in') {
      this.toggleChat();
    }
  }

  notificationOutsideClick(ele: string) {
    if (ele === 'live' && this.liveNotification === 'an-animate') {
      this.toggleLiveNotification();
    } else if (ele === 'profile' && this.profileNotification === 'an-animate') {
      this.toggleProfileNotification();
    }
  }

  toggleChat() {
    if (this.innerChatSlideInOut === 'in') {
      this.innerChatSlideInOut = 'out';
    } else {
      this.chatSlideInOut = this.chatSlideInOut === 'out' ? 'in' : 'out';
    }
  }

  toggleInnerChat() {
    this.innerChatSlideInOut = this.innerChatSlideInOut === 'out' ? 'in' : 'out';
  }

  searchOn() {
    document.querySelector('#main-search').classList.add('open');
    const searchInterval = setInterval(() => {
      if (this.searchWidth >= 200) {
        clearInterval(searchInterval);
        return false;
      }
      this.searchWidth = this.searchWidth + 15;
      this.searchWidthString = this.searchWidth + 'px';
    }, 35);
  }

  searchOff() {
    const searchInterval = setInterval(() => {
      if (this.searchWidth <= 0) {
        document.querySelector('#main-search').classList.remove('open');
        clearInterval(searchInterval);
        return false;
      }
      this.searchWidth = this.searchWidth - 15;
      this.searchWidthString = this.searchWidth + 'px';
    }, 35);
  }

  toggleOpened(e) {
    if (this.windowWidth < 1024) {
      this.toggleOn = this.verticalNavType === 'offcanvas' ? true : this.toggleOn;
      if (this.navRight === 'nav-on') {
        this.toggleHeaderNavRight();
      }
      this.verticalNavType = this.verticalNavType === 'expanded' ? 'offcanvas' : 'expanded';
    } else {
      this.verticalNavType = this.verticalNavType === 'expanded' ? 'collapsed' : 'expanded';
    }
    this.toggleIcon = this.verticalNavType === 'expanded' ? 'icon-toggle-right' : 'icon-toggle-left';
    this.animateSidebar = 'pcoded-toggle-animate';

    if (this.verticalNavType === 'collapsed') {
      this.perfectDisable = 'disabled';
      this.sidebarFixedHeight = '100%';
    } else {
      this.perfectDisable = '';
    }

    if (this.verticalNavType === 'collapsed' && this.isHeaderChecked === false) {
      this.setSidebarPosition();
    }

    setTimeout(() => {
      this.animateSidebar = '';
    }, 500);

    document.querySelector('#main_navbar').classList.toggle('show-menu');
  }

  onClickedOutsideSidebar(e: Event) {
    if ((this.windowWidth < 1024 && this.toggleOn && this.verticalNavType !== 'offcanvas') || this.verticalEffect === 'overlay') {
      this.toggleOn = true;
      this.verticalNavType = 'offcanvas';
      this.toggleIcon = 'icon-toggle-left';
      document.querySelector('#main_navbar').classList.remove('show-menu');

      const mainEle = document.querySelectorAll('.pcoded-trigger');
      for (let i = 0; i < mainEle.length; i++) {
        mainEle[i].classList.remove('pcoded-trigger');
      }
    }
  }

  toggleRightbar() {
    this.configOpenRightBar = this.configOpenRightBar === 'open' ? '' : 'open';
  }

  setNavBarTheme(theme: string) {
    if (theme === 'themelight1') {
      this.navBarTheme = 'themelight1';
      this.menuTitleTheme = 'theme1';
      this.sidebarImg = 'false';
    } else {
      this.menuTitleTheme = 'theme9';
      this.navBarTheme = 'theme1';
      this.sidebarImg = 'false';
    }
  }

  setLayoutType(type: string) {
    if (type === 'dark') {
      this.headerTheme = 'theme1';
      this.navBarTheme = 'theme1';
      this.activeItemTheme = 'theme1';
      this.freamType = 'theme1';
      document.querySelector('body').classList.add('dark');
      this.setBackgroundPattern('theme1');
      this.menuTitleTheme = 'theme9';
      this.layoutType = type;
      this.sidebarImg = 'false';
    } else if (type === 'light') {
      this.headerTheme = 'theme1';
      this.navBarTheme = 'themelight1';
      this.menuTitleTheme = 'theme1';
      this.activeItemTheme = 'theme1';
      this.freamType = 'theme1';
      document.querySelector('body').classList.remove('dark');
      this.setBackgroundPattern('theme1');
      this.layoutType = type;
      this.sidebarImg = 'false';
    } else if (type === 'img') {
      this.sidebarImg = 'true';
      this.navBarTheme = 'themelight1';
      this.menuTitleTheme = 'theme1';
      this.freamType = 'theme1';
      document.querySelector('body').classList.remove('dark');
      this.setBackgroundPattern('theme1');
      this.activeItemTheme = 'theme1';
    }
  }

  setVerticalLayout() {
    this.isVerticalLayoutChecked = !this.isVerticalLayoutChecked;
    if (this.isVerticalLayoutChecked) {
      this.verticalLayout = 'box';
      this.displayBoxLayout = '';
    } else {
      this.verticalLayout = 'wide';
      this.displayBoxLayout = 'd-none';
    }
  }

  setBackgroundPattern(pattern: string) {
    document.querySelector('body').setAttribute('themebg-pattern', pattern);
    // this.menuTitleTheme = this.freamType = this.activeItemTheme = this.headerTheme = pattern;
  }

  setSidebarPosition() {
    if (this.verticalNavType !== 'collapsed') {
      this.isSidebarChecked = !this.isSidebarChecked;
      this.pcodedSidebarPosition = this.isSidebarChecked === true ? 'fixed' : 'absolute';
      this.sidebarFixedHeight = this.isSidebarChecked === true ? 'calc(100vh - 190px)' : '100%';
      if (this.isHeaderChecked === false) {
        window.addEventListener('scroll', this.scroll, true);
        window.scrollTo(0, 0);
      }
    }
  }

  setHeaderPosition() {
    this.isHeaderChecked = !this.isHeaderChecked;
    this.pcodedHeaderPosition = this.isHeaderChecked === true ? 'fixed' : 'relative';
    this.headerFixedMargin = this.isHeaderChecked === true ? '150px' : '';
    if (this.isHeaderChecked === false) {
      window.addEventListener('scroll', this.scroll, true);
      window.scrollTo(0, 0);
    } else {
      window.removeEventListener('scroll', this.scroll, true);
      this.headerFixedTop = 'auto';
      this.pcodedSidebarPosition = 'fixed';
      if (this.verticalNavType !== 'collapsed') {
        this.sidebarFixedHeight = this.isSidebarChecked === true ? 'calc(100vh - 175px)' : 'calc(100vh + 175px)';
      }
    }
  }

  toggleOpenedSidebar() {
    this.isCollapsedSideBar = this.isCollapsedSideBar === 'yes-block' ? 'no-block' : 'yes-block';
    if (this.verticalNavType !== 'collapsed') {
      this.sidebarFixedHeight = this.isCollapsedSideBar === 'yes-block' ? 'calc(100vh - 300px)' : 'calc(100vh - 190px)';
    }
  }

  // hoverOutsideSidebar() {
  //   if (this.windowWidth > 1024) {
  //     const mainEle = document.querySelectorAll('.pcoded-trigger');
  //     for (let i = 0; i < mainEle.length; i++) {
  //       mainEle[i].classList.remove('pcoded-trigger');
  //     }
  //   }
  // }

  hoverOutsideMenu() {
    if (this.windowWidth > 1024) {
      const mainEle = document.querySelectorAll('.pcoded-trigger');
      for (let i = 0; i < mainEle.length; i++) {
        mainEle[i].classList.remove('pcoded-trigger');
      }
    }
  }


  fireClick(e) {
    if (this.windowWidth > 1024) {
      const parentEle = e.target.parentNode.parentNode;
      if (parentEle.classList.contains('pcoded-trigger')) {
        const subEle = parentEle.querySelectorAll('.pcoded-hasmenu');
        for (let i = 0; i < subEle.length; i++) {
          if (subEle[i].classList.contains('pcoded-trigger')) {
            subEle[i].classList.remove('pcoded-trigger');
          }
        }
      } else {
        e.target.click();
      }
    }
  }

  fireClickLeave(e) {
    const parentEle = <HTMLElement>e.target.parentNode.parentNode;
    if (parentEle.classList.contains('pcoded-trigger')) {
      const subEle = parentEle.querySelectorAll('.pcoded-hasmenu');
      for (let i = 0; i < subEle.length; i++) {
        if (subEle[i].classList.contains('pcoded-trigger')) {
          subEle[i].classList.remove('pcoded-trigger');
        }
      }
    }
  }

  // check whether user is logged in, it is a function in "this" context which will be called in userService.isAuthenticated service
  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (isLoggedIn) {
      if (this.cognitoUtil.getCurrentUser()) {
        this.currentUserName = this.cognitoUtil.getCurrentUser()['username'];
        this.userCreateService.userCreate(this.currentUserName).subscribe(res => {
          if (res['success']) {
            // get current user's profile and assign current user's full name by the signed in user's username
            this.userProfileService.getProfile(this.currentUserName).subscribe(profileRes => {
              if (profileRes['result'][0].hasOwnProperty('USR_FirstName') && profileRes['result'][0].hasOwnProperty('USR_LastName') &&
                profileRes['result'][0]['USR_FirstName'] !== '' && profileRes['result'][0]['USR_LastName'] !== '') {
                this.currentFullName = profileRes['result'][0]['USR_FirstName'] + ' ' + profileRes['result'][0]['USR_LastName'];
              } else {
                const arr = this.currentUserName.split('_');
                if (arr.length > 0) {
                  this.currentFullName = arr[1];
                }
              }
              localStorage.removeItem('user');
              localStorage.setItem('user', JSON.stringify(profileRes['result'][0]));

              const defaultLanguage = profileRes['result'][0]['USR_DefaultLanguage'] ? profileRes['result'][0]['USR_DefaultLanguage'] : 'en';
              
              const preferedLanguage = this.languagesList.includes(defaultLanguage) ? defaultLanguage : 'en';
              if (!this.isPreviousSelectedLanguageAvailable) {
                localStorage.setItem('defaultLanguage', preferedLanguage);
                this.translate.use(preferedLanguage);
                this.changeLang(preferedLanguage);
              }


              //const defaultLanguage = localStorage.getItem('defaultLanguage');

              // if (defaultLanguage && defaultLanguage != 'undefined' && defaultLanguage != 'null') {
              //   this.translate.use(defaultLanguage);
              //   if (defaultLanguage === 'en') {
              //     this.enLang = true;
              //   } else {
              //     this.enLang = false;
              //   }
              // }
              // set up notify popup
              if (res['result'] === 'userExists') {
                this.notifyService.addNotify({
                  title: 'Welcome',
                  msg: this.currentFullName,
                  type: 'info',
                  animate: 'fromRight',
                  theClass: 'small-icon'
                }, this);
              } else if (res['result'] === 'userCreated') {
                this.notifyService.addNotify({
                  title: 'Successful',
                  msg: 'Hi ' + this.currentFullName + ', we detect you are the first time to be here, please finish your profile at first',
                  type: 'info',
                  animate: 'fromRight',
                  theClass: 'small-icon',
                  timeOut: 5000
                }, this);
              }
            });
          } else {
            this.notifyService.addNotify({
              title: 'Error',
              msg: 'Something wrong happened when operating your identity in database, please contact with administrator',
              type: 'error',
              animate: 'fromRight',
              theClass: 'small-icon',
              timeOut: 5000
            }, this);
          }

        });
      }
      this.isAuthenticated = true;
      this.cdr.detectChanges();
    } else {
      this.isAuthenticated = false;
      this.cdr.detectChanges();
      this.openNotLogedinModal('effect-12');
    }

  }

  // user signout event handler
  signout() {
    this.userSignService.logout();
    //let auth = this.userSignService.initCognito
    // if (this.cognitoUtil.getCurrentUser() === null) {
    //   localStorage.clear();
    //   this.router.navigate(['']);
    // }

  }

  // open user not signed in modal handler
  openNotLogedinModal(event) {
    document.querySelector('#' + event).classList.add('md-show');
  }

  // close user not signed in modal handler
  closeNotLogedinModal(event) {
    ((event.target.parentElement.parentElement).parentElement).classList.remove('md-show');
    this.router.navigate(['']);
  }

  nav(path) {
    this.router.navigate(['/portal/support/' + path]);
  }

  changeLang(lang) {
    if (this.isLanguageSelectionAllowed || lang === 'en') {

      this.translate.use(lang);
      this.lang = lang;
      localStorage.setItem('defaultLanguage', lang);
      if (lang === 'en') {
        this.enLang = true;
        this.frLang = false;

      } else if (lang === 'fr') {
        this.enLang = false;
        this.frLang = true;

      } else {
        this.enLang = false;
        this.frLang = false;
      }
    }
  }

  closeMenu(event) {
    this.clickMenuTrigger.closeMenu();
    sessionStorage.clear();
  }
  onItemSelected(item, event) {
    const lang = this.translate.currentLang ? this.translate.currentLang : 'en';
    // state path is kept in session storage just in case user reloads page.
    event.stopPropagation();
    event.preventDefault();
    item = item.replace('-$lang$-', lang);
    window.open(item ? item : 'javascript:void(0);');
  }



}

