import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserCreateService } from './user-create.service';
import { UserProfileService } from './user-profile.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthorizationGuard implements CanActivate {

    constructor(private router: Router, public userCreateService: UserCreateService, public userProfileService: UserProfileService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        const getAccess = () => new Promise((resolve, reject) => {
            // this.userProfileService.getModuleAccess('SystemAccessManagement').subscribe(async (data) => {
            //     if (data && data['accessType'] === 'admin') {
            //         localStorage.removeItem('UA_' + route.data['moduleName'])
            //         if (data['accessType'] === 'edit' || data['accessType'] === 'admin' || data['accessType'] === 'superUser') {
            //             localStorage.setItem('UA_' + route.data['moduleName'], 'edit');
            //         } else {
            //             localStorage.setItem('UA_' + route.data['moduleName'], 'read');
            //         }
            //         resolve(true)
            //     } else {
            //         this.userProfileService.getModuleAccess(route.data['moduleName']).subscribe((data) => {
            //             if (data && data['accessType'] !== 'noAccess') {
            //                 localStorage.removeItem('UA_' + route.data['moduleName'])
            //                 if (data['accessType'] === 'edit' || data['accessType'] === 'admin' || data['accessType'] === 'superUser') {
            //                     localStorage.setItem('UA_' + route.data['moduleName'], 'edit');
            //                 } else {
            //                     localStorage.setItem('UA_' + route.data['moduleName'], 'read');
            //                 }
            //                 resolve(true)
            //             } else {
            //                 localStorage.removeItem('UA_' + route.data['moduleName'])
            //                 localStorage.setItem('UA_' + route.data['moduleName'], 'noAccess')
            //                 resolve(false)
            //             }
            //         });
            //     }
            // });
            this.userProfileService.getModuleAccess(route.data['moduleName']).subscribe((data) => {
                if (data && data['accessType'] !== 'noAccess') {
                    localStorage.removeItem('UA_' + route.data['moduleName']);
                    if (data['accessType'] === 'edit' || data['accessType'] === 'admin' || data['accessType'] === 'superUser') {
                        localStorage.setItem('UA_' + route.data['moduleName'], data['accessType']);
                    } else {
                        localStorage.setItem('UA_' + route.data['moduleName'], 'read');
                    }
                    resolve(true);
                } else {
                    if (route.data['moduleName'] === 'error' || route.data['moduleName'] === 'unauthorize') {
                        resolve(true);
                    } else {
                        localStorage.removeItem('UA_' + route.data['moduleName']);
                        localStorage.setItem('UA_' + route.data['moduleName'], 'noAccess');
                        resolve(false);
                    }
                }
            });
        })

        return new Promise((resolve, reject) => {
            getAccess().then((response) => {
                if (response) {
                    this.userCreateService.createLog('Access Success', route.data['moduleName'], 'Authorized Access').subscribe(res => {
                    }, err => console.log(err))
                    resolve(true);
                } else {
                    this.userCreateService.createLog('Access Error', route.data['moduleName'], 'unauthorized Access').subscribe(res => {
                    }, err => console.log(err))

                    this.router.navigate(['/portal/unauthorize']);
                    resolve(false);
                }

            })
        })
    }

}