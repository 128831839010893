import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';

@Injectable()
export class LoaderService {
    private subject = new Subject<any>();
    private disabledOnce = false;
    private disabled = false;
    startLoader() {
        const display = this.shouldStart();
        this.resetDisableOnce();
        this.subject.next({ display });
    }

    stopLoader() {
        this.subject.next({ display: false });
    }

    getLoader(): Observable<any> {
        return this.subject.asObservable();
    }
    shouldStart(): boolean {
        return !(this.disabledOnce || this.disabled);
    }
    disableOnce(): void {
        this.disabledOnce = true;
    }
    resetDisableOnce(): void {
        this.disabledOnce = false;
    }
    disable(): void {
        this.disabled = true;
    }
    enable(): void {
        this.disabled = false;
    }
}