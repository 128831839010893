import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'customDate'
})
export class CustomDatePipe extends
    DatePipe implements PipeTransform {
    transform(value: any, timezone?: string): any {
        return new Date(value).toLocaleString("en-GB", { timeZone: timezone ? timezone : "America/New_York", year: "numeric", month: "numeric", day: "numeric",});
    }
}