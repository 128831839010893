import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import {Router} from '@angular/router';

@Injectable()
export class UserProfileService {
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    constructor(
        private http: HttpClient,
        private router: Router) { }

    // service: get user profile
    getProfile(curUserName) {

        return this.http.get(
            `${environment.usersApi}/api/getprofile/${curUserName}`,
            { headers: this.headers });
    }

    // service: update user profile
    updateProfile(profile) {
        console.log(profile);
        let body = JSON.stringify(profile);

        return this.http.put(
            `${environment.usersApi}/api/updateprofile/${profile['USR_username']}`,
            body,
            { headers: this.headers });
    }

    getUserProfile() {
        return this.http.get(
            `${environment.usersApi}/api/getuserprofile`,
            { headers: this.headers });
    }

    getModuleAccess(module) {
        return this.http.get(
            `${environment.usersApi}/api/getmoduleaccess/${module}`,
            { headers: this.headers });
    }

    getModuleAccessByUsername(username) {
        return this.http.get(
            `${environment.usersApi}/api/getaccess/${username}`,
            { headers: this.headers });
    }

  navigateAfterLogin() {
    const returnURL = localStorage.getItem("returnURL");
    if (returnURL) {
      if (returnURL.indexOf("?") !== -1) {
        const url = returnURL.split("?")[0];
        const params = returnURL.split("?")[1];
        const query = {queryParams: {}};

        let qProps = {};
        if (params.indexOf("&") > -1) {
          console.log("params - ", params.split("&"));
          params &&
          params.split("&").forEach((each) => {
            let param = each.split("=");
            qProps[param[0]] = param[1];
          });
        }
        query.queryParams = qProps;
        console.log(query);
        this.router.navigate([url], query);
      } else {
        this.router.navigate([returnURL]);
      }
      localStorage.removeItem('returnURL');
    } else {
      this.router.navigate(["/portal"]);
    }
  }


}