import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { interval, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VersionCheckService {
  private currentVersion: string = '';
  private currentCommit: string = '';

  constructor(private http: HttpClient) {}

  initVersionCheck() {
    // Check every 5 minutes (300000 ms)
    interval(300000)
      .pipe(
        switchMap(() =>
          this.http.get('/version.html', { responseType: 'text' }).pipe(
            map((response) => this.parseVersionFile(response)),
            catchError((err) => {
              console.error('Error fetching version:', err);
              return of({ Commit: this.currentCommit, Version: this.currentVersion });
            })
          )
        )
      )
      .subscribe(({ Commit, Version }) => {
        console.log(`currentVersion: ${this.currentVersion}, newVersion: ${Version}, currentCommit: ${this.currentCommit}, newCommit: ${Commit}`);

        if (this.currentVersion && this.currentVersion !== Version) {
          this.promptReload();
        }
        this.currentVersion = Version;
        this.currentCommit = Commit;
      });
  }

  parseVersionFile(content: string): { Commit: string; Version: string } {
    const lines = content.split('\n');
    const result: { [key: string]: string } = {};
    for (const line of lines) {
      const [key, value] = line.split('=');
      if (key && value) {
        result[key.trim()] = value.trim();
      }
    }
    return {
      Commit: result['Commit'] || '',
      Version: result['Version'] || '',
    };
  }

  promptReload() {
    // window.location.reload();
    // if (confirm('A new version is available. Reload now?')) {
    //   window.location.reload();
    // }
  }
}
