import { Inject, Injectable } from "@angular/core";
import { CognitoCallback, CognitoUtil } from "./cognito.service";
import {
  AuthenticationDetails,
  CognitoUser,
} from "amazon-cognito-identity-js";
import { NewPasswordUser } from "../views/auth/new-password/new-password.component";
import * as AWS from "aws-sdk/global";

@Injectable()
export class UserNewPasswordService {
  constructor(@Inject(CognitoUtil) public cognitoUtil: CognitoUtil) {}

  newPassword(
    newPasswordUser: NewPasswordUser,
    callback: CognitoCallback
  ): void {
    console.log(newPasswordUser);
    // Get these details and call
    //cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, this);
    let authenticationData = {
      Username: newPasswordUser.username,
      Password: newPasswordUser.existingPassword,
    };
    let authenticationDetails = new AuthenticationDetails(authenticationData);

    let userData = {
      Username: newPasswordUser.username,
      Pool: this.cognitoUtil.getUserPool(),
    };

    console.log("UserLoginService: Params set...Authenticating the user");
    let cognitoUser = new CognitoUser(userData);
    console.log("UserLoginService: config is " + AWS.config);
    cognitoUser.authenticateUser(authenticationDetails, {
      newPasswordRequired: function (userAttributes, requiredAttributes) {
        // User was signed up by an admin and must provide new
        // password and required attributes, if any, to complete
        // authentication.
        // the api doesn't accept this field back
        delete userAttributes.email;
        console.log(userAttributes);

        cognitoUser.completeNewPasswordChallenge(
          newPasswordUser.password,
          requiredAttributes,
          {
            onSuccess: function (result) {
              callback.cognitoCallback(null, userAttributes);
            },
            onFailure: function (err) {
              callback.cognitoCallback(err, null);
            },
          }
        );
      },
      onSuccess: function (result) {
        callback.cognitoCallback(null, result);
      },
      onFailure: function (err) {
        callback.cognitoCallback(err, null);
      },
    });
  }
}
